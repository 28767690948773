import React from 'react'
import TeamMemberTable from "./TeamMemberTable";

const TeamList = () => {
    return (
        <>
            <div className="dashboard-top-left">
                <h2 className="dashboard-top-text">
                    Team
                </h2>
                <p className="dashboard-top-subtext">
                    Track, manage and forecast your customers and orders.
                </p>
            </div>
            <TeamMemberTable />
        </>
    )
};

export default TeamList;


