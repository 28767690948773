import { useEffect, useState } from "react";
import up from "../../images/up-image.svg"
import down from "../../images/down-image.svg"
import {formatNumber} from '../../utils/functions'
import dateFormatter from "../../utils/dateFormatter";


const DashboardReport = ({ activeData, setActiveData, analyticsData,setLabel, label,value,setValue,change,setChange, showCompare, setCompareCount, compareCount, compareData }) => {
 
    useEffect(() => {
        setValue({
            activeUser: analyticsData?.aggregateData?.activeUsers?.value || 0,
            dailyActiveUsers: analyticsData?.aggregateData?.dailyActiveUsers?.value || 0,
            newUser: analyticsData?.aggregateData?.newUsers?.value || 0,
            deletedUsers: analyticsData?.aggregateData?.deletedUsers?.value || 0,
            deactivatedUser: analyticsData?.aggregateData?.deactivatedUsers?.value || 0,
            freePlanUser: analyticsData?.aggregateData?.freeUsers?.value || 0,
            unlimitedPlanUser: analyticsData?.aggregateData?.unlimitedUsers?.value || 0,
            personalAssistantUsers: analyticsData?.aggregateData?.personalAssistantUsers?.value || 0,
            newPages: analyticsData?.aggregateData?.newPages?.value || 0,
            newPublications: analyticsData?.aggregateData?.newPublications?.value || 0,
            newNewsletters: analyticsData?.aggregateData?.newNewsletters?.value || 0,
            totalNewUser: analyticsData?.aggregateData?.totalNewUser?.value || 0,
            totalDeleteUser: analyticsData?.aggregateData?.totalDeleteUser?.value || 0,
            totalDeactivatedUser: analyticsData?.aggregateData?.totalDeactivatedUser?.value || 0,
            totalNewPage: analyticsData?.aggregateData?.totalNewPage?.value || 0,
            totalNewPublication: analyticsData?.aggregateData?.totalNewPublication?.value || 0,
            totalNewNewsletter: analyticsData?.aggregateData?.totalNewNewsletter?.value || 0,
        
        });

        setChange({
            activeUserRate: analyticsData?.aggregateData?.activeUsers?.change || 0,
            dailyActiveUsersRate: analyticsData?.aggregateData?.dailyActiveUsers?.change || 0,
            newUserRate: analyticsData?.aggregateData?.totalNewUser?.change || 0,
            deletedUsersRate: analyticsData?.aggregateData?.totalDeleteUser?.change || 0,
            deactivatedUserRate: analyticsData?.aggregateData?.totalDeactivatedUser?.change || 0,
            freePlanUserRate: analyticsData?.aggregateData?.freeUsers?.change || 0,
            unlimitedPlanUserRate: analyticsData?.aggregateData?.unlimitedUsers?.change || 0,
            personalAssistantUsersRate: analyticsData?.aggregateData?.personalAssistantUsers?.change || 0,
            newPagesRate: analyticsData?.aggregateData?.totalNewPage?.change || 0,
            newPublicationsRate:analyticsData?.aggregateData?.totalNewPublication?.change || 0,
            newNewslettersRate:analyticsData?.aggregateData?.totalNewNewsletter?.change || 0
        })

        setCompareCount({
            activeUserCompare: analyticsData?.aggregateData?.activeUsers?.compareValue || 0,
            dailyActiveUsersCompare: analyticsData?.aggregateData?.dailyActiveUsers?.compareValue || 0,
            newUserCompare: analyticsData?.aggregateData?.totalNewUser?.compareValue || 0,
            deletedUsersCompare: analyticsData?.aggregateData?.totalDeleteUser?.compareValue || 0,
            deactivatedUserCompare: analyticsData?.aggregateData?.totalDeactivatedUser?.compareValue || 0,
            freePlanUserCompare: analyticsData?.aggregateData?.freeUsers?.compareValue || 0,
            unlimitedPlanUserCompare: analyticsData?.aggregateData?.unlimitedUsers?.compareValue || 0,
            personalAssistantUsersCompare: analyticsData?.aggregateData?.personalAssistantUsers?.compareValue || 0,
            newPagesCompare: analyticsData?.aggregateData?.totalNewPage?.compareValue || 0,
            newPublicationsCompare: analyticsData?.aggregateData?.totalNewPublication?.compareValue || 0,
            newNewslettersCompare: analyticsData?.aggregateData?.totalNewNewsletter?.compareValue || 0
        })

    }, [analyticsData]);

    // Data for dashboard items
    const dashboardData = [
        {
            title: "Active Users",
            circleClass: "green-active",
            value: value?.activeUser,
            compareValue: compareCount?.activeUserCompare,
            change: change?.activeUserRate,
            group: "activeUsers",
            label:"activeUsers"
        },
        // {
        //     title: "Daily Active Users",
        //     circleClass: "light-blue-active",
        //     value: value?.dailyActiveUsers,
        //     compareValue: compareCount?.dailyActiveUsersCompare,
        //     change: change?.dailyActiveUsersRate,
        //     group: "dailyActiveUsers",
        //     label:"dailyActiveUsers"
        // },
        {
            title: "New Users",
            circleClass: "blue-active",
            value: value?.totalNewUser,
            compareValue: compareCount?.newUserCompare,
            change: change?.newUserRate,
            group: "newUsers",
            label:"newUsers"
        },
        {
            title: "Deleted Users",
            circleClass: "red-active",
            value: value?.totalDeleteUser,
            compareValue: compareCount?.deletedUsersCompare,
            change: change?.deletedUsersRate,
            group: "deletedUsers",
            label:"deletedUsers"
        },
        {
            title: "Deactivated Users",
            circleClass: "red-active",
            value: value?.totalDeactivatedUser,
            compareValue: compareCount?.deactivatedUserCompare,
            change: change?.deactivatedUserRate,
            group: "deletedUsers",
            label:"deactivatedUsers"
        },
        {
            title: "Free",
            circleClass: "grey-active",
            value: value?.freePlanUser,
            compareValue: compareCount?.freePlanUserCompare,
            change: change?.freePlanUserRate,
            group: "free",
            label:"free"
        },
        {
            title: "Unlimited Plan",
            circleClass: "blue-active",
            value: value?.unlimitedPlanUser,
            compareValue: compareCount?.unlimitedPlanUserCompare,
            change: change?.unlimitedPlanUserRate,
            group: "free",
            label:"unlimitedUsers"
        },
        {
            title: "Personal Assistant",
            circleClass: "blue-active",
            value: value?.personalAssistantUsers,
            compareValue: compareCount?.personalAssistantUsersCompare,
            change: change?.personalAssistantUsersRate,
            group: "free",
            label:"personalAssistantUsers"
        },
        {
            title: "New Pages",
            circleClass: "sky-active",
            value: value?.totalNewPage,
            compareValue: compareCount?.newPagesCompare,
            change: change?.newPagesRate,
            group: "newPages",
            label:"newPages"
        },
        {
            title: "New Publications",
            circleClass: "dark-coffee-active",
            value: value?.totalNewPublication,
            compareValue: compareCount?.newPublicationsCompare,
            change: change?.newPublicationsRate,
            group: "newPages",
            label:"newPublications"
        },
        {
            title: "New Newsletters",
            circleClass: "dark-green-active",
            value: value?.totalNewNewsletter,
            compareValue: compareCount?.newNewslettersCompare,
            change: change?.newNewslettersRate,
            group: "newPages",
            label:"newNewsletters"
        }
    ];

    // Grouping data based on the 'group' property
    const groupedData = dashboardData.reduce((acc, item) => {
        acc[item.group] = [...(acc[item.group] || []), item];
        return acc;
    }, {});

    return (
        <div className="dashboard-box-wrapp">
            {Object.keys(groupedData).map((group, index) => (
                <div key={index} className={`dashboard-box ${activeData === group ? 'active-box' : ''}`} onClick={(e) => setActiveData(group)}>
                    <div className="dashboard-item">
                        <div className="dashboard-item-box">
                            {groupedData[group].map((item, idx) => (
                                <div key={idx} className="dashboard-item-inner" onClick={()=> setLabel(item?.label)}>
                                    <p className={`dash-user-status ${showCompare ? 'compareValue' : ''}`}>
                                        {item.title}
                                        <span className={`dash-circle ${item.circleClass}`}></span>
                                    </p>
                                    <p className="dash-user-data">
                                        {formatNumber(item.value)}
                                        
                                        {!showCompare &&
                                            <span className={`${(item.change >= 0) ? 'dash-green-growth' : 'dash-red-growth'}`}>
                                                {item.change !== 0 ? (
                                                    item.change >= 0 ? (
                                                        <img src={up} alt="Up" className="svg-icon" />
                                                    ) : (
                                                        <img src={down} alt="Down" className="svg-icon" />
                                                    )) : (
                                                        "〰"
                                                )}
                                                {Math.abs(item.change)}%
                                            </span>
                                        }
                                    </p>
                                    { showCompare && 
                                        <div className='analyticsDate'>{`${dateFormatter(analyticsData?.graphData?.[0]?.date, 'date', '', false)}-${dateFormatter(analyticsData?.graphData[analyticsData?.graphData.length - 1].date, 'date', '', false)}`}</div>
                                    }
                                   {showCompare && 
                                        <div className="compare-data">
                                            <p className="dash-user-data compareCount">
                                                {formatNumber(item.compareValue)}
                                            </p>
                                            <div className='analyticsDate compareDate'>{`${dateFormatter(compareData?.[0]?.date, 'date', '', false)}-${dateFormatter(compareData?.[compareData.length - 1].date, 'date', '', false)}`}</div>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DashboardReport;
