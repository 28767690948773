import React from "react";

import {formattedDate} from "../../utils/functions";

import avatar from "../../images/profile-user.svg"

const ProfileInfo = ({userData}) => {
    
    return (
        <>
            <div className="profile-section">
                <div className="profile-card">
                    <div className="profile-item">
                        <div className="profile-item-card">
                            <div className="profile-image">
                                <img src={userData?.profileMediaUrl ? userData?.profileMediaUrl : avatar} alt="profile-img" />
                            </div>
                            <div className="profile-content">
                                <h2 className="profile-title">{userData.firstName} {userData.lastName}</h2>
                                <p className="profile-text">{userData.email}</p>
                                <span className="profile-btn">{userData.role}</span>
                            </div>
                        </div>
                    </div>
                    <div className="profile-item">
                        <div className="profile-date-content">
                            <p className="profile-date">
                                Added:
                                <span className="profile-sub-text">
                                    {formattedDate(userData.createdAt)}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default ProfileInfo;
