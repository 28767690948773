import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function DeleteUser(props) {
    const {userData, isOpen, setOpen, onClose, selectedItems, setSelectedItems, refrence, userList, setUserList, togglePopup, setSelectAll, setUserData, navigate } = props;

    const history = useNavigate()

    const [isLoading, setIsLoading] = useState(false);
    const [userPassword, setUserPassword] = useState('');
    const [validatePassword, setValidatePassword] = useState(false);
    const [error, setError] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    const deleteUser = async (itemIDs) => {
        try {
            setError(false);
            setValidatePassword(false);

            if (userPassword.length > 0) {
                setIsLoading(true);

                const deletePromis = itemIDs?.map(async (itemID) => {
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/admin/deleteuser/${JSON.parse(localStorage.getItem("userData"))?._id}`,
                        { password: userPassword, id:itemID  }
                    );
                });

                await Promise.all(deletePromis);
                
                if( userList ) {
                    const idsToRemove = Array.isArray(itemIDs) ? itemIDs : [itemIDs];
                    const updatedUsers = userList.filter(
                        (user) => !idsToRemove.includes(user._id)
                    );
                    setUserList(updatedUsers);
                    setSelectedItems([]);
                    togglePopup([]);
                    setSelectAll(false);
                    setUserData([]);
                } else {
                    document.body.classList.remove('hidden-message');
                    { navigate && history(`/${navigate}`)}   
                }
                
                setOpen(false);
            } else {
                setValidatePassword(true)
            }
            setIsLoading(false);
            setUserPassword("");
        }  catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
            } else {
                console.log(error, "error");
            }
        }
    };

    const handleKeyPress = (e)=>{
        if(e.key === 'Enter'){
            deleteUser( userData && userData.length != 0 ? [userData._id] : selectedItems )
        }
    }
    
    // Function to get display name from _id
    const getDisplayNameById = (id) => {
        const user = userList.find(user => user._id === id);
        return user ? user.displayName : "";
    };

    return (
        <>
            {isOpen && (
                <>
                    <div className="popupModalOverlay"></div>
                    <div className='popupModal DeletePage' ref={refrence} >
                        <h3 className='popupModalTitle'>Delete {userData && userData.length != 0 ? 'User' : 'Users' }</h3>
                        {userData && userData.length != 0 ? (
                            <p className='popupModalContent'>Are you sure you want to delete <span>{userData.firstName} {userData.lastName}</span>? This action can’t be undone.</p>
                        ) : (
                            <p className='popupModalContent'>Are you sure you want to delete these {selectedItems && selectedItems.length > 1 ? 'users' : <span>{getDisplayNameById(selectedItems[0])}</span>}? This action can’t be undone. </p>
                        )}
                        <div className='popupFields'>
                            <label className='fieldsLabel'>Enter Password</label>
                            <input type="password" name='password' className='inputFields' placeholder='Type Password' autoComplete='off' onChange={(e) => setUserPassword(e.target.value)} readOnly={readOnly} onFocus={ () => setReadOnly(false) } onBlur={ () => setReadOnly(true) } onKeyPress={(e)=> handleKeyPress(e) }/>
                            {validatePassword === true && (
                                <p className="validateError">The field is not filled</p>
                            )}
                            {error === true && (
                                <p className="validateError">Passwords do not match</p>
                            )}
                        </div>
                        <div className="popupModalBtn">
                            <button className="cancel" type="button" onClick={onClose}>Cancel</button>
                            <button className="savePicture" type="button" onClick={() => deleteUser( userData && userData.length != 0 ? [userData._id] : selectedItems )}>
                                {isLoading ?
                                    <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                    </div>
                                : "Delete"}
                            </button>
                        </div>
                    </div>                                    
                </>
            )}
        </>
    )
}
export default DeleteUser;