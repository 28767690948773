import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import BgFormAnimate from './BgFormAnimate';
import login from '../../images/login.svg';
import './LoginModule.css';

function Login() {
    const navigation = useNavigate();

    // State variables for email, password, and their validation
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [validateEmail, setValidateEmail] = useState(false);
    const [validatePass, setValidatePass] = useState(false);

    // State variables for error, authentication status, and loading indicator
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState('')
    const [loginData, setLoginData] = useState()
    const [authEmail, setAuthEmail] = useState('')
    const [isBack, setBack] = useState(false)
    const [userId, setUserId] = useState("")
    const [codeInputs, setCodeInputs] = useState(['', '', '', '', '']);
    const [inputError, setInputError] = useState(false);
    const [invalidOtpError, setInvalidOtpError] = useState(false);
    const [authDone, setAuthDone] = useState(false)
    const [token, setToken] = useState('')
    const inputRefs = useRef([]);
    // Regular expression to validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation checks for email and password
        if (email.length === 0 || !emailPattern.test(email)) {
            setValidateEmail(true);
        } else {
            setValidateEmail(false);
        }

        if (pass.length === 0) {
            setValidatePass(true);
        } else {
            setValidatePass(false);
        }

        // If both email and password are valid, proceed with authentication
        if (emailPattern.test(email) && pass.length > 0) {
            loginProcess();
        }
    };

    const loginProcess = async () => {
        try {
            setError(false);
            setIsLoading(true);

            const loginData = { username: email, password: pass };

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/login`,
                loginData
            );
            const userResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${response.data.user?._id}`
            );
            if (userResponse?.data?.result?.userMeta?.twoFactorAuth?.status === "enable") {
                setUserId(response.data.user?._id);

                setLoginData(response?.data);
                setIsLoading(false);
                setStatus(userResponse?.data?.result?.userMeta?.twoFactorAuth?.status);

                setAuthEmail(userResponse?.data?.result?.userMeta?.twoFactorAuth?.data || email);
                setBack(true)
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/twoFactorEmailOTP/${response.data.user?._id}`,
                    { email: userResponse?.data?.result?.userMeta?.twoFactorAuth?.data || email }
                );
            }else{
                localStorage.setItem("userData", JSON.stringify(response.data.user))
                setTimeout(() => {
                    window.location.href = '/dashboard';
                }, 1000);
            }
                setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
            } else {
                setError(true);
                console.log(error, error);
            }
            return false;
        }
    }
    const handleInputChange = (index, value) => {
        if (value.length <= 1 && /^\d*$/.test(value)) {
            const newInputs = [...codeInputs];
            newInputs[index] = value;
            setCodeInputs(newInputs);

            if (value !== '') {
                // Move focus to the next input field
                if (index < codeInputs.length - 1) {
                    inputRefs.current[index + 1].focus();
                }
            } else {
                // Move focus to the previous input field
                if (index > 0) {
                    inputRefs.current[index - 1].focus();
                }
            }
        }
    };

    const handleOtpPaste = (e) => {
        const paste = e.clipboardData.getData('text');
        if (/^\d{5}$/.test(paste)) { // Assuming OTP length is 5
            const newInputs = paste.split('');
            setCodeInputs(newInputs);
            newInputs.forEach((value, index) => {
                inputRefs.current[index].value = value;
                if (index < inputRefs.current.length - 1) {
                    inputRefs.current[index + 1].focus();
                }
            });
        }
        e.preventDefault();
    };

    const handleSend = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const otpDigit = codeInputs?.join('');
            if (codeInputs) {
                const payload = {
                    email: email,
                    otp: Number(otpDigit)
                };
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/userOtpVerify/${userId}`,
                    payload
                );
                setInvalidOtpError(false);
                if (codeInputs?.some(input => input === '')) {
                    setInputError(true);
                    setInvalidOtpError(false);
                    setIsLoading(false);
                } else {
                    localStorage.setItem("userData", JSON.stringify(loginData?.user))
                    setTimeout(() => {
                        window.location.href = '/dashboard';
                    }, 1000);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                setInvalidOtpError(true);
            } else {
                setInvalidOtpError(false);
            }
            setAuthDone(false);
            setIsLoading(false);
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSend(e);
        }
    };

    return (
        <>
            <BgFormAnimate />
            <form className='form-design' onSubmit={handleSubmit}>
                <section className='login'>
                    <div className="loginWindow">
                        <img src={login} alt="Login" />
                        {status === 'enable' && status !== "" && isBack ?
                            <>
                                <p className='loginTitle'>Two Factor Authentication</p>
                                <div className="popupContent">
                                    <p className="popupDesc">Enter the code sent to your email address to log in to your account.</p>
                                </div>

                                <div className='input-verify-code' style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
                                    <div>
                                        <div className='codeSection loginRow' style={{ marginTop: "10px" }} onPaste={handleOtpPaste}>
                                            {codeInputs?.map((value, index) => (
                                                <input
                                                    key={index}
                                                    type='number'
                                                    name='otp'
                                                    placeholder='0'
                                                    className='code-input'
                                                    value={value}
                                                    maxLength={1}
                                                    autoFocus={index === 0}
                                                    onChange={e => handleInputChange(index, e.target.value)}
                                                    ref={el => inputRefs.current[index] = el}
                                                    onKeyPress={(e) => handleKeyPress(e)}
                                                />
                                            ))}
                                        </div>
                                        {inputError && <p className="validateError" style={{ textAlign: "center" }}>Please fill all code fields</p>}
                                        {invalidOtpError && <p className="validateError" style={{ textAlign: "center" }}>The code you have entered is incorrect.</p>}
                                    </div>
                                </div>
                                <div className='resend-text'>
                                    <p style={{ display: "flex", justifyContent: "center", margin: "5px" }}>Didn’t get a code? <Link style={{ margin: "0 5px" }} onClick={() => {
                                        axios.post(
                                            `${process.env.REACT_APP_API_URL}/user/twoFactorEmailOTP/${userId}`,
                                            { email: authEmail }
                                        );
                                        setCodeInputs(["", "", "", "", ""])
                                    }}> Click to resend.</Link></p>
                                </div>

                                <div className="popupButtons" >

                                    <button className='backButton' style={{ width: "50%" }} onClick={() => { setBack(false); setInvalidOtpError(false); setCodeInputs(["", "", "", "", ""]) }}><svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.3335 6.5L1.00016 6.5M1.00016 6.5L6.00016 11.5M1.00016 6.5L6.00016 1.5" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                        Back</button>
                                    <button className='settingsBlue billingSaveBtn' style={{ width: "50%", marginRight: "0px" }} onClick={handleSend} type='submit'>
                                        {isLoading ?
                                            <div className="registerLoader">
                                                <div className="ring-loader"></div>
                                            </div>
                                            : <>
                                                Continue
                                                <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ rotate: "180deg", marginLeft: "5px", marginTop: '1px' }}>
                                                    <path d="M14.3335 6.5L1.00016 6.5M1.00016 6.5L6.00016 11.5M1.00016 6.5L6.00016 1.5" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </>
                                        }

                                    </button>
                                </div>
                            </> :
                        <>
                        <p className='loginTitle'>Login to your account</p>
                        <p className='loginSubtitle'>Please enter your details.</p>

                        {/* Email input field */}
                        <div className='loginRow'>
                            <p>Email</p>
                            <input
                                type="text"
                                name="email"
                                placeholder='Enter your email'
                                value={email}
                                autoFocus={true}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setValidateEmail(false);
                                }}
                                autoComplete="off"
                                className={validateEmail ? 'inputError' : ''}
                            />
                            {validateEmail && <p className='validateError'>Please enter a valid email address.</p>}
                        </div>

                        {/* Password input field */}
                        <div className='loginRow'>
                            <p>Password</p>
                            <input
                                type="password"
                                name="password"
                                placeholder='Enter your password'
                                value={pass}
                                onChange={(e) => {
                                    setPass(e.target.value);
                                    setValidatePass(false);
                                }}
                                className={validatePass ? 'inputError' : ''}
                            />
                            {validatePass && <p className='validateError'>The field is not filled</p>}
                        </div>

                        {/* Error message for incorrect credentials */}
                        <div className='loginRow'>
                            {error && <p className='validateError'>Your email address and password don't match. Please try again.</p>}
                        </div>

                        {/* Checkbox for remember me */}
                        <div className="loginInfo">
                            <div>
                                <input type="checkbox" id="loginCheckbox" />
                                <label htmlFor="loginCheckbox">Remember for 30 days</label>
                            </div>
                        </div>

                        {/* Login button */}
                        <button className={`loginButton ${isLoading ? 'btn_loading' : ''}`} type='submit'>
                            {isLoading ? (
                                <div className="registerLoader">
                                    <div className="ring-loader"></div>
                                </div>
                            ) : (
                                "Sign in"
                            )}
                        </button>
                    </>
                    }
                    </div>

                    {/* Copyright info */}
                    <div className='pvivacy_mobile'>
                        <p>©Jourmal 2022. All Rights Reserved. <span>Terms</span> & <span>Privacy</span>.</p>
                    </div>

                </section>
            </form>
        </>
    );
}

export default Login;
