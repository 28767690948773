import React, { useState, useRef, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import axios from 'axios';

import ReverseHistory from './ReverseHistory';
import Pagination from "../../components/Pagination";
import NoResultsAssistantHistory from "./NoResultsAssistantHistory";

import avatar from "../../images/profile-picture 1.png";
import Down from "../../images/down-arrow.svg";
import moreIcon from "../../images/more.svg";
import reverse from "../../images/reverse-left.png";
import search from '../../images/search.svg';

import { formattedDate } from "../../utils/functions";

const AssistantHistory = (props) => {
    const ref = useRef();
    const popupRef = useRef();
    props.setActivePage('Assistant History')

    const { id } = useParams();

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [readFields, setReadFields] = useState(true);
    const [searchInput, setSearchInput] = useState('');

    const [originalData, setOriginalData] = useState([]);
    const [dateSort, setDateSort] = useState(false);
    const [actionSort, setActionSort] = useState(false);
    const [nameSort, setNameSort] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        fetchData();
    }, [currentPage, searchInput]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupitem([]);
                setOpen(false);
            }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        setIsLoading(false);
        try {
            setIsLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/gethistory/${id}?type=assistant&page=${currentPage}&search=${search}`,
            )

            setOriginalData(response.data.result);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "error");
        }
    }

    const onClose = (e) => {
        setOpen(false);
        setOpenPopupitem([]);
    };

    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
        } else {
            setOpen(false);
            setOpenPopupitem(item);
        }
    };

    const handleReverseActionEvent = (item) => {
        if (openPopupitem.length != 0 || selectedItems.length != 0) {
            setOpen(true);
        }
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = originalData.map((item) => item._id);
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter(
                    (_id) => _id !== itemId
                );
                setSelectAll(
                    updatedSelectedItems.length === originalData.length
                );
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(
                    updatedSelectedItems.length === originalData.length
                );
                return updatedSelectedItems;
            }
        });
    };

    function sortByDate() {
        var result = [];

        if (dateSort === false) {
            result = [...originalData].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format("ll");
                var daysA = moment(dateA).diff(now, "Days");

                var dateB = moment(b.createdAt).format("ll");
                var daysB = moment(dateB).diff(now, "Days");

                return daysA - daysB;
            });
        } else {
            result = [...originalData].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format("ll");
                var daysA = moment(dateA).diff(now, "Days");

                var dateB = moment(b.createdAt).format("ll");
                var daysB = moment(dateB).diff(now, "Days");

                return daysB - daysA;
            });
        }
        setOriginalData(result);
    }

    function sortByAction() {
        var result;
        if (actionSort === true) {
            result = [...originalData].sort((a, b) =>
                a.action.localeCompare(b.action)
            );
        } else {
            result = [...originalData].sort(
                (a, b) => -1 * a.action.localeCompare(b.action)
            );
        }
        setOriginalData(result);
    }

    function sortByName() {
        var result;
        if (nameSort === true) {
            result = [...originalData].sort((a, b) =>
                a.user.localeCompare(b.user)
            );
        } else {
            result = [...originalData].sort(
                (a, b) => -1 * a.user.localeCompare(b.user)
            );
        }
        setOriginalData(result);
    }

    return (
        <>
            <div className='pagesSearch twoItemWrap'>
                <div className="pageSearchInput">
                    <input type="text" name="pages_search" className="inputFields" id="pages_search" placeholder={'Search action...'} value={searchInput} onChange={e => setSearchInput(e.target.value)} autocomplete="off" readOnly={readFields} onFocus={() => setReadFields(false)} onBlur={() => setReadFields(true)} />
                    <img src={search} alt="Search" />
                </div>
                <div className="actions-buttons">
                    <div className="deleteIcon">
                        <img onClick={() => handleReverseActionEvent([])} className="deleteIconImage" src={reverse} alt="reverse-icon" />
                    </div>
                </div>
            </div>
            <div className="table-data-wrapper table-pagination-wrapper">
                <div className="table-data table-four-column">
                    <div className="table-head">

                        <div className={`action ${actionSort === false ? "down-arrow" : "up-arrow"}`} >
                            <input type={"checkbox"} checked={selectAll} onChange={handleSelectAll} />
                            <p onClick={(e) => { setActionSort(!actionSort); sortByAction(); }} className="table-country">Action</p>
                            <img onClick={(e) => { setActionSort(!actionSort); sortByAction(); }} src={Down} alt="Down" />
                        </div>

                        <div className={`table-status full-name ${nameSort === false ? "down-arrow" : "up-arrow"}`} >
                            <p onClick={(e) => { setNameSort(!nameSort); sortByName(); }} className="table-full-name">Profile Assistant</p>
                            <img onClick={(e) => { setNameSort(!nameSort); sortByName(); }} src={Down} alt="Down" />
                        </div>
                        <div className={`subscription-date ${dateSort === false ? "down-arrow" : "up-arrow"}`} >
                            <p onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} className="table-title">Date</p>
                            <img onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} src={Down} alt="Down" />
                        </div>

                        <div className="actions">Actions</div>
                    </div>
                    {isLoading ?
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>   
                        :                 
                        (originalData.length > 0) ? originalData.map((item) => {
                            return (
                                <div className="table-item" key={item._id}>

                                    <div className="table-status">
                                        <div className="user-status" >
                                            <input type="checkbox" checked={selectedItems.includes(item._id)} onChange={() => handleCheckboxChange(item._id)} />
                                            <div className="user-content">
                                                <div className="table-link support-title">
                                                    <p> {item.title}: </p>
                                                    <span>{item.subTitle}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-status">
                                        <Link className="user-link" to={`/users/${item.member_id}`}>
                                            <img src={avatar} />
                                            <span className="user-name">{item.userName}</span>
                                        </Link>
                                    </div>

                                    <div className="subscription-date">
                                        <span>{formattedDate(item.createdAt)}</span>
                                    </div>
                                    <div className="table-action" ref={ref} >
                                        <img onClick={() => togglePopup(item)} src={moreIcon} alt="More" />
                                    </div>
                                    {openPopupitem && openPopupitem._id == item._id && (
                                        <div className="table-data-dropdown" ref={popupRef}>
                                            <ul>
                                                <li>
                                                    <span onClick={() => handleReverseActionEvent(item)} >
                                                        <img src={reverse} alt="reverse-icon" />
                                                        Reverse
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            );
                        })
                            :
                            <NoResultsAssistantHistory />
                    }
                </div>
                {
                    totalPage > 1 &&
                    <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                }
            </div>
            <ReverseHistory
                isOpen={isOpen}
                item={openPopupitem}
                selectedItems={selectedItems}
                onClose={onClose}
                fetchData={fetchData}
                originalData={originalData}
                setOpen={setOpen}
                refrence={popupRef}
            />
        </>
    );
};
export default AssistantHistory;
