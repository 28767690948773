import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "../images/image17.svg";
import home from "../images/home.png";
import contact from "../images/contact.png";
import headphone from "../images/headphone.png";
import setting from "../images/setting.png";
import profilepicture from "../images/profile-user.svg";
import icon_logout from '../images/icon-logout.svg';

import "./css/Header.css";

function Header(props) {
    const ref = useRef();

    const [menuActive, setMenuActive] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const [addTabActive, setAddTabActive] = useState(false);
    const [userInfoActive, setUserInfoActive] = useState(false);

    const sidebarClasses = `sidebar ${isHovered ? "hover-menu" : ""}`;


    const toggleMenu = () => {
        const body = document.body;
        body.classList.toggle("hide-menu", !menuActive);
        setMenuActive(!menuActive);
    };

    const handleMouseEnter = () => {
        if (menuActive) {
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleHamburgerClick = () => {
        toggleMenu();
        setIsHovered(false);

        setAddTabActive(false);
        setUserInfoActive(false);
    };

    const handleLogout = () => {
        localStorage.removeItem("userData");
        window.location.href = '/login';
    }

    const resetMenuActive = () => {
        setMenuActive(false);
        document.body.classList.remove('hide-menu');
    };

    const handleMenuItemClick = (event) => {
        resetMenuActive();
    };

    return (
        <div className={sidebarClasses} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="logo-hamburger-menu mobile-view">
                <Link className='logotype' to={'/'}>
                    <img src={logo} alt="Logotype" />
                    <span>Sark</span>
                </Link>
                <div className='mobile-menuRight'>
                    <button className='hamburger-menu' onClick={handleHamburgerClick} >
                        <span></span>
                    </button>
                </div>
            </div>
            <nav id="myNav">
                <div className="menuWrapper">
                    <div className="logo-hamburger-menu desktop-view">
                        <Link className="logotype" to={"/"}>
                            <img src={logo} alt="Logotype" /><span>&nbsp;Sark</span>
                        </Link>
                        <button className="hamburger-menu" onClick={handleHamburgerClick} >
                            <span></span>
                        </button>
                    </div>
                    <div className="menu-wrapperBox">
                        <ul className="menuList">
                            <li>
                                <Link className={(props.activePage === 'dashboard') ? 'menuListItem menuItemActive' : 'menuListItem'} to="/"  onClick={handleMenuItemClick}>
                                    <img src={home} className="home-icon" alt="home-icon" />
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            <li>
                                <Link className={(props.activePage === 'users') ? 'menuListItem menuItemActive' : 'menuListItem'} to="/users" onClick={handleMenuItemClick}>
                                    <img src={contact} className="contact-icon" alt="home-icon" />
                                    <span>Users</span>
                                </Link>
                            </li>
                            <li>
                            <Link className={(props.activePage === 'team') ? 'menuListItem menuItemActive' : 'menuListItem'} to="/team" onClick={handleMenuItemClick}>
                                    <img src={headphone} className="home-icon" alt="headphone-icon" />
                                    <span>Team</span>
                                </Link>
                            </li>
                            <li>
                                <Link className={(props.activePage === 'setting') ? 'menuListItem menuItemActive' : 'menuListItem'} to="/setting" onClick={handleMenuItemClick}>
                                    <img src={setting} className="home-icon" alt="setting-icon" />
                                    <span>Settings</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="user-action" ref={ref}>
                        <div className="menuRight">
                            <div className="userProfile">
                                <div className={`avatarVisible ${userInfoActive === true ? "active" : "" }`} >
                                    <div className="sidebar-user">
                                        <img className="avatar" src={(props?.userData?.profileMediaUrl !== "" && props?.userData?.profileMediaUrl !== undefined) ? props?.userData?.profileMediaUrl : profilepicture} alt="Avatar" />
                                    </div>
                                    <div className="sidebar-user-content">
                                        <p className="sidebar-user-name">
                                            {props?.userData?.firstName} {props?.userData?.lastName}
                                        </p>
                                        <p className="sidebar-user-mail">
                                            {props?.userData?.email}
                                        </p>
                                    </div>
                                </div>
                                <div className="sidebar-logout">
                                    <img className="icon-logout" src={icon_logout} alt="icon-logout" onClick={handleLogout} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}
export default Header;
