function UserRoleActionDropdown(props) {
    return (
        <div className="dropdown addDropdown userolesDropdown">
            <ul>
                {props.userRoles.map((userrole) => (
                    <li
                        key={userrole}
                        onClick={() => {
                            const result = props.selected.includes(userrole)
                                ? props.selected.filter(
                                      (selectedRole) =>
                                          selectedRole !== userrole
                                  )
                                : [...props.selected, userrole];
                            props.changeRole(result);
                            props.setCurrentPage(1)
                        }}
                    >
                        <input className="userolesSelect" type="checkbox" checked={props.selected.includes(userrole)} />
                        <p>{userrole}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default UserRoleActionDropdown;
