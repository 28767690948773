import React from 'react'

// import bglogin02 from '../../images/bg-login/bg-01.png'

import bglogin01 from '../../images/bg-login/bg-01.png'
import bglogin02 from '../../images/bg-login/bg-02.png'
import bglogin03 from '../../images/bg-login/bg-03.png'
import bglogin04 from '../../images/bg-login/bg-04.png'
import bglogin05 from '../../images/bg-login/bg-05.png'

import bglogin11 from '../../images/bg-login/bg-11.png'
import bglogin12 from '../../images/bg-login/bg-12.png'
import bglogin13 from '../../images/bg-login/bg-13.png'
import bglogin14 from '../../images/bg-login/bg-14.png'
import bglogin15 from '../../images/bg-login/bg-15.png'

import bglogin21 from '../../images/bg-login/bg-21.png'
import bglogin22 from '../../images/bg-login/bg-22.png'
import bglogin23 from '../../images/bg-login/bg-23.png'
import bglogin24 from '../../images/bg-login/bg-24.png'
import bglogin25 from '../../images/bg-login/bg-25.png'

import bglogin31 from '../../images/bg-login/bg-31.png'
import bglogin32 from '../../images/bg-login/bg-32.png'
import bglogin33 from '../../images/bg-login/bg-33.png'
import bglogin34 from '../../images/bg-login/bg-34.png'
import bglogin35 from '../../images/bg-login/bg-35.png'

import bglogin41 from '../../images/bg-login/bg-41.png'
import bglogin42 from '../../images/bg-login/bg-42.png'
import bglogin43 from '../../images/bg-login/bg-43.png'
import bglogin44 from '../../images/bg-login/bg-44.png'
import bglogin45 from '../../images/bg-login/bg-45.png'

import bglogin51 from '../../images/bg-login/bg-51.png'
import bglogin52 from '../../images/bg-login/bg-52.png'
import bglogin53 from '../../images/bg-login/bg-53.png'
import bglogin54 from '../../images/bg-login/bg-54.png'
import bglogin55 from '../../images/bg-login/bg-55.png'

import bglogin61 from '../../images/bg-login/bg-61.png'
import bglogin62 from '../../images/bg-login/bg-62.png'
import bglogin63 from '../../images/bg-login/bg-63.png'
import bglogin64 from '../../images/bg-login/bg-64.png'
import bglogin65 from '../../images/bg-login/bg-65.png'

import bglogin71 from '../../images/bg-login/bg-71.png'
import bglogin72 from '../../images/bg-login/bg-72.png'
import bglogin73 from '../../images/bg-login/bg-73.png'
import bglogin74 from '../../images/bg-login/bg-74.png'
import bglogin75 from '../../images/bg-login/bg-75.png'

const BgFormAnimate = () => {

  return (
    <div className='bg-images-animate'>
        <div className="bg-gradient"></div>
        <div className='wrapper-vertical'>
          <div className="marquee marquee-vertical">
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin01} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin02} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin03} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin04} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin05} alt="bgLogin" />
                  </div>
                </div>
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin01} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin02} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin03} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin04} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin05} alt="bgLogin" />
                  </div>
                </div>
          </div>
          <div className="marquee marquee-vertical">
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin11} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin12} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin13} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin14} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin15} alt="bgLogin" />
                  </div>
                </div>
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin11} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin12} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin13} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin14} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin15} alt="bgLogin" />
                  </div>
                </div>
          </div>
          <div className="marquee marquee-vertical">
                <div className="marquee-group">
                  <div className="bg-image-item">
                    <img src={bglogin21} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin22} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin23} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin24} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin25} alt="bgLogin" />
                  </div>
                </div>
                <div className="marquee-group">
                  <div className="bg-image-item">
                    <img src={bglogin21} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin22} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin23} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin24} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin25} alt="bgLogin" />
                  </div>
                </div>
          </div>
          <div className="marquee marquee-vertical">
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin31} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin32} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin33} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin34} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin35} alt="bgLogin" />
                  </div>
                </div>
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin31} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin32} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin33} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin34} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin35} alt="bgLogin" />
                  </div>
                </div>
          </div>
          <div className="marquee marquee-vertical">
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin41} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin42} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin43} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin44} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin45} alt="bgLogin" />
                  </div>
                </div>
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin41} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin42} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin43} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin44} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin45} alt="bgLogin" />
                  </div>
                </div>
          </div>
          <div className="marquee marquee-vertical">
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin51} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin52} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin53} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin54} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin55} alt="bgLogin" />
                  </div>
                </div>
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin51} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin52} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin53} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin54} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin55} alt="bgLogin" />
                  </div>
                </div>
          </div>
          <div className="marquee marquee-vertical">
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin61} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin62} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin63} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin64} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin65} alt="bgLogin" />
                  </div>
                </div>
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin61} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin62} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin63} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin64} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin65} alt="bgLogin" />
                  </div>
                </div>
          </div>
          <div className="marquee marquee-vertical">
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin71} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin72} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin73} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin74} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin75} alt="bgLogin" />
                  </div>
                </div>
                <div className="marquee-group">
                  <div className="bg-image-item">
                      <img src={bglogin71} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin72} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin73} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin74} alt="bgLogin" />
                  </div>
                  <div className="bg-image-item">
                      <img src={bglogin75} alt="bgLogin" />
                  </div>
              </div>
        </div>
    </div>
  </div> 
  )
}

export default BgFormAnimate
