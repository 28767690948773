import React, { useRef, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import moment from "moment";

import UserPlanActionDropdown from "./UserPlanActionDropdown";
import DeleteUser from "./DeleteUser";
import NoResultsUsers from "./NoResultsUsers";
import Pagination from "../../components/Pagination";

import {formattedDate, getCountryName, getCountryWithFlag, isUserAdmin} from "../../utils/functions";

import avatar from "../../images/profile-user.svg";
import Down from "../../images/down-arrow.svg";
import search from "../../images/search.svg";
import Top from "../../images/arrow_top.svg";
import clear from "../../images/delete.svg";
import more from "../../images/Icon.png";
import penIcon from "../../images/pen-img.svg";
import Delete from "../../images/delete.svg";
import iconDropDown from "../../images/icondropdown.svg";


const UsersTable = () => {
    const ref = useRef();
    const modalRef = useRef();
    const usercountryRef = useRef();
    const tableActionRef = useRef();

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [isOpen, setOpen] = useState(false);

    const [userData, setUserData] = useState([]);
    const [userList, setUserList] = useState([]);
    const [originalData, setOriginalData] = useState(userList);

    const [userCountrySelect, setUserCountrySelect] = useState(false)
    const [countryList, setCountryList] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');

    const [userPlanSelect, setUserPlanSelect] = useState(false);
    const [userPlanList, setUserPlanList] = useState([]);
    const [selectedUserPlan, setSelectedUserPlan] = useState([]);

    const [dateSort, setDateSort] = useState(false);
    const [countrySort, setCountrySort] = useState(false);
    const [nameSort, setNameSort] = useState(false);
    
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [readFields, setReadFields] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const [userPlanData, setUserPlanData] = useState([])
    const userPlan = ["Free", 'Unlimited', 'Personal Assistant']
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setUserPlanSelect(false);
            }

            if (usercountryRef.current && !usercountryRef.current.contains(event.target)) {
                setUserCountrySelect(false)
            }

            if (tableActionRef.current && !tableActionRef.current.contains(event.target)) {
                setUserData([]);
                setOpen(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getcountries?type=customer`);

                if( response.status == 200 ) {
                    setCountryList(response.data.result || [])
                }
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchData(); // Call the async function immediately
    }, []);



    const abortControllerRef = useRef(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            abortControllerRef.current = new AbortController();
            const search = searchInput.toLowerCase();
            const countries = selectedCountryCode.length > 0 ? selectedCountryCode.join(',') : '';
            const changePlanName = userPlanData.map((plan) => {
                switch (plan) {
                  case 'Free':
                    return 'free_plan,basic_plan';
                  case 'Unlimited':
                    return 'unlimited_plan';
                  case 'Personal Assistant':
                    return 'personal_assistant_plan';
                  default:
                    return plan;
                }
              }).join(',');
            try {
                setIsLoading(true);
                let response;
                if (search === "") {
                    response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getusers/?page=${currentPage}&search=${search}&countries=${countries}&planName=${changePlanName}`);
                } else {

                    response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getusers/?page=${currentPage}&search=${search}&countries=${countries}&planName=${changePlanName}`, {
                        signal: abortControllerRef.current.signal
                    });
                }
                if (response.status == 200) {
                    const usersArray = response.data.users.map(item => ({
                        ...item,
                        countryName: getCountryName(item?.country || ''),
                    }));

                    setTotalPage(response.data.totalPages || 1);
                    setOriginalData(usersArray);
                    setUserList(usersArray);
                    setIsLoading(false);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        };

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        setDebounceTimeout(
            setTimeout(() => {
                fetchData();
            }, 1000) 
        );

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [searchInput, currentPage, selectedCountryCode, userPlanData]);

    useEffect(() => {
        if (originalData !== undefined) {
            const uniqueCountries = [];
            const uniquePlans = [];
            originalData.forEach((element) => {
                if (!uniqueCountries.includes(element.country)) {
                    uniqueCountries.push(element.country);
                }
                if (!uniquePlans.includes(element.plan)) {
                    uniquePlans.push(element.plan);
                }
            });
            setUserPlanList(uniquePlans);
            setUserList(originalData);
        } else {
            setUserPlanSelect(false);
            setUserPlanList([]);
            setUserList([]);
        }
    }, [originalData]);

    /*
    useEffect(() => {
        let filteredData = originalData;
    
        if (selectedCountryCode.length > 0) {
            filteredData = originalData.filter(element => selectedCountryCode.includes(element.country));
        }
    
        if (selectedUserPlan.length > 0) {
            filteredData = filteredData.filter(element => selectedUserPlan.includes(element.plan));
        }
    
        setUserList(filteredData.length > 0 ? filteredData : (selectedCountryCode.length > 0 ? originalData : []));
    }, [selectedCountryCode, selectedUserPlan]);
    */
    const onClose = (e) => {
        setOpen(false);
    };

    const handleDeleteActionEvent = () => {
        if (userData.length != 0 || selectedItems.length != 0) {
            setOpen(true);
        }
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = userList.map((item) => item._id);
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    const togglePopup = (user) => {
        if (userData && user && user._id === userData._id) {
            setUserData([]);
        } else {
            setOpen(false);
            setUserData(user);
        }
    };

    const handleActionEvent = (e) => { };

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter(
                    (_id) => _id !== itemId
                );
                setSelectAll(updatedSelectedItems.length === userList.length);
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(updatedSelectedItems.length === userList.length);
                return updatedSelectedItems;
            }
        });
    };

    function sortByDate() {
        var result = [];

        if (dateSort === false) {
            result = [...userList].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format("ll");
                var daysA = moment(dateA).diff(now, "Days");

                var dateB = moment(b.createdAt).format("ll");
                var daysB = moment(dateB).diff(now, "Days");

                return daysA - daysB;
            });
        } else {
            result = [...userList].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format("ll");
                var daysA = moment(dateA).diff(now, "Days");

                var dateB = moment(b.createdAt).format("ll");
                var daysB = moment(dateB).diff(now, "Days");

                return daysB - daysA;
            });
        }
        setUserList(result);
    }

    const [planSort, setPlanSort] = useState(false);
    function sortByPlan() {
        var result;
        if (planSort === true) {
            result = [...userList].sort((a, b) => a?.planDetail?.plan.localeCompare(b?.planDetail?.plan));
        } else {
            result = [...userList].sort(
                (a, b) => -1 * a?.planDetail?.plan.localeCompare(b?.planDetail?.plan)
            );
        }
        setUserList(result);
    }

    function sortByCountry() {
        var result;
        if (countrySort === true) {
            result = [...userList].sort((a, b) =>
                a.country.localeCompare(b.country)
            );
        } else {
            result = [...userList].sort(
                (a, b) => -1 * a.country.localeCompare(b.country)
            );
        }
        setUserList(result);
    }

    function sortByName() {
        var result;
        if (nameSort === true) {
            result = [...userList].sort((a, b) => a.displayName.localeCompare(b.displayName));
        } else {
            result = [...userList].sort(
                (a, b) => -1 * a.displayName.localeCompare(b.displayName)
            );
        }
        setUserList(result);
    }

    return (
        <>
            <div className="pagesSearch pageUser">
                <div className="pageSearchTop">
                    <div className="pageSearchInput">
                        <input
                            type="text"
                            id="pages_search"
                            className="inputFields"
                            name="pages_search"
                            placeholder={"Search user member..."}
                            value={searchInput}
                            autoComplete="off"
                            onChange={(e) => {setSearchInput(e.target.value); setCurrentPage(1);}}
                            readOnly={readFields} 
                            onFocus={ () => setReadFields(false) } 
                            onBlur={ () => setReadFields(true) }
                        />
                        <img src={search} alt="Search" />
                    </div>
                    
                    <div ref={usercountryRef} className="select-country dropdown-wrapp">
                        <button className="selectFields" onClick={(e) => setUserCountrySelect(!userCountrySelect)}>
                            All Countries{" "}
                            {selectedCountryCode.length > 0 ? `(${selectedCountryCode.length})` : ""}
                        </button>
                        {
                            userCountrySelect === true && 
                                <div className="dropdown addDropdown countryDropdown">
                                    <ul>
                                        {countryList && countryList.length > 0 && 
                                            countryList.map((country) => (
                                                <li key={country} onClick={() => {
                                                    const result = selectedCountryCode.includes(country)
                                                        ? selectedCountryCode.filter(
                                                            (selectcountry) =>
                                                            selectcountry !== country
                                                        )
                                                        : [...selectedCountryCode, country];
                                                    setSelectedCountryCode(result);
                                                    setCurrentPage(1);
                                                }}>
                                                    <input className="countrySelect" type="checkbox" checked={selectedCountryCode.includes(country)} />
                                                    <span className="country-flag">{getCountryWithFlag(country)}</span>
                                                </li>
                                                
                                            ))
                                        } 
                                    </ul>
                                </div>
                        }
                    </div>

                    <div ref={modalRef} className="userrole-dropdown dropdown-wrapp">
                        <button className="userPlanBtn"
                            onClick={(e) => setUserPlanSelect(!userPlanSelect)}>
                            All Plans{" "}
                            {userPlanData.length > 0
                                ? `(${userPlanData.length})`
                                : ""}
                            <img src={iconDropDown} alt="User Plans" />
                        </button>
                        {userPlanSelect === true && (
                            <UserPlanActionDropdown userPlans={userPlan} selected={userPlanData} changePlan={setUserPlanData} />
                        )}
                    </div>
                </div>
                {isUserAdmin() && (
                                    <div className="deleteIcon">
                                    <img
                                        className="deleteIconImage"
                                        src={clear}
                                        alt="Clear Input"
                                        onClick={() => handleDeleteActionEvent()}
                                    />
                                </div>
                )}

            </div>
            <div className="table-data-wrapper table-pagination-wrapper">
                <div className="table-data table-five-column">
                    <div className="table-head">
                        <div className="table-status">
                            <input
                                type={"checkbox"}
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                            <div className="full-name down-arrow">
                                <p className="table-full-name" onClick={(e) => { setNameSort(!nameSort); sortByName(); }} >
                                    Full Name
                                </p>
                                <img onClick={(e) => { setNameSort(!nameSort); sortByName(); }} src={nameSort === false ? Down : Top} alt="Down" />
                            </div>
                        </div>
                        <div
                            className="country"
                            onClick={(e) => { setCountrySort(!countrySort); sortByCountry(); }}
                        >
                            <p className="table-country">Country</p>
                            <img src={ countrySort === false ? Down : Top} alt="Down" />
                        </div>
                        <div className="date-user" onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} >
                            <p className="table-title">Date</p>
                            <img src={dateSort === false ? Down : Top} alt="Down" />
                        </div>
                        <div className="plan" onClick={(e) => { setPlanSort(!planSort); sortByPlan(); }} >
                            <p className="table-title">Plan</p>
                            <img src={planSort === false ? Down : Top} alt="Down" />
                        </div>
                        <div className="actions">
                            <p className="table-title">Action</p>
                        </div>
                    </div>
                    {isLoading ? 
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                        :    
                        <>
                            {userList.length > 0 ? (
                                
                                userList.map((item) => {
                                    return (
                                        <div className={`${"table-item"}`} key={item._id}>
                                            <div className="table-status">
                                                <div className="user-status">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedItems.includes( item._id )}
                                                        onChange={() => handleCheckboxChange(item._id) }
                                                    />
                                                    <div className="user-content">
                                                        <img src={`${item?.profileMedia == "" ? avatar : item?.profileMedia }`} />
                                                        <div className="user-brief">
                                                            <span className="user-name">
                                                                {
                                                                    item?.status === "active" ? 
                                                                        <Link to={`/users/${item._id}`}>{item?.displayName || 'NO NAME'}</Link> :
                                                                        <span>{item?.displayName || 'NO NAME'}</span>
                                                                }
                                                            </span>
                                                            <span className="user-mail">{item.email}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-country">
                                                <span className="country-flag">{getCountryWithFlag(item.country)}</span>
                                            </div>
                                            <div className="subscription-date">
                                                <span>{formattedDate(item.createdAt)}</span>
                                            </div>
                                            <div className="subscription-plan" >
                                                <span className={`plan-btn ${item.plan == 'pro' && 'pro-btn'}`}>{`${(item?.planDetail?.plan === "free_plan" || item?.planDetail?.plan === "basic_plan" || item?.planDetail === "FREE") ? "Free" : (item?.planDetail?.plan === "unlimited_plan") ? "Unlimited" : "Personal Assistant"}`}</span>
                                            </div>
                                            <div className="table-action" ref={ref}>
                                                <span>
                                                    <img onClick={() => togglePopup(item) } src={more} alt="Down" />
                                                </span>
                                                {userData &&
                                                    userData._id == item._id && (
                                                        <div className="table-data-dropdown" ref={tableActionRef}>
                                                            <ul>
                                                                {
                                                                    item.status !== "inactive" &&
                                                                    <li>
                                                                        <Link to={`/users/${item._id}`} onClick={ handleActionEvent } >
                                                                            <img src={penIcon} alt="pen-icon" />
                                                                            Edit
                                                                        </Link>
                                                                    </li>
                                                                }
                                                                {isUserAdmin() && (
                                                                    <li>
                                                                    <span onClick={ handleDeleteActionEvent } >
                                                                        <img src={Delete} alt="delete-icon" />
                                                                        Delete
                                                                    </span>
                                                                </li>
                                                                )}
                                                                
                                                            </ul>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                    <>
                                        {
                                            !isLoading &&
                                            <NoResultsUsers />
                                        }
                                    </>
                            )}
                        </>
                    }
                </div>
                {
                    totalPage > 1 && 
                        <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                }                
            </div>
            <DeleteUser
                refrence={tableActionRef}
                isOpen={isOpen}
                userData={userData}
                selectedItems={selectedItems}
                onClose={onClose}
                setUserList={setUserList}
                togglePopup={togglePopup}
                setOpen={setOpen}
                setSelectAll={setSelectAll}
                setSelectedItems={setSelectedItems}
                userList= {userList}
                setUserData= {setUserData}
            />
        </>
    );
};

export default UsersTable;
