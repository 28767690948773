import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import moment from "moment";
import axios from 'axios';

import { formattedDate } from "../../utils/functions";

import moreIcon from "../../images/more.svg";
import Delete from "../../images/delete.svg";
import Down from "../../images/down-arrow.svg";
import penIcon from "../../images/pen-img.svg";
import linkIMG from "../../images/link.svg";
import iconDropDown from "../../images/icondropdown.svg";
import CheckboxActionDropdown from "../../components/CheckboxActionDropdown";
import search from "../../images/search.svg";
import plus from '../../images/plus.svg'

import Pagination from "../../components/Pagination";
import DeletePublicationModal from "./DeletePublicationModel";
import NoResultSubscriber from "./NoResultSubscriber";

export const Subscriber = (props) => {
    const { id } = useParams();
    const ref = useRef();
    const publicationStatusRef = useRef();
    const publicationCatRef = useRef();
    const popupRef = useRef();

    props.setActivePage("Subscriber");
    // props.setHideUserInfo(false);

    const baseUrl = `https://jourmal-next-front.vercel.app/${props.profileSlug}`;

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [userStatusSelect, setPublicationStatusSelect] = useState(false);
    const [userCategorySelect, setPublicationCategorySelect] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedSubscriberStatus, setSelectedSubscriberStatus] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [readFields, setReadFields] = useState(true);
    const excludedItemIds = [];

    const [originalData, setOriginalData] = useState([]);
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const statusList = ['Free Subscribers', 'Paid Subscribers', 'Monthly Subscribers', 'Yearly Subscribers', 'Trial subscribers'];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (publicationStatusRef.current && !publicationStatusRef.current.contains(event.target)) {
                setPublicationStatusSelect(false);
            }

            if (publicationCatRef.current && !publicationCatRef.current.contains(event.target)) {
                setPublicationCategorySelect(false);
            }

            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupitem([]);
                setOpen(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscriber/list/${id}?page=${currentPage}&filterSubscriber=${selectedSubscriberStatus}&search=${search}&planName=${selectedCategory}`);
            setOriginalData(response?.data?.result || []);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error(error, "error");
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(false)
            try {
                setIsLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscriber/list/${id}?planName=${selectedCategory}`);
                const value = response?.data?.planName?.map((item) =>{
                    return item?.planName
                })
                setCategoryList(value || [])
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                console.error(error, "error");
            }
        };
        fetchData();
    }, [id]);
    useEffect(() => {
        fetchData();
    }, [id,searchInput,selectedCategory,currentPage,selectedSubscriberStatus]);
    
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedSubscriberStatus,selectedCategory]);
    useEffect(() => {
        searchPage();
    }, [searchInput, originalData, selectedSubscriberStatus]);

    function searchPage() {
        const input = searchInput.toLowerCase();
        let filteredData = originalData;
      
        if (input !== '') {
          filteredData = filteredData.filter(element =>
            element?.email?.toLowerCase().startsWith(input)
          );
        }
        setPages(filteredData);
    }


    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
        } else {
            setOpen(false);
            setOpenPopupitem(item);
        }
    };

    const [emailSort, setEmailSort] = useState(false);
    function sortByEmail() {
        var result;
        if (emailSort === true) {
            result = [...pages].sort((a, b) =>
                a.email.localeCompare(b.email)
            );
        } else {
            result = [...pages].sort(
                (a, b) => -1 * a.email.localeCompare(b.email)
            );
        }
        setPages(result);
    }

    const [statusSort, setStatusSort] = useState(false);
    function sortByStatus() {
        var result;
        if (statusSort === true) {
            result = [...pages].sort((a, b) =>
                a.status.localeCompare(b.status)
            );
        } else {
            result = [...pages].sort(
                (a, b) => -1 * a.status.localeCompare(b.status)
            );
        }
        setPages(result);
    }
    const [planSort, setPlanSort] = useState(false)
    function sortByplan() {
        var result;

        if (planSort === true) {
            result = [...pages].sort((a, b) =>
                a.planName.localeCompare(b.planName)
            );
        } else {
            result = [...pages].sort(
                (a, b) => -1 * a.planName.localeCompare(b.planName)
            );
        }
        setPages(result)
    }
    const [dateSort, setDateSort] = useState(false);
    const [lastUpdatedDateSort, setLastUpdatedSort] = useState(false);
    function sortBy(key) {
        return function (a, b) {
            var now = moment();

            var dateA = moment(a[key]).format("ll");
            var daysA = moment(dateA).diff(now, "Days");

            var dateB = moment(b[key]).format("ll");
            var daysB = moment(dateB).diff(now, "Days");

            if (dateSort) {
                return daysA - daysB;
            } else if (lastUpdatedDateSort) {
                // Use the appropriate property for last updated date
                // Assuming it's named 'lastUpdatedDate', replace it with your actual property name
                var lastUpdatedDateA = moment(a.lastUpdatedDate).format("ll");
                var lastUpdatedDaysA = moment(lastUpdatedDateA).diff(now, "Days");

                var lastUpdatedDateB = moment(b.lastUpdatedDate).format("ll");
                var lastUpdatedDaysB = moment(lastUpdatedDateB).diff(now, "Days");

                return lastUpdatedDaysA - lastUpdatedDaysB;
            }
            return 0; // Default case
        };
    }

    function sortByDate() {
        var result = [...pages].sort(sortBy("createdAt"));
        setPages(dateSort ? result : result.reverse());
        setDateSort(!dateSort);
    }

    function sortByLastUpdated() {
        var result = [...pages].sort(sortBy("updatedAt")); // Update to "lastUpdated"
        setPages(lastUpdatedDateSort ? result : result.reverse());
        setLastUpdatedSort(!lastUpdatedDateSort);
    }

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = pages.map((item) => item._id).filter((itemId) => !excludedItemIds.includes(itemId));
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter(
                    (_id) => _id !== itemId
                );
                setSelectAll(
                    updatedSelectedItems.length === pages?.length
                );
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(
                    updatedSelectedItems.length === pages?.length
                );
                return updatedSelectedItems;
            }
        });
    };

    const handleActionEvent = (e) => {
        togglePopup(null);
    };

    const handleDeleteActionEvent = (item) => {
        if (item.length != 0 || selectedItems.length != 0) {
            setOpen(true);
        }
    };

    const deletePublication = (itemID) => {
        const idsToRemove = Array.isArray(itemID) ? itemID : [itemID];
        const updatedUsers = pages.filter(
            (page) => !idsToRemove.includes(page._id)
        );
        setPages(updatedUsers);
        togglePopup(null);
        setOpen(false);
        setSelectAll(false);
    };
   


    const onClose = (e) => {
        setOpen(false);
    };


    return (
        <>
            <section className="dashboard">
                <div className="pagesTableWrapper">
                    <div className="pagesSearch pageTeam">
                        <div className="pageSearchTop pageSearchTopMobile">
                            <div className="pageSearchInput">
                                <input
                                    type="text"
                                    name="pages_search"
                                    id="pages_search"
                                    className="inputFields"
                                    placeholder={"Search subscriber..."}
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    autocomplete="off"
                                    readOnly={readFields}
                                    onFocus={() => setReadFields(false)}
                                    onBlur={() => setReadFields(true)}
                                />
                                <img src={search} alt="Search" />
                            </div>
                            {
                                props.billingDetails?.subscriptions?.plan !== "free_plan" &&
                            <div className="userrole-dropdown userrole-mobiledropdown" ref={publicationStatusRef}>
                                <button className="selectFields subscriberFillter" onClick={(e) => setPublicationStatusSelect(!userStatusSelect)}>
                                    All Subscriber{" "}
                                    {selectedSubscriberStatus.length > 0 ? `(${selectedSubscriberStatus.length})` : ""}
                                    <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userStatusSelect === true) ? <CheckboxActionDropdown options={statusList} selectedOptions={selectedSubscriberStatus} changeOption={setSelectedSubscriberStatus} /> : ''
                                }
                            </div>
                            }

                            <div className="category-dropdown userrole-dropdown userrole-mobiledropdown" ref={publicationCatRef}>
                                <button className="selectFields subscriberPlan" onClick={(e) => setPublicationCategorySelect(!userCategorySelect)}>
                                    All Plan{" "}
                                    {selectedCategory.length > 0 ? `(${selectedCategory.length})` : ""}
                                    <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userCategorySelect === true) ? <CheckboxActionDropdown options={categoryList} selectedOptions={selectedCategory} changeOption={setSelectedCategory} /> : ''
                                }
                            </div>
                        </div>
                        {/* <div className="actions-buttons">
                            <div className="deleteIcon">
                                <img onClick={() => handleDeleteActionEvent([])} className="deleteIconImage" src={Delete} alt="Clear Input" />
                            </div>

                        </div> */}
                    </div>
                    <div className={pages?.length > 0 ? "pagesTable" : "pagesTable tableBottomRounded"} >
                        <div className="table-data-wrapper table-pagination-wrapper">
                            <div className="table-data table-seven-column">
                                <div className="table-head table-subscriber">
                                    <div className="table-status">
                                        <input type={"checkbox"} checked={selectAll} onChange={handleSelectAll} />
                                        <div className={`title ${emailSort === false ? "down-arrow" : "up-arrow"}`} onClick={(e) => { setEmailSort(!emailSort); sortByEmail(); }} >
                                            <p className="table-title">Email</p>
                                            <img src={Down} onClick={(e) => { setEmailSort(!emailSort); sortByEmail(); }} alt="Down" />
                                        </div>
                                    </div>
                                    <div className={`team-member-role tableDataSubscribeStatus ${statusSort === false ? "down-arrow" : "up-arrow"}`} >
                                        <p className="table-title" onClick={(e) => { setStatusSort(!statusSort); sortByStatus(); }} >Status</p>
                                        <img src={Down} onClick={(e) => { setStatusSort(!statusSort); sortByStatus(); }} alt="Down" />
                                    </div>
                                    <div className={`team-category tableDataSubscribePlan ${planSort === false ? "down-arrow" : "up-arrow"}`} >
                                        <p className="table-title" onClick={(e) => { setPlanSort(!planSort); sortByplan(); }} >Plan</p>
                                        <img src={Down} onClick={e => { setPlanSort(!planSort); sortByplan(); }} alt="Down" />
                                    </div>
                                    <div className={`date-user tableDataSubscribeDate ${dateSort === false ? "down-arrow" : "up-arrow"}`}>
                                        <p className="table-country" onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} > Date</p>
                                        <img src={Down} onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} alt="Down" />
                                    </div>
                                    {/* <div className={`last-updated-date ${lastUpdatedDateSort === false ? "down-arrow" : "up-arrow"}`}>
                                        <p className="table-title" onClick={(e) => { setLastUpdatedSort(!lastUpdatedDateSort); sortByLastUpdated(); }} >
                                            Last Updated
                                        </p>
                                        <img src={Down} onClick={(e) => { setLastUpdatedSort(!lastUpdatedDateSort); sortByLastUpdated(); }} alt="Down" />
                                    </div>
                                    <div className="link">Link</div> */}
                                    {/* <div className="actions">Actions</div> */}
                                </div>
                                {isLoading ? 
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                    </div>
                                    :
                                    (pages?.length > 0) ?
                                        pages?.map((item) => {
                                            return (
                                                <div className="table-item" key={item._id}>
                                                    <div className="table-status">
                                                        <div className="user-status">
                                                            <input type="checkbox" checked={selectedItems.includes(item._id)} onChange={() => handleCheckboxChange(item._id)} />
                                                            <span>{item.email}</span>
                                                            {/* <div className="user-content">{item.title}</div> */}
                                                        </div>
                                                    </div>
                                                    <div className={`table-status  tableDataSubscribeStatus ${item?.emailVerification === true ? 'Published' : 'Draft'}`}>
                                                        <span className="status-btn">{(item?.emailVerification === true) ? 'Verified' : 'Unverified'}</span>
                                                    </div>
                                                    <div className="table-category tableDataSubscribePlan ">
                                                        <span className="status-btn">
                                                            {item?.planName} {item?.trialData?.isTrial === true && `(Trial)`}
                                                        </span>
                                                    </div>
                                                    <div className="table-action tableDataSubscribeAction ">
                                                        <span>{formattedDate(item.createdAt)}</span>
                                                    </div>
                                                    {/* <div className="table-date">
                                                        <span>{formattedDate(item.updatedAt)}</span>
                                                    </div>
                                                    <div className="table-link">
                                                        <Link to={`${baseUrl}`} target={'_blank'} className="link-btn">
                                                            <img className="socialIcon" src={linkIMG} alt="SocialIcon" />
                                                            Link
                                                        </Link>
                                                    </div> */}
                                                    {/* <div className="table-action tableDataSubscribeAction" onClick={() => togglePopup(item)} ref={ref} >
                                                        <img src={moreIcon} alt="More" />
                                                    </div> */}
                                                    {openPopupitem &&
                                                        openPopupitem._id == item._id && (
                                                            <div className="table-data-dropdown" ref={popupRef}>
                                                                <ul>
                                                                    <li>
                                                                        <span onClick={() => handleDeleteActionEvent(item)} >
                                                                            <img src={Delete} alt="pen-icon" />
                                                                            Delete
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                </div>
                                            );
                                        })
                                        :
                                        <NoResultSubscriber />
                                }
                            </div>
                            {
                                totalPage > 1 &&
                                <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            }
                        </div>
                    </div>
                </div>
            </section>

            <DeletePublicationModal
                isOpen={isOpen}
                item={openPopupitem}
                selectedItems={selectedItems}
                deletePublication={deletePublication}
                onClose={onClose}
                setOpen={setOpen}
                refrence={popupRef}
                fetchData={fetchData}
                originalData={pages}
            />
        </>
    );
}
