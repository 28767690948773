function UserPlanActionDropdown(props) {
    
    return (
        <div className="dropdown addDropdown userolesDropdown">
            <ul>
                {props.userPlans.length > 0 &&  props.userPlans.map((plan, index) => (
                    <li
                        key={index}
                        onClick={() => {
                            const result = props.selected.includes(plan)
                                ? props.selected.filter(
                                      (selectedPlan) =>
                                          selectedPlan !== plan
                                  )
                                : [...props.selected, plan];
                            props.changePlan(result);
                        }}
                    >
                        <input className="userolesSelect" type="checkbox" checked={props.selected.includes(plan)} />
                        <p>{plan}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default UserPlanActionDropdown;
