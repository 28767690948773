import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import moment from "moment";
import axios from 'axios';

import { formattedDate } from "../../utils/functions";

import moreIcon from "../../images/more.svg";
import Delete from "../../images/delete.svg";
import Down from "../../images/down-arrow.svg";
import penIcon from "../../images/pen-img.svg";
import linkIMG from "../../images/link.svg";
import iconDropDown from "../../images/icondropdown.svg";
import CheckboxActionDropdown from "../../components/CheckboxActionDropdown";
import search from "../../images/search.svg";
import plus from '../../images/plus.svg'

import Pagination from "../../components/Pagination";
import NoResultsPublication from "./NoResultPublication";
import DeletePublicationModal from "./DeletePublicationModel";
import Notification from "../../components/Notification";

const Publication = (props) => {
    const { id } = useParams();

    const ref = useRef();
    const publicationStatusRef = useRef();
    const publicationCatRef = useRef();
    const popupRef = useRef();

    props.setActivePage("Publications");
    props.setHideUserInfo(false);

    const baseUrl = `https://jourmal-next-front.vercel.app/${props.profileSlug}`;

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [userStatusSelect, setPublicationStatusSelect] = useState(false);
    const [userCategorySelect, setPublicationCategorySelect] = useState(false);
    const [selectedPublicationCategory, setSelectedPublicationCategory] = useState([]);
    const [selectedPublicationStatus, setSelectedPublicationStatus] = useState([]);
    const [categoryList, setPublicationCategoryList] = useState([]);
    const [readFields, setReadFields] = useState(true);
    const excludedItemIds = [];

    const [originalData, setOriginalData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [openNotify, setOpenNotify] = useState(false);
    const [multiDeteleNotify, setMultiDeteleNotify] = useState(false);
    const [selectedPubLenght, setSelectedPubLenght] = useState(0);

    const statusList = ['Published', 'Draft', 'Deleted'];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (publicationStatusRef.current && !publicationStatusRef.current.contains(event.target)) {
                setPublicationStatusSelect(false);
            }

            if (publicationCatRef.current && !publicationCatRef.current.contains(event.target)) {
                setPublicationCategorySelect(false);
            }

            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupitem([]);
                setOpen(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const abortControllerRef = useRef(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const fetchData = async () => {
        setIsLoading(false);
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();
        const search = searchInput.toLowerCase();
        const status = selectedPublicationStatus.length > 0 ? selectedPublicationStatus.join(',') : '';
        const categories = selectedPublicationCategory.length > 0 ? selectedPublicationCategory.join(',') : '';
        try {
            setIsLoading(true);
            let response;
            if (search === "") {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/publicationlist/${id}?page=${currentPage}&search=${search}&status=${status}&categories=${categories}`);
            } else {

                response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/publicationlist/${id}?page=${currentPage}&search=${search}&status=${status}&categories=${categories}`, {
                    signal: abortControllerRef.current.signal
                });
            }
            setOriginalData(response?.data?.result || []);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "error");
        }
    };
    useEffect(() => {
        
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        setDebounceTimeout(
            setTimeout(() => {
                fetchData();
            }, 1000) 
        );

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [searchInput, currentPage, selectedPublicationStatus, selectedPublicationCategory, id]);

    const fetchCatData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/categories/getpublicationscats/${id}`,
            )

            setPublicationCategoryList(response.data.result || [])
        } catch (error) {
            console.log(error, "error");
        }
    }

    useEffect(() => {
        fetchCatData();
    }, [id, currentPage, searchInput, selectedPublicationStatus, selectedPublicationCategory]);


    useEffect(() => {
        fetchCatData();
    }, [id]);

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedPublicationStatus]);

    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
        } else {
            setOpen(false);
            setOpenPopupitem(item);
        }
    };

    const [titleSort, setTitleSort] = useState(false);
    function sortByTitle() {
        var result;
        if (titleSort === true) {
            result = [...originalData].sort((a, b) =>
                a.title.localeCompare(b.title)
            );
        } else {
            result = [...originalData].sort(
                (a, b) => -1 * a.title.localeCompare(b.title)
            );
        }
        setOriginalData(result);
    }

    const [statusSort, setStatusSort] = useState(false);
    function sortByStatus() {
        var result;
        if (statusSort === true) {
            result = [...originalData].sort((a, b) =>
                a.status.localeCompare(b.status)
            );
        } else {
            result = [...originalData].sort(
                (a, b) => -1 * a.status.localeCompare(b.status)
            );
        }
        setOriginalData(result);
    }

    const [dateSort, setDateSort] = useState(false);
    const [lastUpdatedDateSort, setLastUpdatedSort] = useState(false);
    function sortBy(key) {
        return function (a, b) {
            var now = moment();

            var dateA = moment(a[key]).format("ll");
            var daysA = moment(dateA).diff(now, "Days");

            var dateB = moment(b[key]).format("ll");
            var daysB = moment(dateB).diff(now, "Days");

            if (dateSort) {
                return daysA - daysB;
            } else if (lastUpdatedDateSort) {
                // Use the appropriate property for last updated date
                // Assuming it's named 'lastUpdatedDate', replace it with your actual property name
                var lastUpdatedDateA = moment(a.lastUpdatedDate).format("ll");
                var lastUpdatedDaysA = moment(lastUpdatedDateA).diff(now, "Days");

                var lastUpdatedDateB = moment(b.lastUpdatedDate).format("ll");
                var lastUpdatedDaysB = moment(lastUpdatedDateB).diff(now, "Days");

                return lastUpdatedDaysA - lastUpdatedDaysB;
            }
            return 0; // Default case
        };
    }

    function sortByDate() {
        var result = [...originalData].sort(sortBy("createdAt"));
        setOriginalData(dateSort ? result : result.reverse());
        setDateSort(!dateSort);
    }

    function sortByLastUpdated() {
        var result = [...originalData].sort(sortBy("updatedAt")); // Update to "lastUpdated"
        setOriginalData(lastUpdatedDateSort ? result : result.reverse());
        setLastUpdatedSort(!lastUpdatedDateSort);
    }

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = originalData.map((item) => item._id).filter((itemId) => !excludedItemIds.includes(itemId));
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter(
                    (_id) => _id !== itemId
                );
                setSelectAll(
                    updatedSelectedItems.length === originalData.length
                );
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(
                    updatedSelectedItems.length === originalData.length
                );
                return updatedSelectedItems;
            }
        });
    };

    const handleActionEvent = (e) => {
        togglePopup(null);
    };

    const handleDeleteActionEvent = (item) => {
        if (item.length != 0 || selectedItems.length != 0) {
            setOpen(true);
        }
    };

    const deletePublication = (itemID) => {
        const idsToRemove = Array.isArray(itemID) ? itemID : [itemID];
        const updatedUsers = originalData.filter(
            (page) => !idsToRemove.includes(page._id)
        );
        setOriginalData(updatedUsers);
        togglePopup(null);
        setOpen(false);
        setSelectAll(false);
    };

    const [categorySort, setCategorySort] = useState(false)
    function sortByCategory() {
        var result;

        result = [...originalData].sort((a, b) => {
            if (categorySort) {
                if (a.categories.length === 0) return -1;
                if (b.categories.length === 0) return 1
            } else {
                if (a.categories.length === 0) return 1;
                if (b.categories.length === 0) return -1
            }

            const categoryA = a.categories[0];
            const categoryB = b.categories[0];

            // Sort in ascending order
            if (categorySort) {
                if (categoryA < categoryB) return -1;
                if (categoryA > categoryB) return 1;
                return 0;
            }
            // Sort in descending order
            else {
                if (categoryA > categoryB) return -1;
                if (categoryA < categoryB) return 1;
                return 0;
            }
        });

        setOriginalData(result)
    }


    const onClose = (e) => {
        setOpen(false);
    };


    return (
        <>
            <section className="dashboard">
                <div className="pagesTableWrapper">
                    <div className="pagesSearch pageTeam">
                        <div className="pageSearchTop">
                            <div className="pageSearchInput">
                                <input
                                    type="text"
                                    name="pages_search"
                                    id="pages_search"
                                    className="inputFields"
                                    placeholder={"Search publications..."}
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    autocomplete="off"
                                    readOnly={readFields} 
                                    onFocus={ () => setReadFields(false) } 
                                    onBlur={ () => setReadFields(true) }
                                />
                                <img src={search} alt="Search" />
                            </div>
                            <div className="userrole-dropdown" ref={publicationStatusRef}>
                                <button className="selectFields" onClick={(e) => setPublicationStatusSelect(!userStatusSelect)}>
                                    All Publications{" "}
                                    {selectedPublicationStatus.length > 0 ? `(${selectedPublicationStatus.length})` : ""}
                                    <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userStatusSelect === true) ? <CheckboxActionDropdown options={statusList} selectedOptions={selectedPublicationStatus} changeOption={setSelectedPublicationStatus} /> : ''
                                }
                            </div>
                            <div className="category-dropdown userrole-dropdown" ref={publicationCatRef}>
                                <button className="selectFields" onClick={(e) => setPublicationCategorySelect(!userCategorySelect)}>
                                    All Categories{" "}
                                    {selectedPublicationCategory.length > 0 ? `(${selectedPublicationCategory.length})` : ""}
                                    <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userCategorySelect === true) ? <CheckboxActionDropdown options={categoryList} selectedOptions={selectedPublicationCategory} changeOption={setSelectedPublicationCategory} /> : ''
                                }
                            </div>
                        </div>
                        <div className="actions-buttons">
                            <div className="deleteIcon">
                                <img onClick={() => handleDeleteActionEvent([])} className="deleteIconImage" src={Delete} alt="Clear Input" />
                            </div>
                            <div className="add-new-member">
                                <Link to={`/users/${id}/publications/publication/`}>
                                    <button className="form-btn">
                                        <img src={plus} alt="plus" />
                                        New Publication
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={originalData.length > 0 ? "pagesTable" : "pagesTable tableBottomRounded"} >
                        <div className="table-data-wrapper table-pagination-wrapper">
                            <div className="table-data table-seven-column">
                                <div className="table-head">
                                    <div className="table-status">
                                        <input type={"checkbox"} checked={selectAll} onChange={handleSelectAll} />
                                        <div className={`title ${titleSort === false ? "down-arrow" : "up-arrow"}`} onClick={(e) => { setTitleSort(!titleSort); sortByTitle(); }} >
                                            <p className="table-title">Title</p>
                                            <img src={Down} onClick={(e) => { setTitleSort(!titleSort); sortByTitle(); }} alt="Down" />
                                        </div>
                                    </div>
                                    <div className={`team-member-role ${statusSort === false ? "down-arrow" : "up-arrow"}`} >
                                        <p className="table-title" onClick={(e) => { setStatusSort(!statusSort); sortByStatus(); }} >Status</p>
                                        <img src={Down} onClick={(e) => { setStatusSort(!statusSort); sortByStatus(); }} alt="Down" />
                                    </div>
                                    <div className={`team-category ${categorySort === false ? "down-arrow" : "up-arrow"}`} >
                                        <p className="table-title" onClick={(e) => { setCategorySort(!categorySort); sortByCategory(); }} >Categories</p>
                                        <img src={Down} onClick={e => { setCategorySort(!categorySort); sortByCategory(); }} alt="Down" />
                                    </div>
                                    <div className={`date-user ${dateSort === false ? "down-arrow" : "up-arrow"}`}>
                                        <p className="table-country" onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} > Date</p>
                                        <img src={Down} onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} alt="Down" />
                                    </div>
                                    <div className={`last-updated-date ${lastUpdatedDateSort === false ? "down-arrow" : "up-arrow"}`}>
                                        <p className="table-title" onClick={(e) => { setLastUpdatedSort(!lastUpdatedDateSort); sortByLastUpdated(); }} >
                                            Last Updated
                                        </p>
                                        <img src={Down} onClick={(e) => { setLastUpdatedSort(!lastUpdatedDateSort); sortByLastUpdated(); }} alt="Down" />
                                    </div>
                                    <div className="link">Link</div>
                                    <div className="actions">Actions</div>
                                </div>
                                {isLoading ?
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                    </div>
                                    :
                                    (originalData.length > 0) ?
                                        originalData.map((item) => {
                                            return (
                                                <div className="table-item" key={item._id}>
                                                    <div className="table-status">
                                                        <div className="user-status">
                                                            <input type="checkbox" checked={selectedItems.includes(item._id)} onChange={() => handleCheckboxChange(item._id)} />
                                                            <Link to={`/users/${id}/publications/publication/${item._id}`}>{item.title}</Link>
                                                            {/* <div className="user-content">{item.title}</div> */}
                                                        </div>
                                                    </div>
                                                    <div className={`table-status ${item.status}`}>
                                                        <span className="status-btn">{item.status}</span>
                                                    </div>
                                                    <div className="table-category">
                                                        <span className="status-btn">
                                                            {item.categories?.map((category, index) => (
                                                                <span key={index} className="category">{category} </span>
                                                            ))}
                                                        </span>
                                                    </div>
                                                    <div className="table-date">
                                                        <span>{formattedDate(item.createdAt)}</span>
                                                    </div>
                                                    <div className="subscription-lastupdated">
                                                        <span>{formattedDate(item.updatedAt)}</span>
                                                    </div>
                                                    <div className="table-link">
                                                    <Link to={`${baseUrl}/${item.url_address}`} target={'_blank'} className="link-btn">
                                                            <img className="socialIcon" src={linkIMG} alt="SocialIcon" />
                                                            Link
                                                        </Link>
                                                    </div>
                                                    <div className="table-action" onClick={() => togglePopup(item)} ref={ref} >
                                                        <img src={moreIcon} alt="More" />
                                                    </div>
                                                    {openPopupitem &&
                                                        openPopupitem._id == item._id && (
                                                            <div className="table-data-dropdown" ref={popupRef}>
                                                                <ul>
                                                                    <li>
                                                                        <Link to={`/users/${id}/publications/publication/${item._id}`} onClick={handleActionEvent} >
                                                                            <img src={penIcon} alt="pen-icon" />
                                                                            Edit
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <span onClick={() => handleDeleteActionEvent(item)} >
                                                                            <img src={Delete} alt="pen-icon" />
                                                                            Delete
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                </div>
                                            );
                                        })
                                        :
                                        <NoResultsPublication />
                                }
                            </div>
                            {
                                totalPage > 1 &&
                                <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            }
                        </div>
                    </div>
                </div>
            </section>

            <DeletePublicationModal
                isOpen={isOpen}
                item={openPopupitem}
                selectedItems={selectedItems}
                onClose={onClose}
                setOpen={setOpen}
                refrence={popupRef}
                fetchData={fetchData}
                originalData={originalData}
                setOpenNotify={setOpenNotify}
                setMultiDeteleNotify={setMultiDeteleNotify}
                setSelectedPubLenght={setSelectedPubLenght}
                setSelectedItems={setSelectedItems}
            />
            { openNotify && 
                <Notification title={`The publication was successfully deleted!`} setOpenNotify={setOpenNotify} type={"success"} openNotify={openNotify} />
            }
            { multiDeteleNotify && 
                <Notification title={`${selectedPubLenght} publications were successfully deleted!`} setOpenNotify={setMultiDeteleNotify} type={"success"} openNotify={multiDeteleNotify} />
            }
        </>
    );
}
export default Publication;