import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const timeComponents = [];

  if (hours > 0) {
    timeComponents.push(`${hours}H`);
  }
  if (minutes > 0) {
    timeComponents.push(`${minutes}m`);
  }
  if (remainingSeconds > 0 || timeComponents.length === 0) {
    timeComponents.push(`${remainingSeconds}s`);
  }

  if (seconds > 0 && remainingSeconds == 0) {
    timeComponents.push(`00s`);
  }

  return timeComponents.join(' ');
}

export const formatISO = (date, type = 'day') => {

  let formatedValue = dayjs(date).format('YYYY-MM-DD');

  if( type == 'month' ) {
    formatedValue = dayjs(date).format('MMMM YYYY');
  }

  if( type == 'year' ) {
    formatedValue = dayjs(date).format('YYYY');
  }

  return formatedValue;
}

export const today = dayjs().format('YYYY-MM-DD');
export const currentMonth = dayjs().format('MMMM YYYY');
export const currentYear = dayjs().format('YYYY');
export const firstDateOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
export const lastDateOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');
export const firstDateOfLastMonth = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
export const lastDateOfLastMonth = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
export const firstDateOfYear = dayjs().startOf('year').format('YYYY-MM-DD');
export const lastDateOfYear = dayjs().endOf('year').format('YYYY-MM-DD');

const browserDateFormat = Intl.DateTimeFormat(navigator.language, { hour: 'numeric' })

export const getCompareDate = (date, period, comparison, customDates = { startDate: '', endDate: '' }) => {

  let compareDate = date;

  if(comparison === 'previous_period')  {
    const currentDate = dayjs(date);
  
    if(period === 'day') {
      let previousDate = currentDate.subtract(1, 'day');
      compareDate = previousDate.format('YYYY-MM-DD');
    }

    if(period === '7d') {
      let previousDate = currentDate.subtract(7, 'day');
      compareDate = previousDate.format('YYYY-MM-DD');
    }

    if(period === '30d') {
      let previousDate = currentDate.subtract(31, 'day');
      compareDate = previousDate.format('YYYY-MM-DD');  
    }

    if(period === '12mo') {
      let previousDate = currentDate.subtract(1, 'year');
      compareDate = previousDate.format('YYYY-MM-DD');  
    }

    if(period === 'month' || period === 'last_month') {
      // Get the first day of the previous month
      const firstDayOfPreviousMonth = currentDate.subtract(1, 'month').startOf('month');

      // Get the last day of the previous month
      const lastDayOfPreviousMonth = currentDate.subtract(1, 'month').endOf('month');

      compareDate = `${firstDayOfPreviousMonth.format('YYYY-MM-DD')},${lastDayOfPreviousMonth.format('YYYY-MM-DD')}`;
    }

    if( period === 'year' ) {
      // Get the start date of the previous year
      const startOfPreviousYear = currentDate.subtract(1, 'year').startOf('year');

      // Get the end date of the previous year
      const endOfPreviousYear = currentDate.subtract(1, 'year').endOf('year');

      compareDate = `${startOfPreviousYear.format('YYYY-MM-DD')},${endOfPreviousYear.format('YYYY-MM-DD')}`;
    }

    if(period === 'custom') {
      const [startDate, endDate] = date.split(',');
      const startDateDay = dayjs(startDate);
      const endDateDay = dayjs(endDate);
      let startDateFormatter = startDateDay.subtract(7, 'day');
      let endDateFormatter = endDateDay.subtract(7, 'day');
      compareDate = `${startDateFormatter.format('YYYY-MM-DD')},${endDateFormatter.format('YYYY-MM-DD')}`;
    }
  }

  
  if(comparison === 'year_over_year') {
    const currentDate = dayjs(date);

    const prevYearDate = currentDate.subtract(1, 'year');
    compareDate = prevYearDate.format('YYYY-MM-DD');

    if(period === 'month' || period === 'last_month') {
      // Get the start date of the previous year
      const startOfPreviousYear = currentDate.subtract(1, 'year');

      // Get the end date of the previous year
      const endOfPreviousYear = startOfPreviousYear.endOf('month');

      compareDate = `${startOfPreviousYear.format('YYYY-MM-DD')},${endOfPreviousYear.format('YYYY-MM-DD')}`;
    }

    if( period === 'year' ) {
      // Get the start date of the previous year
      const startOfPreviousYear = currentDate.subtract(1, 'year').startOf('year');

      // Get the end date of the previous year
      const endOfPreviousYear = currentDate.subtract(1, 'year').endOf('year');

      compareDate = `${startOfPreviousYear.format('YYYY-MM-DD')},${endOfPreviousYear.format('YYYY-MM-DD')}`;
    }

    if(period === 'custom') {
      const [startDate, endDate] = date.split(',');
      const startDateDay = dayjs(startDate);
      const endDateDay = dayjs(endDate);
      let startDateFormatter = startDateDay.subtract(1, 'year');
      let endDateFormatter = endDateDay.subtract(1, 'year');
      compareDate = `${startDateFormatter.format('YYYY-MM-DD')},${endDateFormatter.format('YYYY-MM-DD')}`;
    }    
  }
  
  if( comparison === 'custom_period' ) {   
    if( customDates.startDate !== '' && customDates.endDate !== '' ) {
      compareDate = `${dayjs(customDates.startDate).format('YYYY-MM-DD')},${dayjs(customDates.endDate).format('YYYY-MM-DD')}`;
    }
  }
  
  return compareDate;
}

export const getPrevDate = (date, type = 'day') => {
  if( date ) {
    let prevDate = dayjs(date).subtract(1, 'day').format('YYYY-MM-DD');

    if(type === 'month') {
      const [month, year] = date.split(' ');

      // Create a dayjs object for the target month
      const targetMonth = dayjs(`${year}-${month}-01`);

      prevDate = targetMonth.subtract(1, 'month').format('MMMM YYYY');
    }

    if(type === 'year') {
      const year = parseInt(date.split(' ')[2]);    
      prevDate = year - 1;
    }

    return prevDate;
  }
}

export const getNextDate = (date, type = 'day') => {
  if( date ) {
    let nextDate = dayjs(date).add(1, 'day').format('YYYY-MM-DD');

    if(type === 'month') {
      const [month, year] = date.split(' ');

      // Create a dayjs object for the target month
      const targetMonth = dayjs(`${year}-${month}-01`);

      nextDate = targetMonth.add(1, 'month').format('MMMM YYYY');
    }

    if(type === 'year') {
      const year = parseInt(date.split(' ')[2]);
      nextDate = year + 1;
    }

    return nextDate;
  }
}

export const getDateRangeOfMonth = (date) => {  
  const monthYearString = ( date ) ? date : currentMonth;

  // Parse the month and year from the input string
  const [month, year] = monthYearString.split(' ');

  // Create a dayjs object for the first day of the target month
  const firstDayOfMonth = dayjs(`${year}-${month}-01`);

  // Get the last day of the target month
  const lastDayOfMonth = firstDayOfMonth.endOf('month').format('YYYY-MM-DD');

  const dateRange = {startDate: firstDayOfMonth.format('YYYY-MM-DD'), endDate: lastDayOfMonth}

  return dateRange;
}

export const getDateRangeOfYear = (date) => {  
  const yearString = ( date ) ? parseInt(date.split(' ')[2]) : currentYear;

  // Get the start date of the year
  const startDate = dayjs(`${yearString}-01-01`).format('YYYY-MM-DD');

  // Get the end date of the year
  const endDate = dayjs(`${yearString}-12-31`).format('YYYY-MM-DD');

  const dateRange = {startDate: startDate, endDate: endDate}

  return dateRange;
}


const parseUTCDate = (dateString) => {
  return dayjs.utc(dateString)
}

const formatMonthYYYY = (date) => {
  return date.format('MMMM YYYY')
}

const formatDayShort = (date, includeYear = false) => {
  if (includeYear) {
    return date.format('DD MMMM, YYYY')
  } else {
    return date.format('D MMM')
  }
}

const is12HourClock = function () {
  return browserDateFormat.resolvedOptions().hour12
}

const monthIntervalFormatter = {
  long(isoDate, options) {
    const formatted = this.short(isoDate, options)
    return options.isBucketPartial ? `Partial of ${formatted}` : formatted
  },
  short(isoDate, _options) {
    return formatMonthYYYY(parseUTCDate(isoDate))
  }
}

const weekIntervalFormatter = {
  long(isoDate, options) {
    const formatted = this.short(isoDate, options)
    return options.isBucketPartial ? `Partial week of ${formatted}` : `Week of ${formatted}`
  },
  short(isoDate, options) {
    return formatDayShort(parseUTCDate(isoDate), options.shouldShowYear)
  }
}

const dateIntervalFormatter = {
  long(isoDate, _options) {
    return parseUTCDate(isoDate).format('ddd, D MMM')
  },
  short(isoDate, options) {
    return formatDayShort(parseUTCDate(isoDate), options.shouldShowYear)
  }
}

const hourIntervalFormatter = {
  long(isoDate, options) {
    return this.short(isoDate, options)
  },
  short(isoDate, _options) {
    if (is12HourClock()) {
      return parseUTCDate(isoDate).format('ha')
    } else {
      return parseUTCDate(isoDate).format('HH:mm')
    }
  }
}

const minuteIntervalFormatter = {
  long(isoDate, options) {
    if (options.period == 'realtime') {
      const minutesAgo = Math.abs(isoDate)
      return minutesAgo === 1 ? '1 minute ago' : minutesAgo + ' minutes ago'
    } else {
      return this.short(isoDate, options)
    }
  },
  short(isoDate, options) {
    if (options.period === 'realtime') return isoDate + 'm'

    if (is12HourClock()) {
      return parseUTCDate(isoDate).format('h:mm')
    } else {
      return parseUTCDate(isoDate).format('HH:mm')
    }
  }
}

const factory = {
  month: monthIntervalFormatter,
  week: weekIntervalFormatter,
  date: dateIntervalFormatter,
  hour: hourIntervalFormatter,
  minute: minuteIntervalFormatter
}


export default function dateFormatter(date, interval, period, longForm = true, shouldShowYear= false) {
  const displayMode = longForm ? 'long' : 'short'
  const options = { period: period, interval: interval, isBucketPartial: false, shouldShowYear: shouldShowYear }

  const formatedDate = dayjs(date).format('YYYY-MM-DD HH:mm');

  return factory[interval][displayMode](formatedDate, options);
}