function CheckboxActionDropdown(props) {
    return (
        <div className="dropdown addDropdown userolesDropdown" ref={props.refrence}>
            <ul>
                {props.options.map((status, index) => (
                    <li
                        key={index}
                        onClick={() => {
                            const result = props.selectedOptions.includes(status)
                                ? props.selectedOptions.filter(
                                      (selectedStatus) =>
                                          selectedStatus !== status
                                  )
                                : [...props.selectedOptions, status];
                            props.changeOption(result);
                        }}
                    >
                        <input
                            className="userolesSelect"
                            type="checkbox"
                            checked={props.selectedOptions.includes(status)}
                        />
                        <p>{status}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default CheckboxActionDropdown;
