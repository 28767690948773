import React from 'react'
import close from '../../images/close.svg'
export const UpgradePopUp = (props) => {
    return (
        <>
            <div className="popupModalOverlay"></div>
            <div className='popupModal upgradepopup' ref={props.refrence}>
                <div className="popupTop popupTopClose closePlan">
                <h3 className='popupModalTitle'>{props?.name === "media" ? "Media Upload Fail" : "Limit of data"}</h3>
                    <img src={close} onClick={() => {props.setShowUpgradeBtn(false)}} alt="Icon" />
                </div>
                <div className="popupModalContent">

                    <p>{props?.name === "media" ? "This file type is not supported under the Free plan. Please upgrade to access more features." : "You have reached the limit of data for this plan. Please upgrade to access more features."}</p>
                </div>
                <div className="popupModalBtn">
                    <button className="savePicture" type="button" onClick={() => { props.setShowUpgradeBtn(false) }}>
                        OK
                    </button>
                </div>
            </div>
        </>
    )
}
