import React, { useRef, useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import axios from "axios";

import Select, { components } from "react-select";
import ImageEditor from "../../components/ImageEditor/ImageEditor";
import Loader from "../../components/Loader/Loader";

import "../css/team.css";

import iconEmail from "../../images/icon-mail.svg"
import iconPhone from "../../images/icon-phone.svg"
import 'react-phone-input-2/lib/style.css'
import avatar from "../../images/profile-user.svg";
import coverImg from "../../images/picture.svg";
import avatarProfile from "../../images/cover_image.svg"
import selectDown from "../../images/select_down.svg";

import {dataURLtoFile, isUserAdmin} from "../../utils/functions";

const General = ({ userData, setUserData, setUserUpdate }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [userRole, setUserRole] = useState("");
    const [phone, setPhone] = useState('');


    const profileFileInputRef = useRef(null);
    const [image, setImage] = useState(null);

    const profileModalRef = useRef(null);
    const [profileIsDragging, setProfileIsDragging] = useState(false);
    const [saveProfileImage, setSaveProfileImage] = useState(null);
    const [profileCoverModal, setProfileCoverModal] = useState(false);
    const [loadingProfileImg, setloadingProfileImg] = useState(false);
    const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
    const [replcaeProfileImageZoom, setReplcaeProfileImageZoom] = useState(false);
    const [oldProfileZoom, setOldProfileZoom] = useState(1);
    const [profileZoom, setProfileZoom] = useState(1);
    const [oldProfileImage, setOldProfileImage] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [profilePosition, setProfilePosition] = useState({ x: 0.5, y: 0.5 });
    const [newProfileUploadedImage, setNewProfileUploadedImage] = useState(null);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [profileCropedImageId, setProfileCropedImageId] = useState('');
    const [profileImageId, setProfileImageId] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [selectedProfileImageData, setSelectedProfileImageData] = useState(null);
    const [uploadMediaPopupFor, setUploadMediaPopupFor] = useState('');
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    });
    const [userMedia, setUserMedia] = useState([]);

    useEffect(() => {
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setEmail(userData.email || '');
        setSelectedCountry(userData.country || '');
        setUserRole(userData.role);
        setPhone(`${userData.phone}` || '');
        setProfileCropedImageId(userData?.userMediaData?.profileMedia?.cropedImage?._id);
        setProfileImageId(userData?.userMediaData?.profileMedia?.selectedImage?._id);
    }, [userData]);

    const validateInputs = () => {
        let valid = true;
        const newErrors = {};
        if (firstName.trim() === "") {
            newErrors.firstName = "First Name is required";
            valid = false;
        }

        if (lastName.trim() === "") {
            newErrors.lastName = "Last Name is required";
            valid = false;
        }

        if (email.trim() === "") {
            newErrors.email = "Email address is required";
            valid = false;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                newErrors.email = "Invalid email format";
                valid = false;
            }
        }

        if (phone.trim() === "") {
            newErrors.phone = "Phone number is required";
            valid = false;
        } else {
            const phoneRegex = /^[0-9]+$/;
            if (!phoneRegex.test(phone)) {
                newErrors.phone = "Phone Number must be numeric";
                valid = false;
            }
        }

        if (userRole.length == 0) {
            newErrors.userRole = "User role is required";
            valid = false;
        }

        if (selectedCountry.trim() === "") {
            newErrors.countryCode = "Country is required";
            valid = false;
        }

        // File Input Validation
        // const selectedFile = profileFileInputRef?.current?.files[0];
        // if (!selectedFile) {
        //     newErrors.file = "Please select a file";
        //     valid = false;
        // }

        setErrors(newErrors);
        return valid;
    };
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchData();
      }, [userData?.userMediaData]);
    
      const fetchData = async () => {
        try {
            if(userData?.userMediaData?.profileMedia?.cropedImage) {
              const imageData = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/fetchImageData/${userData._id}`,
                userData?.userMediaData?.profileMedia?.cropedImage,
                { responseType: 'blob' }
              );
    
              const imageUrl = URL.createObjectURL(imageData.data);
    
              setImage(imageUrl);
              setOldProfileImage(imageUrl);
              setProfileCropedImageId(userData?.userMediaData?.profileMedia?.cropedImage)
            }
    
            if(userData?.userMediaData?.profileMedia?.selectedImage) {
              const imageData = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/fetchImageData/${userData._id}`,
                userData?.userMediaData?.profileMedia?.selectedImage,
                { responseType: 'blob' }
              );
    
              const imageUrl = URL.createObjectURL(imageData.data);
              setOldProfileImage(imageUrl);
              setNewProfileUploadedImage(imageUrl);
              setSaveProfileImage(imageUrl);
              setProfileImageId(userData?.userMediaData?.profileMedia?.selectedImage?._id);
            }
            if(userData?.profilePosition?.profileZoom) {
              setProfileZoom(userData?.profilePosition?.profileZoom)
              setOldProfileZoom(userData?.profilePosition?.profileZoom)
            }
    
            if(userData?.profilePosition?.profilePosition) {
              setProfilePosition(userData?.profilePosition?.profilePosition)
            }
    
        } catch (error) {
          console.log(error, "error");
        }
      };

    const handleFormSubmit = async () => {
        try {
            if (validateInputs()) {
                setIsLoading(true);
                let profileCropedImage = profileCropedImageId;
                let profileImage = profileImageId;
        
                const featureImageData = await getFeatureImageData(userData._id)
        
                profileCropedImage = featureImageData?.featureCropedImage || profileCropedImage;
                profileImage = featureImageData?.selectedImage || profileImage;
                const updateData = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    country: selectedCountry,
                    phone: phone,
                    role: userRole,
                    profileMedia: {
                        selectedImage: profileImage,
                        cropedImage: profileCropedImage,
                        profilePosition: profilePosition,
                        profileZoom: profileZoom
                    },
                };
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/updateuser/${userData._id}`,
                    updateData
                );
                
                const updatedUserData = { ...userData, ...updateData };
                setUserData(updatedUserData);
                setUserUpdate(true)
            }

            setIsLoading(false);
        } catch (error) {
            const newErrors = {};

            if (error.response?.data) {
                const errors = error.response.data.errors;

                errors.forEach((err) => {
                    if (err.emailError) {
                        newErrors.email = err.message;
                    }

                    if (err.profileError) {
                        newErrors.profileUrl = err.message;
                    }
                });
            }

            setErrors(newErrors);
            setIsLoading(false);
        }
    };

    const handleCountryChange = (countryCode) => {
        setSelectedCountry(countryCode);
        setPhone('');
    };

    const options = [
        { value: "admin", label: "Admin" },
        { value: "support", label: "Support" },
        { value: "assistant", label: "Assistant" },
    ];

    function handleDragOver(e) {
        e.preventDefault();
        if (!profileIsDragging) {
            setProfileIsDragging(true);
        }
    }
    const handleProfileDragLeave = (e) => {
        e.preventDefault();
        setProfileIsDragging(false);
    };

    const handleProfileClick = () => {
        setProfileCoverModal(!profileCoverModal);
    };

    const handleProfileImageLoad = () => {
        const img = new Image();
        img.src = image;
        img.onload = () => { };
    };

    const handleMouseDown = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.add("slider-cursor");
    };

    const handleMouseUp = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.remove("slider-cursor");
    };

    const handleTouchStart = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.add("slider-cursor");
    };

    const handleTouchEnd = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.remove("slider-cursor");
    };

    const handleProfileZoom = (profileZoom) => {
        setProfileZoom(profileZoom);
    };

    const handleProfilePosition = (newProfilePosition) => {
        setProfilePosition(newProfilePosition);
    };

    const handleReplaceProfileImg = () => {
        document.getElementById("upload-img").click();
        setReplcaeProfileImageZoom(true);
        setUploadMediaPopupFor('croped-picture')
    };

    const handleSaveProfilePicture = async () => {
        let click = document.getElementById("clickFunction");
        click.click();
        setImage(image);
        setNewProfileUploadedImage(oldProfileImage);
        setSaveProfileImage(image);
        setProfileCoverModal(false);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-uploaded");
        setOldProfileZoom(profileZoom);
        setSelectedProfileImageData(imageFile)
    };

    const handleProfileCancel = () => {
        if (saveProfileImage !== null) {
            var divElement = document.getElementById("profile-picturebox");
            divElement.classList.add("profile-uploaded");
        } else {
            var divElement = document.getElementById("profile-picturebox");
            divElement.classList.remove("profile-uploaded");
        }
        setOldProfileImage(newProfileUploadedImage);
        setProfileCoverModal(false);
        setProfileZoom(oldProfileZoom);

        if (profileFileInputRef.current) {
            profileFileInputRef.current.value = null;
            setUploadedProfileImage(null);
            setImageFile(null);
        }
    };

    function handleDrop(e) {
        let uploadedMediaId = 1
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.remove("profile-uploaded");
        setloadingProfileImg(true);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-border");
        var TempReplcaeProfileImageZoom = replcaeProfileImageZoom;

        if (image) {
            setReplcaeProfileImageZoom(true);

            TempReplcaeProfileImageZoom = true;
        }

        if (TempReplcaeProfileImageZoom === true) {
            setOldProfileZoom(1);
            setProfileZoom(1);
        } else {
            setProfileZoom(oldProfileZoom);
        }

        if (TempReplcaeProfileImageZoom === true) {
            setOldProfileZoom(1);
            setProfileZoom(1);
        } else {
            setProfileZoom(oldProfileZoom);
        }

        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        const imageFileUrl = URL.createObjectURL(file);
        reader.onload = () => {
            TempReplcaeProfileImageZoom !== true && setImage(reader.result);
            setOldProfileImage(reader.result);
            setImageFile(imageFileUrl);
        };

        if (file) {
            reader.readAsDataURL(file);
            setUploadedProfileImage(null);
            setTimeout(() => {
                reader.readAsDataURL(file);
                setloadingProfileImg(false);
                setUploadedProfileImage(file);
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.add("profile-uploaded");
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.remove("profile-border");
                setProfileCoverModal(true);
                setReplcaeProfileImageZoom(false);
                setImageFile(imageFileUrl);
            }, 4000);
        } else {
            setloadingProfileImg(false);
        }
        setProfileIsDragging(false);
    }

    function handleFileUpload(e) {
        let uploadedMediaId = 1
        setloadingProfileImg(true);
        setDisableSaveButton(true);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-border");
        if (replcaeProfileImageZoom === true) {
            setOldProfileZoom(1);
            setProfileZoom(1);
        } else {
            setProfileZoom(oldProfileZoom);
        }
        const file = e.target.files[0];
        const reader = new FileReader();
        const uploadMedia = {
            _id: uploadedMediaId,
            name: file?.name,
            description: '',
            alt: '',
            type: file?.type,
            size: file?.size,
            object_id: "",
            key: "",
            url: "",
            createdAt: "",
            isLoading: true
        }
        const imageFileUrl = URL.createObjectURL(file);
        reader.onload = () => {
            replcaeProfileImageZoom !== true && setImage(reader.result);
            setOldProfileImage(reader.result);

            setImageFile(imageFileUrl);
        };
        if (file) {
            reader.readAsDataURL(file);
            setUploadedProfileImage(null);
            setUserMedia((prevUserMedia) => [uploadMedia, ...prevUserMedia]);
            setTimeout(() => {
                reader.readAsDataURL(file);
                setloadingProfileImg(false);
                setDisableSaveButton(false);
                setUploadedProfileImage(file);
                setImageFile(imageFileUrl);
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.add("profile-uploaded");
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.remove("profile-border");
                setProfileCoverModal(true);
                setReplcaeProfileImageZoom(false);
            }, 4000);
        } else {
            setloadingProfileImg(false);
            setDisableSaveButton(false);
        }
    }

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <img src={selectDown} alt="select-down" />
            </components.DropdownIndicator>
        );
    };

    const colourStyles = {
        option: (styles, { isFocused, isSelected, isDisabled }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? "#f9f9f9 !important"
                    : isFocused
                        ? "#f9f9f9 !important"
                        : "transparent",
                fontSize: "14px",
                cursor: "pointer",
                color: "#344054",
            };
        },
        input: (provided, state) => ({
            ...provided,
            height: "34px",
            padding: 0,
            margin: 0,
            borderRadius: "8px",
            fontSize: "14px",
            cursor: "pointer",
        }),
        noOptionsMessage: (provided, state) => ({
            ...provided,
            fontSize: "14px",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            color: "#101828",
        }),
        control: (provided, state) => ({
            ...provided,
            border: "none",
            borderRadius: "8px",
            width: "100%",
        }),
        control: (base) => ({
            ...base,
            border: 0,
            boxShadow: "none !important",
            borderRadius: "8px",
            marginRTop: "2px",
        }),
        container: (provided, state) => ({
            ...provided,
            paddingLeft: "0px",
        }),
        placeholder: (styles, { isFocused }) => {
            return {
                ...styles,
                marginBottom: isFocused ? "0px" : "0px",
                fontSize: "14px",
                marginLeft: "0",
                color: "#667085",
            };
        },
        indicatorSeparator: (state) => ({
            display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            borderRadius: "8px",
            outline: "none",
            marginRight: "5px",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            paddingLeft: "15px",
            paddingRight: "15px",
        }),
    };


    
      const getFeatureImageData = async (id) => {
        let uploadedMediaId=1;
        let selectedImage = profileImageId;
        let featureCropedImage = profileCropedImageId;
        const uploadMedia = {
            _id: uploadedMediaId,
            name: imageFile?.name,
            description: '',
            alt: '',
            type: imageFile?.type,
            size: imageFile?.size,
            object_id: "",
            key: "",
            url: "",
            createdAt: "",
            isLoading: true
        }
        if (imageFile !== null) {
            const featureImageFile = await dataURLtoFile(imageFile, "profile-picture.jpg");
            const imageFormData = new FormData();
            imageFormData.append('files', featureImageFile); 
          try {
            const res = await axios.post(
              `${process.env.REACT_APP_API_URL}/admin/uploadMedia/${userData._id}`,
              imageFormData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
            );
            
            selectedImage = res.data.result[0]?._id;

            const featureImageFile = await dataURLtoFile(image, "croped-picture.jpg");
            const formData = new FormData();
            formData.append('files', featureImageFile);
            formData.append('id', id);
            const uploadedMedia = await axios.post(
              `${process.env.REACT_APP_API_URL}/admin/uploadMedia/${userData._id}`,
              formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
            );
            setUserMedia((prevUserMedia) =>
                prevUserMedia.map((media) =>
                    media._id === uploadMedia._id ? { ...media, ...uploadedMedia.data.result[0], isLoading: false } : media
                )
            );
            featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;
    
          } catch (error) {
            console.log(error);
          }
          // profileImageId !== '' || selectedProfileImageData?._id) && oldImage !== saveProfileImage
        } else if ((profileImageId !== "" || selectedProfileImageData?._id) || oldProfileImage !== saveProfileImage) {

            const featureImageFile = await dataURLtoFile(image, "croped-picture.jpg");
            
            const formData = new FormData();
            formData.append('files', featureImageFile);
            formData.append('id', id);
            
            const uploadedMedia = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/uploadMedia/${userData._id}`,
                formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
    setUserMedia((prevUserMedia) =>
        prevUserMedia.map((media) =>
            media._id === uploadMedia._id ? { ...media, ...uploadedMedia.data.result[0], isLoading: false } : media
        )
    );
    
          selectedImage = selectedProfileImageData?._id || selectedImage;
          featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;
        }
    
        const result = { selectedImage, featureCropedImage }
    
        return result;
      }
      useEffect(() => {
        if(selectedProfileImageData !== '') {
          setBlobData();
        }
      }, [selectedProfileImageData]);


    const setBlobData = async () => {

        if (selectedProfileImageData?.url) {
            try {
                const imageData = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/fetchImageData/${userData?._id}`,
                    selectedProfileImageData,
                    { responseType: 'blob' }
                );
                const imageUrl = URL.createObjectURL(imageData?.data);

                if (uploadMediaPopupFor === 'croped-picture') {
                    // setOldProfileZoom(1);
                    setProfileZoom(1);
                    setOldProfileImage(imageUrl);
                    if (image === null) {
                        setImage(imageUrl);
                    }

                    setUploadedProfileImage(imageUrl)
                    // setUploadedProfileImageFile(null)

                    setloadingProfileImg(false);
                    setProfileCoverModal(true);
                    setReplcaeProfileImageZoom(false);
                    setSelectedProfileImageData(selectedProfileImageData);
                }


                // setSelectMediaLoading(false);
                // setShowUploadMediaPopup(false);
                setUploadMediaPopupFor('');
            } catch (error) {
                // setSelectMediaLoading(false);
                console.log({ error: error, message: error.message })
            }
        }
        // setSelectMediaLoading(false);
    }

      

    return (
        <>
            <div className="generalContent">
                <div className="generalRow">
                    <div className="formControltems">
                        <div className="formControlInputs">
                            <div className="formControlFields">
                                <label className="fieldsLabel">First Name</label>
                                <input
                                    type="text"
                                    value={firstName}
                                    className="inputFields"
                                    onChange={(e) => setFirstName(e.target.value)}
                                    autocomplete="off"
                                />
                                {errors.firstName && (
                                    <div className="error-message">
                                        {errors.firstName}
                                    </div>
                                )}
                            </div>
                            <div className="formControlFields">
                                <label className="fieldsLabel">Last Name</label>
                                <input
                                    type="text"
                                    value={lastName}
                                    className="inputFields"
                                    onChange={(e) => setLastName(e.target.value)}
                                    autocomplete="off"
                                />
                                {errors.lastName && (
                                    <div className="error-message">
                                        {errors.lastName}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="formControltems">
                        <div className="formControlInputs">
                            <div className="formControlFields">
                                <label className="fieldsLabel">Email</label>
                                <div className="inputFieldsIcon">
                                    <img src={iconEmail} />
                                    <input
                                        type="text"
                                        className="inputFields"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autocomplete="off"
                                    />
                                    {errors.email && (
                                        <div className="error-message">
                                            {errors.email}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="formControlFields">
                                <label className="fieldsLabel">Phone Number</label>
                                <div className="inputFieldsIcon">
                                    <img src={iconPhone} />
                                    <input
                                        className="inputFields"
                                        type="text"
                                        value={phone}
                                        placeholder="054-3140602"
                                        autocomplete="off"
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        } />
                                    {errors.phone && (
                                        <div className="error-message">
                                            {errors.phone}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formControltems">
                        <div className="formControlInputs formControlSelect">
                            <div className="formControlFields">
                                <label className="fieldsLabel">Country</label>
                                <ReactFlagsSelect
                                    selected={selectedCountry}
                                    searchable
                                    searchPlaceholder="Search countries"
                                    onSelect={(code) => handleCountryChange(code)}
                                    className="select-country"
                                />
                                {errors.countryCode && (
                                    <div className="error-message">
                                        {errors.countryCode}
                                    </div>
                                )}
                            </div>
                            <div className="formControlFields">
                                <label className="fieldsLabel">Role</label>
                                <div className="inputFieldIcon">
                                    <Select
                                        className="userrole-select team-user-role inputfield-select"
                                        onChange={(selectedOption) => setUserRole(selectedOption.value)}
                                        options={options}
                                        styles={colourStyles}
                                        components={{ DropdownIndicator }}
                                        value={options.find((option) => option.value === userRole)}
                                    />
                                    {errors.userRole && (
                                        <div className="error-message">
                                            {errors.userRole}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="generalRow">
                    <label className="fieldsLabel">Profile Picture</label>
                    <div className="profilePictureItems">
                        <div
                            className="avatarBlock"
                            onDragOver={handleDragOver}
                            onDragLeave={handleProfileDragLeave}
                            onDrop={handleDrop}
                        >
                            <div className="newteamprofileImage">

                                <div className="avatarUpload">
                                    {saveProfileImage ? (
                                        <div id="profile-picturebox" className="registerProfile-block" onClick={handleProfileClick} >
                                            {loadingProfileImg === true && profileCoverModal === false ? (
                                                <div id="profileImg-loader" className="registerProfileImg-loader" >
                                                    <Loader />
                                                </div>
                                            ) : (
                                                <>
                                                    <div onClick={handleProfileClick}>
                                                        <img src={image} className="newpage_ProfileImg" alt="Uploaded" onLoad={handleProfileImageLoad} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <div id="profile-picturebox" onClick={() => document.getElementById("upload-img").click()} >
                                            <div className="registerProfile-block" >
                                                {loadingProfileImg === true && profileCoverModal === false ? (
                                                    <div id="profileImg-loader" className="registerProfileImg-loader" >
                                                        <Loader />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <img className="avatarUpload-img" src={avatar} alt="avatar-Images" />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <input type="file" ref={profileFileInputRef} id="upload-img" onChange={handleFileUpload} accept="image/*" hidden />
                                </div>
                                <div
                                    className="newPageRightRow"
                                    onClick={() => {
                                        if (saveProfileImage) {
                                            handleProfileClick();
                                        }
                                        else {
                                            
                                            document.getElementById("upload-img").click()
                                            setUploadMediaPopupFor('croped-picture')
                                        }
                                    }}
                                >
                                    <div className="fileUploadProfile-box">
                                        <p className="newPageImageUploadText">
                                            <span className="newPageImageText">Profile Picture</span> <br />
                                            Min 400x400px, PNG or JPEG <br />
                                            <span className="btnUpload">
                                                {saveProfileImage ? 'Replace' : 'Upload'}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {errors.file && (
                        <div className="error-message">
                            {errors.file}
                        </div>
                    )}
                </div>

                {isUserAdmin() &&
                    (<button
                        onClick={handleFormSubmit}
                        className={`form-btn save-change ${isLoading ? 'btn_loading' : ''}`}
                        type='submit'
                        style={{ pointerEvents: disableSaveButton ? 'none' : 'auto' }}
                    >
                        {isLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                            : "Save Changes"}
                    </button>)}
            </div>


            {profileCoverModal && (
                <>
                    <div className="profile-img-model imagCropModal">
                        <div className="featuredImageModal featurboxModal" ref={profileModalRef} >
                            <div className="modal-content">
                                {uploadedProfileImage === null ? (
                                    <div
                                        className="newPageRightRow"
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleProfileDragLeave}
                                        onDrop={handleDrop}
                                    >
                                        {((saveProfileImage &&
                                            saveProfileImage === oldProfileImage) ||
                                            saveProfileImage !== image) &&
                                            (saveProfileImage ? (
                                                <div id="newpageImageUpload" className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"} >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <div className="crop-container">
                                                                    <ImageEditor
                                                                        type="profileImg"
                                                                        image={image}
                                                                        oldImage={oldProfileImage}
                                                                        setOldImage={setOldProfileImage}
                                                                        setFeatureImage={setImage}
                                                                        setUploadedImage={setUploadedProfileImage}
                                                                        replaceImage={handleFileUpload}
                                                                        zoom={profileZoom}
                                                                        position={profilePosition}
                                                                        setZoom={handleProfileZoom}
                                                                        setPosition={handleProfilePosition}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    id="newpageImageUpload"
                                                    className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"}
                                                    onClick={() => document.getElementById("upload-img").click()}
                                                >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <div className="featureImgMiddleContent">
                                                            <img className="profile-cover" src={avatar} alt="avatar-Images" />
                                                            <p className="uploadimgs-text">
                                                                Drag and drop an image or click to upload
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}

                                        {image &&
                                            saveProfileImage === image &&
                                            (image ? (
                                                <div
                                                    id="newpageImageUpload"
                                                    className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"} >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <div className="crop-container">
                                                                    <ImageEditor
                                                                        type="profileImg"
                                                                        image={image}
                                                                        oldImage={oldProfileImage}
                                                                        setOldImage={setOldProfileImage}
                                                                        setFeatureImage={setImage}
                                                                        setUploadedImage={setUploadedProfileImage}
                                                                        replaceImage={handleFileUpload}
                                                                        zoom={profileZoom}
                                                                        position={profilePosition}
                                                                        setZoom={handleProfileZoom}
                                                                        setPosition={handleProfilePosition}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    id="newpageImageUpload"
                                                    className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"}
                                                    onClick={() => document.getElementById("upload-img").click()}
                                                >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <div className="featureImgMiddleContent">
                                                            <img className="profile-cover" src={avatarProfile} alt="avatarProfile" />
                                                            <p className="uploadimgs-text">
                                                                Drag and drop an image or click to upload
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        <input
                                            type="file"
                                            ref={profileFileInputRef}
                                            id="upload-img"
                                            onChange={handleFileUpload}
                                            accept="image/*"
                                            hidden
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <div className="crop-container">
                                            {image && oldProfileImage && (
                                                <ImageEditor
                                                    image={image}
                                                    oldImage={oldProfileImage}
                                                    setOldImage={setOldProfileImage}
                                                    setFeatureImage={setImage}
                                                    setUploadedImage={setUploadedProfileImage}
                                                    replaceImage={handleFileUpload}
                                                    zoom={profileZoom}
                                                    position={profilePosition}
                                                    setZoom={handleProfileZoom}
                                                    setPosition={handleProfilePosition}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="replaceImageRange">
                                    <div className="replaceImgBtn">
                                        <button className="uploadImage" type="button" onClick={() => handleReplaceProfileImg()} >
                                            <span>Replace Image</span>
                                        </button>
                                    </div>
                                    <div className="imgRange">
                                        <img className="coverImgLeft" src={coverImg} alt="cover-Images" />
                                        <input
                                            id="zoom-range"
                                            className="zoom-range"
                                            type="range"
                                            onMouseDown={handleMouseDown}
                                            onMouseUp={handleMouseUp}
                                            onTouchStart={handleTouchStart}
                                            onTouchEnd={handleTouchEnd}
                                            value={profileZoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aspect={384 / 200}
                                            aria-labelledby="Zoom"
                                            style={{ background: `linear-gradient(to right, #344054 ${((profileZoom - 1) / 2) * 100}%, #B7BCC2 0%)`, }}
                                            onChange={(e) => { handleProfileZoom(e.target.value); }}
                                        />
                                        <img className="coverImgRight" src={coverImg} alt="cover-Images" />
                                    </div>
                                </div>
                                <div className="featuredImageModalbtn">
                                    <button className="cancel" type="button" onClick={() => handleProfileCancel()} > Cancel </button>
                                    <button className="savePicture" type="button" onClick={() => handleSaveProfilePicture()} > Save Picture </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {profileCoverModal && <div className="profileOverlayModal"> </div>}
                </>
            )}
        </>
    );
};
export default General;
