import React, { useEffect, useRef, useState } from 'react'
import axios from "axios";
import ReactFlagsSelect from "react-flags-select";
import Select, { components } from "react-select";

import FeaturedimageModal from "../../components/featuredImageInput";

import iconDropDown from "../../images/icondropdown.svg";
import iconEmail from "../../images/icon-mail.svg"
import iconPhone from "../../images/icon-phone.svg"
import avatar from "../../images/profile-user.svg";
import coverImg from "../../images/picture.svg";
import avatarProfile from "../../images/cover_image.svg"

import "../../components/css/PopupModal.css";
import ImageEditor from '../../components/ImageEditor/ImageEditor';
import Loader from '../../components/Loader/Loader';
import {dataURLtoFile} from "../../utils/functions";

function AddNewTeamMember(props) {
    const { isOpen, onClose, setUserMemberList, setOriginalData, fetchData, currentPage, setCurrentPage } = props;
    const modelRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [countryCode, setSelectedCountry] = useState("");
    const [userRole, setUserRole] = useState("");
    const [password, setPassword] = useState('');
    const [confirmNewPass, setConfirmNewPass] = useState('');
    const [apiError, setAPIError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [readOnlyPhone, setReadOnlyPhone] = useState(true);
    const [readOnlyPassword, setReadOnlyPassword] = useState(true);
    const fileInputRef = useRef(null);
    const profileModalRef = useRef(null);

    const [featureImage, setFeatureImage] = useState(null);
    const [saveFeatureImage, setSaveFeatureImage] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [oldZoom, setOldZoom] = useState(1);
    const [replcaeImageZoom, setReplcaeImageZoom] = useState(false);
    const [newUploadedImage, setNewUploadedImage] = useState(null);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [oldImage, setOldImage] = useState(null);
    const [image, setImage] = useState(null);

    const profileFileInputRef = useRef(null);
    const modalRef = useRef(null);
    const [loadingFeatureImg, setloadingFeatureImg] = useState(false);
    const [coverPictureDragging, setCoverPictureDragging] = useState(false);
    const [profileIsDragging, setProfileIsDragging] = useState(false);
    const [saveProfileImage, setSaveProfileImage] = useState(null);
    const [profileCoverModal, setProfileCoverModal] = useState(false);
    const [loadingProfileImg, setloadingProfileImg] = useState(false);
    const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
    const [replcaeProfileImageZoom, setReplcaeProfileImageZoom] = useState(false);
    const [oldProfileZoom, setOldProfileZoom] = useState(1);
    const [profileZoom, setProfileZoom] = useState(1);
    const [profilePosition, setProfilePosition] = useState({ x: 0.5, y: 0.5 });
    const [newProfileUploadedImage, setNewProfileUploadedImage] = useState(null);
    const [oldProfileImage, setOldProfileImage] = useState(null);
    const [selectedProfileImageData, setSelectedProfileImageData] = useState(null);
    const [uploadMediaPopupFor, setUploadMediaPopupFor] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [profileImageId, setProfileImageId] = useState("");
    const [profileCropedImageId, setProfileCropedImageId] = useState("");
    const [userMedia, setUserMedia] = useState([]);
    const [newUserData, setNewUserData] = useState({})

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        countryCode: "",
        userRole: "",
        password: "",

    });

    function handleDragOver(e) {
        e.preventDefault();
        if (!profileIsDragging) {
            setProfileIsDragging(true);
        }
    }
    const handleProfileDragLeave = (e) => {
        e.preventDefault();
        setProfileIsDragging(false);
    };

    const handleProfileClick = () => {
        setProfileCoverModal(!profileCoverModal);
    };

    const handleProfileImageLoad = () => {
        const img = new Image();
        img.src = image;
        img.onload = () => { };
    };

    const handleMouseDown = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.add("slider-cursor");
    };

    const handleMouseUp = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.remove("slider-cursor");
    };

    const handleTouchStart = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.add("slider-cursor");
    };

    const handleTouchEnd = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.remove("slider-cursor");
    };

    
    const handleProfileZoom = (profileZoom) => {
        setProfileZoom(profileZoom);
    };
    const handleProfilePosition = (newProfilePosition) => {
        setProfilePosition(newProfilePosition);
    };
    const handleReplaceProfileImg = () => {
        document.getElementById("upload-img").click();
        setReplcaeProfileImageZoom(true);
        setUploadMediaPopupFor('profile-picture')
    };
    const handleSaveProfilePicture = async () => {
        let click = document.getElementById("clickFunction");
        click.click();
        setImage(image);
        setNewProfileUploadedImage(oldProfileImage);
        setSaveProfileImage(image);
        setProfileCoverModal(false);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-uploaded");
        setOldProfileZoom(profileZoom);
        setSelectedProfileImageData(imageFile)
    };
    const handleProfileCancel = () => {
        if (saveProfileImage !== null) {
            var divElement = document.getElementById("profile-picturebox");
            divElement.classList.add("profile-uploaded");
        } else {
            var divElement = document.getElementById("profile-picturebox");
            divElement.classList.remove("profile-uploaded");
        }
        setOldProfileImage(newProfileUploadedImage);
        setProfileCoverModal(false);
        setProfileZoom(oldProfileZoom);

        if (profileFileInputRef.current) {
            profileFileInputRef.current.value = null;
            setUploadedProfileImage(null);
            setImageFile(null);
        }
    };

    function handleDrop(e) {
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.remove("profile-uploaded");
        setloadingProfileImg(true);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-border");
        var TempReplcaeProfileImageZoom = replcaeProfileImageZoom;

        if (image) {
            setReplcaeProfileImageZoom(true);

            TempReplcaeProfileImageZoom = true;
        }

        if (TempReplcaeProfileImageZoom === true) {
            setOldProfileZoom(1);
            setProfileZoom(1);
        } else {
            setProfileZoom(oldProfileZoom);
        }

        if (TempReplcaeProfileImageZoom === true) {
            setOldProfileZoom(1);
            setProfileZoom(1);
        } else {
            setProfileZoom(oldProfileZoom);
        }
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            TempReplcaeProfileImageZoom !== true && setImage(reader.result);
            setOldProfileImage(reader.result);
            setImageFile(file);
        };
        if (file) {
            reader.readAsDataURL(file);
            setUploadedProfileImage(null);
            setTimeout(() => {
                reader.readAsDataURL(file);
                setloadingProfileImg(false);
                setUploadedProfileImage(file);
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.add("profile-uploaded");
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.remove("profile-border");
                setProfileCoverModal(true);
                setReplcaeProfileImageZoom(false);
                setImageFile(file);
            }, 4000);
        } else {
            setloadingProfileImg(false);
        }
        setProfileIsDragging(false);
    }

    function handleFileUpload(e) {
        setloadingProfileImg(true);
        setDisableSaveButton(true);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-border");
        if (replcaeProfileImageZoom === true) {
            setOldProfileZoom(1);
            setProfileZoom(1);
        } else {
            setProfileZoom(oldProfileZoom);
        }
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            replcaeProfileImageZoom !== true && setImage(reader.result);
            setOldProfileImage(reader.result);
            setImageFile(file);
        };
        if (file) {
            reader.readAsDataURL(file);
            setUploadedProfileImage(null);
            setTimeout(() => {
                reader.readAsDataURL(file);
                setloadingProfileImg(false);
                setDisableSaveButton(false);
                setUploadedProfileImage(file);
                setImageFile(file);
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.add("profile-uploaded");
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.remove("profile-border");
                setProfileCoverModal(true);
                setReplcaeProfileImageZoom(false);
            }, 4000);
        } else {
            setloadingProfileImg(false);
            setDisableSaveButton(false);
        }
    }
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (modelRef.current && !modelRef.current.contains(e.target)) {
                onClose();

                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setSelectedCountry('');
                setUserRole('');
                setPassword('');
                setConfirmNewPass('');

                setErrors({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    countryCode: "",
                    userRole: "",
                    password: "",
                });
                setErrorMessage('');
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isOpen]);

    const onPasswordChange = e => {
        setPassword(e.target.value);
    };

    const onConfirmPasswordChange = e => {
        setConfirmNewPass(e.target.value);
    };

    const validateInputs = () => {
        let valid = true;
        const newErrors = {};
        if (firstName.trim() === "") {
            newErrors.firstName = "First Name is required";
            valid = false;
        }

        if (lastName.trim() === "") {
            newErrors.lastName = "Last Name is required";
            valid = false;
        }

        if (email.trim() === "") {
            newErrors.email = "Email address is required";
            valid = false;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                newErrors.email = "Invalid email format";
                valid = false;
            }
        }

        if (phone.trim() === "") {
            newErrors.phone = "Phone number is required";
            valid = false;
        } else {
            const phoneRegex = /^[0-9]+$/;
            if (!phoneRegex.test(phone)) {
                newErrors.phone = "Phone Number must be numeric";
                valid = false;
            }
        }

        if (countryCode.trim() === "") {
            newErrors.countryCode = "Country is required";
            valid = false;
        }

        if (userRole.length == 0) {
            newErrors.userRole = "User role is required";
            valid = false;
        }
        if (password.length == 0) {
            newErrors.password = "Password is required";
            valid = false;
        }
        if (confirmNewPass.length == 0) {
            newErrors.confirmNewPass = "Confirm Password is required";
            valid = false;
        }

        if (password.length > 0 && confirmNewPass.length > 0) {
            if (password !== confirmNewPass) {
                newErrors.confirmNewPass = "Password do not match";
                valid = false;
            } else {
                if (!validatePassword(password)) {
                    setAPIError(true);
                    setErrorMessage("Password must contain at least 8 characters including uppercase and lowercase letters, numbers, and special characters.")
                    valid = false;
                } else {
                    setAPIError(false);
                    setErrorMessage('');
                }
            }
        }

        setErrors(newErrors);
        return valid;
    };

    const validatePassword = (password) => {
        // Regular expression to enforce the password requirements
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const handleAddTeamMember = async () => {
        if (validateInputs()) {
            setIsLoading(true);

            try {
                const userData = {
                    _id: '',
                    displayName: `${firstName} ${lastName}`,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    password: password,
                    country: countryCode,
                    role: userRole.value,
                    phone:phone,
                    createdAt: '',  
                }

                let response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/addTeam`,
                    userData
                );
                setNewUserData(response?.data?.result)
                userData._id = response?.data?.result?._id;
                userData.createdAt = response?.data?.result?.createdAt;

                if (response.status === 200) {
                    let profileCropedImage = profileCropedImageId;
                    let profileImage = profileImageId;
                    const featureImageData = await getFeatureImageData(response?.data?.result?._id)
                    
                    profileCropedImage = featureImageData?.featureCropedImage || profileCropedImage;
                    profileImage = featureImageData?.selectedImage || profileImage;
                    const sendProfile = {
                        displayName: `${firstName} ${lastName}`,
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        password: password,
                        country: countryCode,
                        role: userRole?.value,
                        phone: phone,
                        profileMedia: {
                            selectedImage: profileImage,
                            cropedImage: profileCropedImage,
                            profilePosition: profilePosition,
                            profileZoom: profileZoom
                        },
                    }
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/admin/updateuser/${userData._id}`,
                        sendProfile
                    );

                    if( currentPage != 1 ) {
                        setCurrentPage(1)
                    } else {
                        fetchData()
                    }
                }


                // Append the new user to the userMemberList
                setUserMemberList((prevList) => [...prevList, userData]);

                // Update the originalData if needed
                setOriginalData((prevData) => [...prevData, userData]);

                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setSelectedCountry('');
                setUserRole('');

                setErrors({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    countryCode: "",
                    userRole: "",
                    password: "",
                });
                setAPIError(false);
                setErrorMessage('');

                setIsLoading(false);
                onClose();

            } catch (error) {
                let errorMessage = "An error occurred.";

                if (error.response) {
                    if (error.response.status === 409) {
                        errorMessage = "User already exists.";
                    } else {
                        errorMessage = "Server error. Please try again later.";
                    }
                }
                setAPIError(true);
                setErrorMessage(errorMessage);
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    const getFeatureImageData = async (id) => {
        let uploadedMediaId = 1;
        let selectedImage = profileImageId;
        let featureCropedImage = profileCropedImageId;
        const uploadMedia = {
            _id: uploadedMediaId,
            name: imageFile?.name,
            description: '',
            alt: '',
            type: imageFile?.type,
            size: imageFile?.size,
            object_id: "",
            key: "",
            url: "",
            createdAt: "",
            isLoading: true
        }
        if (imageFile !== null) {
          const featureImageFile = await dataURLtoFile(imageFile, "croped-picture.jpg");
            const imageFormData = new FormData();
            imageFormData.append('files', featureImageFile); 
            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/uploadMedia/${id}`,
                    imageFormData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
                
                selectedImage = res.data.result[0]?._id;
        
                const featureImageFile = await dataURLtoFile(image, "profile-picture.jpg");
                const formData = new FormData();
                formData.append('files', featureImageFile);
                formData.append('id', id);
                const uploadedMedia = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/uploadMedia/${id}`,
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
                setUserMedia((prevUserMedia) =>
                    prevUserMedia.map((media) =>
                        media._id === uploadMedia._id ? { ...media, ...uploadedMedia.data.result[0], isLoading: false } : media
                    )
                );
                featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;
        
            } catch (error) {
                console.log(error);
            }
          // profileImageId !== '' || selectedProfileImageData?._id) && oldImage !== saveProfileImage
        } else if ((profileImageId !== "" || selectedProfileImageData?._id) || oldProfileImage !== saveProfileImage) {
            const featureImageFile = await dataURLtoFile(image, "profile-picture.jpg");
            
            const formData = new FormData();
            formData.append('files', featureImageFile);
            formData.append('id', id);
            
            const uploadedMedia = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/uploadMedia/${id}`,
                formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        );
        setUserMedia((prevUserMedia) =>
            prevUserMedia.map((media) =>
                media._id === uploadMedia._id ? { ...media, ...uploadedMedia.data.result[0], isLoading: false } : media
            )
        );
        
            selectedImage = selectedProfileImageData?._id || selectedImage;
            featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;
            }
        
            const result = { selectedImage, featureCropedImage }
        
            return result;
    }

    useEffect(() => {
        if(selectedProfileImageData !== '') {
          setBlobData();
        }
    }, [selectedProfileImageData]);

    const setBlobData = async () => {
        // setSelectMediaLoading(true);

        if (selectedProfileImageData?.url) {
            try {
                const imageData = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/fetchImageData/${newUserData?._id}`,
                    selectedProfileImageData,
                    { responseType: 'blob' }
                );
                const imageUrl = URL.createObjectURL(imageData?.data);

                if (uploadMediaPopupFor === 'profile-picture') {
                    // setOldProfileZoom(1);
                    setProfileZoom(1);
                    setOldProfileImage(imageUrl);
                    if (image === null) {
                        setImage(imageUrl);
                    }

                    setUploadedProfileImage(imageUrl)

                    setloadingProfileImg(false);
                    setProfileCoverModal(true);
                    setReplcaeProfileImageZoom(false);
                    setSelectedProfileImageData(selectedProfileImageData);
                }
                setUploadMediaPopupFor('');
            } catch (error) {
                console.log({ error: error, message: error.message })
            }
        }
    }
    useEffect(() => {
        data();
    }, []);
    
    const data = async () => {
        try {
    
            if(newUserData?.userMediaData?.profileMedia?.cropedImage) {
              const imageData = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/fetchImageData/${newUserData?._id}`,
                newUserData?.userMediaData?.profileMedia?.cropedImage,
                { responseType: 'blob' }
              );
    
              const imageUrl = URL.createObjectURL(imageData.data);
    
              setImage(imageUrl);
              setOldProfileImage(imageUrl);
              setProfileCropedImageId(newUserData?.userMediaData?.profileMedia?.cropedImage)
            }
    
            if(newUserData?.userMediaData?.profileMedia?.selectedImage) {
              const imageData = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/fetchImageData/${newUserData?._id}`,
                newUserData?.userMediaData?.profileMedia?.selectedImage,
                { responseType: 'blob' }
              );
    
              const imageUrl = URL.createObjectURL(imageData.data);
              setOldProfileImage(imageUrl);
              setNewProfileUploadedImage(imageUrl);
              setSaveProfileImage(imageUrl);
              setProfileImageId(newUserData?.userMediaData?.profileMedia?.selectedImage?._id);
            }
    
            if(newUserData?.userMediaData?.profileMedia?.profileZoom) {
              setProfileZoom(newUserData?.userMediaData?.profileMedia?.profileZoom)
              setOldProfileZoom(newUserData?.userMediaData?.profileMedia?.profileZoom)
            }
    
            if(newUserData?.userMediaData?.profileMedia?.profilePosition) {
              setProfilePosition(newUserData?.userMediaData?.profileMedia?.profilePosition)
            }
    
        } catch (error) {
          console.log(error, "error");
        }
    };

    const handleCoseBtn = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setSelectedCountry('');
        setUserRole('');

        setErrors({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            countryCode: "",
            userRole: "",
            password: "",
        });
        setAPIError(false);
        setErrorMessage('');

        setIsLoading(false);
        onClose();
    }

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <img src={iconDropDown} alt="select-down" />
            </components.DropdownIndicator>
        );
    };
    const colourStyles = {
        option: (styles, { isFocused, isSelected, isDisabled }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? "#eaeaea !important"
                    : isFocused
                        ? "#f9f9f9 !important"
                        : "transparent",
                fontSize: "14px",
                cursor: "pointer",
            };
        },
        input: (provided, state) => ({
            ...provided,
            height: "40px",
            padding: 0,
            margin: 0,
            borderRadius: "8px",
            fontSize: "14px",
            cursor: "pointer",
        }),
        noOptionsMessage: (provided, state) => ({
            ...provided,
            fontSize: "14px",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            color: "#101828",
        }),
        control: (provided, state) => ({
            ...provided,
            border: "none",
            borderRadius: "8px",
            width: "100%",
        }),
        control: (base) => ({
            ...base,
            border: 0,
            boxShadow: "none !important",
            borderRadius: "8px",
            marginRTop: "2px",
        }),
        container: (provided, state) => ({
            ...provided,
            paddingLeft: "0px",
        }),
        placeholder: (styles, { isFocused }) => {
            return {
                ...styles,
                marginBottom: isFocused ? "0px" : "0px",
                fontSize: "14px",
                marginLeft: "0",
                color: "#667085",
            };
        },
        indicatorSeparator: (state) => ({
            display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            borderRadius: "8px",
            outline: "none",
            marginRight: "5px",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            paddingLeft: "15px",
            paddingRight: "15px",
        }),
    };
    const userRoleOptions = [
        { value: "admin", label: "Admin" },
        { value: "support", label: "Support" },
        { value: "assistant", label: "Assistant" },
    ];


    return (
        <>
            {isOpen && (
                <>
                    <div className="popupModalOverlay"></div>
                    <div className="popupModal singleDeletePage addTeamMember" ref={modelRef}>
                        <h3 className="popupModalTitle">New Team Member</h3>
                        <div className="popupModalRow">
                            <div className="formControltems">
                                <div className="formControlInputs">
                                    <div className="formControlFields">
                                        <label className="fieldsLabel">First Name</label>
                                        <input
                                            type="text"
                                            value={firstName}
                                            className="inputFields"
                                            placeholder="Enter first name"
                                            onChange={(e) => setFirstName(e.target.value)}
                                            autocomplete="off"
                                        />
                                        {errors.firstName && (
                                            <div className="error-message">
                                                {errors.firstName}
                                            </div>
                                        )}
                                    </div>
                                    <div className="formControlFields">
                                        <label className="fieldsLabel">Last Name</label>
                                        <input
                                            type="text"
                                            value={lastName}
                                            className="inputFields"
                                            placeholder="Enter lasst name"
                                            onChange={(e) => setLastName(e.target.value)}
                                            autocomplete="off"
                                        />
                                        {errors.lastName && (
                                            <div className="error-message">
                                                {errors.lastName}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="formControltems">
                                <div className="formControlInputs formControlSelect">
                                    <div className="formControlFields">
                                        <label className="fieldsLabel">Role</label>
                                        <Select
                                            defaultValue={userRole}
                                            className="selectField"
                                            onChange={setUserRole}
                                            options={userRoleOptions}
                                            styles={colourStyles}
                                            placeholder='Select a role'
                                            components={{ DropdownIndicator }}
                                        />
                                        {errors.userRole && (
                                            <div className="error-message">
                                                {errors.userRole}
                                            </div>
                                        )}
                                    </div>
                                    <div className="formControlFields">
                                        <label className="fieldsLabel">Country</label>
                                        <ReactFlagsSelect
                                            selected={countryCode}
                                            className="select-country"
                                            searchable
                                            searchPlaceholder="Search countries"
                                            onSelect={(code) => setSelectedCountry(code)}
                                        />
                                        {errors.countryCode && (
                                            <div className="error-message">
                                                {errors.countryCode}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="formControltems">
                                <div className="formControlInputs">
                                    <div className="formControlFields">
                                        <label className="fieldsLabel">Email</label>
                                        <div className="inputFieldsIcon">
                                            <img src={iconEmail} />
                                            <input
                                                type="text"
                                                className="inputFields"
                                                value={email}
                                                placeholder="Enter email address"
                                                name="teamName"
                                                autoComplete="off"
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        {errors.email && (
                                            <div className="error-message">
                                                {errors.email}
                                            </div>
                                        )}
                                    </div>
                                    <div className="formControlFields">
                                        <label className="fieldsLabel">Phone</label>
                                        <div className="inputFieldsIcon">
                                            <img src={iconPhone} />
                                            <input
                                                type="text"
                                                className="inputFields"
                                                value={phone}
                                                placeholder="Enter phone number"
                                                name="teamPhone"
                                                autoComplete="off"
                                                readOnly={readOnlyPhone} 
                                                onFocus={ () => setReadOnlyPhone(false) } 
                                                onBlur={ () => setReadOnlyPhone(true) }
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                        {errors.phone && (
                                            <div className="error-message">
                                                {errors.phone}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="formControltems">
                                <div className="formControlInputs">
                                    <div className="formControlFields">
                                        <label className="fieldsLabel">Password</label>
                                        <input
                                            type="password"
                                            value={password}
                                            className="inputFields"
                                            placeholder="Enter password"
                                            name="teamPassword"
                                            autoComplete="off"
                                            readOnly={readOnlyPassword} 
                                            onFocus={ () => setReadOnlyPassword(false) } 
                                            onBlur={ () => setReadOnlyPassword(true) }
                                            onChange={(e) => onPasswordChange(e)}
                                        />
                                        {errors.password && (
                                            <div className="error-message">
                                                {errors.password}
                                            </div>
                                        )}
                                    </div>

                                    <div className="formControlFields">
                                        <label className="fieldsLabel">Confirm Password</label>
                                        <input
                                            type="password"
                                            value={confirmNewPass}
                                            className="inputFields"
                                            placeholder="Enter confirm password"
                                            onChange={(e) => onConfirmPasswordChange(e)}
                                        />
                                        {errors.confirmNewPass && (
                                            <div className="error-message">
                                                {errors.confirmNewPass}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="formControltems">
                    <label className="fieldsLabel">Profile Picture</label>
                    <div className="profilePictureItems">
                        <div
                            className="avatarBlock"
                            onDragOver={handleDragOver}
                            onDragLeave={handleProfileDragLeave}
                            onDrop={handleDrop}
                        >
                            <div className="avatarUpload">
                                {saveProfileImage ? (
                                    <div id="profile-picturebox" className="registerProfile-block" onClick={handleProfileClick} >
                                        {loadingProfileImg === true && profileCoverModal === false ? (
                                            <div id="profileImg-loader" className="registerProfileImg-loader" >
                                                <Loader />
                                            </div>
                                        ) : (
                                            <>
                                                <div onClick={handleProfileClick}>
                                                    <img
                                                        src={image}
                                                        className="newpage_ProfileImg"
                                                        alt="Uploaded"
                                                        onLoad={handleProfileImageLoad}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div id="profile-picturebox" className="registerProfile-block" onClick={() => document.getElementById("upload-img").click()} >
                                        {loadingProfileImg === true && profileCoverModal === false ? (
                                            <div id="profileImg-loader" className="registerProfileImg-loader" >
                                                <Loader />
                                            </div>
                                        ) : (
                                            <>
                                                <img className="avatarUpload-img" src={avatar} alt="avatar-Images" />
                                            </>
                                        )}
                                    </div>
                                )}
                                <input type="file" ref={profileFileInputRef} id="upload-img" onChange={handleFileUpload} accept="image/*" hidden />
                            </div>
                            <div className="newPageRightRow"
                                onClick={() => {
                                    if (saveProfileImage) {
                                        handleProfileClick();
                                    }
                                    else {
                                        document.getElementById("upload-img").click()
                                        setUploadMediaPopupFor('profile-picture')
                                    }
                                }}
                            >
                                <div className="fileUploadProfile-box selectProfile">
                                    <p className="newPageImageUploadText profileImageBox">
                                        <span className="newPageImageText">Profile Picture</span> <br />
                                        Min 400x400px, PNG or JPEG <br />
                                        <span className="btnUpload">
                                            {saveProfileImage ? 'Replace' : 'Upload'}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {errors.file && (
                        <div className="error-message">
                            {errors.file}
                        </div>
                    )}
                </div>
                            {profileCoverModal && (
                <>
                    <div className="profile-img-model imagCropModal">
                        <div className="featuredImageModal featurboxModal" ref={profileModalRef} >
                            <div className="modal-content">
                                {uploadedProfileImage === null ? (
                                    <div className="newPageRightRow" onDragOver={handleDragOver} onDragLeave={handleProfileDragLeave} onDrop={handleDrop} >
                                        {((saveProfileImage && saveProfileImage === oldProfileImage) || saveProfileImage !== image) && (saveProfileImage ? (
                                                <div id="newpageImageUpload" className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" } >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <div className="crop-container">
                                                                    <ImageEditor
                                                                        type="profileImg"
                                                                        image={image}
                                                                        oldImage={oldProfileImage}
                                                                        setOldImage={setOldProfileImage}
                                                                        setFeatureImage={setImage}
                                                                        setUploadedImage={setUploadedProfileImage}
                                                                        replaceImage={handleFileUpload}
                                                                        zoom={profileZoom}
                                                                        position={profilePosition}
                                                                        setZoom={handleProfileZoom}
                                                                        setPosition={handleProfilePosition}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div id="newpageImageUpload" className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" } onClick={() => document.getElementById("upload-img").click() } >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <div className="featureImgMiddleContent">
                                                            <img className="profile-cover" src={avatar} alt="avatar-Images" />
                                                            <p className="uploadimgs-text">
                                                                Drag and drop an image or click to upload
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}

                                        {image &&
                                            saveProfileImage === image &&
                                            (image ? (
                                                <div id="newpageImageUpload" className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" } >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <div className="crop-container">
                                                                    <ImageEditor
                                                                        type="profileImg"
                                                                        image={image}
                                                                        oldImage={oldProfileImage}
                                                                        setOldImage={setOldProfileImage}
                                                                        setFeatureImage={setImage}
                                                                        setUploadedImage={setUploadedProfileImage}
                                                                        replaceImage={handleFileUpload}
                                                                        zoom={profileZoom}
                                                                        position={profilePosition}
                                                                        setZoom={handleProfileZoom}
                                                                        setPosition={handleProfilePosition}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div id="newpageImageUpload" className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" } onClick={() => document.getElementById("upload-img").click() } >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <div className="featureImgMiddleContent">
                                                            <img className="profile-cover" src={avatarProfile} alt="avatarProfile" />
                                                            <p className="uploadimgs-text">
                                                                Drag and drop an image or click to upload
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        <input
                                            type="file"
                                            ref={profileFileInputRef}
                                            id="upload-img"
                                            onChange={handleFileUpload}
                                            accept="image/*"
                                            hidden
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <div className="crop-container">
                                            {image && oldProfileImage && (
                                                <ImageEditor
                                                    image={image}
                                                    oldImage={oldProfileImage}
                                                    setOldImage={setOldProfileImage}
                                                    setFeatureImage={setImage}
                                                    setUploadedImage={setUploadedProfileImage}
                                                    replaceImage={handleFileUpload}
                                                    zoom={profileZoom}
                                                    position={profilePosition}
                                                    setZoom={handleProfileZoom}
                                                    setPosition={handleProfilePosition}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="replaceImageRange">
                                    <div className="replaceImgBtn">
                                        <button className="uploadImage" type="button" onClick={() => handleReplaceProfileImg()} >
                                            <span>Replace Image</span>
                                        </button>
                                    </div>
                                    <div className="imgRange">
                                        <img className="coverImgLeft" src={coverImg} alt="cover-Images" />
                                        <input
                                            id="zoom-range"
                                            className="zoom-range"
                                            type="range"
                                            onMouseDown={handleMouseDown}
                                            onMouseUp={handleMouseUp}
                                            onTouchStart={handleTouchStart}
                                            onTouchEnd={handleTouchEnd}
                                            value={profileZoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aspect={384 / 200}
                                            aria-labelledby="Zoom"
                                            style={{ background: `linear-gradient(to right, #344054 ${((profileZoom - 1) / 2) * 100 }%, #B7BCC2 0%)`, }}
                                            onChange={(e) => { handleProfileZoom(e.target.value); }}
                                        />
                                        <img className="coverImgRight" src={coverImg} alt="cover-Images" />
                                    </div>
                                </div>
                                <div className="featuredImageModalbtn">
                                    <button className="cancel" type="button" onClick={() => handleProfileCancel()} > Cancel </button>
                                    <button className="savePicture" type="button" onClick={() => handleSaveProfilePicture()} > Save Picture </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {profileCoverModal && <div className="profileOverlayModal"> </div>}
                </>
            )}
                        </div>

                        {
                            apiError &&
                            <div className="error-message">
                                {errorMessage}
                            </div>
                        }

                        <div className="popupModalBtn">
                            <button className="cancel" type="button" onClick={handleCoseBtn}>
                                Cancel
                            </button>
                            <button
                                onClick={handleAddTeamMember}
                                className="savePicture" type="button">
                                {isLoading ? (
                                    <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                    </div>
                                ) : (
                                    "Add Team Member"
                                )}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
export default AddNewTeamMember;
