import React, { useState, useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import axios from "axios";

import { PageTabs, PageNavigation } from './PageTabs';
import General from "./General";
import Subscription from "./Subscription";
import Profile from "./Profile";
import Pages from "./Pages";
import Password from "./Password";
import SocialNetwork from "./SocialNetwork";
import AssistantHistory from "./AssistantHistory";
import SupportHistory from "./SupportHistory";
import Publications from "./Publications";
import MenuManagement from "./MenuManagement";
import Categories from "./Categories";
import MediaGallery from "./MediaGallery";
import Newsletter from "./Newsletter";
import EditPages from "./EditPages";
import EditPublication from "./Publications/EditPublication";
import EditNewsletter from "./EditNewsletter";
import { Subscriber } from "./Subscriber";

const UsersDetail = () => {
    const [activePage, setActivePage] = useState("General");
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [isUpdateUser,setUpdateUser]=useState(false)
    const [hideUserInfo, setHideUserInfo] = useState(false);

    const { id } = useParams();
    const ProfileTabs = [
        { name: 'General', href: `/users/${id}/general` },
        { name: 'Password', href: `/users/${id}/password` },
        { name: 'Subscription', href: `/users/${id}/subscription` },
        { name: 'Navigation menu', href: `/users/${id}/menu` },
        { name: 'Social networks', href: `/users/${id}/social` },
        { name: 'Pages', href: `/users/${id}/pages` },
        { name: 'Publications', href: `/users/${id}/publications` },
        { name: 'Newsletters', href: `/users/${id}/newsletters` },
        { name: 'Categories', href: `/users/${id}/categories` },
        { name: 'Media Library', href: `/users/${id}/media-gallery` },
        { name: 'Subscriber', href: `/users/${id}/subscriber` },
        { name: 'Assistant History', href: `/users/${id}/assistant-history` },
        { name: 'Support History', href: `/users/${id}/support-history` },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/userdetail/${id}/`);
                setUserData(response.data.result);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData(); // Call the async function immediately
    }, [id,isUpdateUser]);

    useEffect(() => {
        const hideInfoFor = ['Pages', 'Publications', 'Newsletters'];

        if (!hideInfoFor.includes(activePage)) {
            setHideUserInfo(false);
        }

    }, [activePage])

    const handleFormSubmit = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 4000);
    };

    useEffect(() => {
        fetchBillingData();
    }, []);

    const [billingDetails, setBillingDetails] = useState({});
    const [showUpgradeModel, setShowUpgradeModel] = useState(false);
    const fetchBillingData = async () => {
        try {
            const billingDetailsResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/getBillingDetails/${id}`,
            )
            setBillingDetails(billingDetailsResponse.data.result);
            setShowUpgradeModel(false);
        } catch (error) {
            console.log(error, "error");
        }
    }

    return (
        <>
            {!hideUserInfo && <Profile userData={userData} />}
            {!hideUserInfo && <PageTabs tabs={ProfileTabs} activePage={activePage} setActivePage={setActivePage} />}
            <PageNavigation tabs={ProfileTabs} activePage={activePage} setActivePage={setActivePage} />

            <div className="UserDetails">
                <Routes basename="/users">
                    <Route path="/" element={<General setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} userData={userData} setUserData={setUserData} setUpdateUser={setUpdateUser}/>} ></Route>
                    <Route path="/general" element={<General setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} userData={userData} setUserData={setUserData} setUpdateUser={setUpdateUser}/>} ></Route>
                    <Route path="/password" element={<Password setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} />} ></Route>
                    <Route path="/subscription" element={<Subscription setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} userData={userData} />} ></Route>
                    <Route path="/menu" element={<MenuManagement setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} navigation={userData.navigation || []} />} ></Route>
                    <Route path="/social" element={<SocialNetwork setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} socialNetwork={userData.social_nework || []} />} ></Route>
                    <Route path="/pages/*" element={<Pages setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} setHideUserInfo={setHideUserInfo} profileSlug={userData.profileSlug} />} ></Route>
                    <Route path="/pages/page/:pageid?" element={<EditPages setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} profileSlug={userData.profileSlug} setHideUserInfo={setHideUserInfo} userData={userData} />} ></Route>
                    <Route path="/publications/*" element={<Publications setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} setHideUserInfo={setHideUserInfo} profileSlug={userData.profileSlug} />} ></Route>
                    <Route path="/publications/publication/:publicationid?" element={<EditPublication setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} setHideUserInfo={setHideUserInfo} userData={userData} billingDetails={billingDetails} billingData={fetchBillingData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel}/>}></Route>
                    <Route path="/newsletters/*" element={<Newsletter setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} setHideUserInfo={setHideUserInfo} />} ></Route>
                    <Route path="/newsletters/newsletter/:newsletterid?" element={<EditNewsletter setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} setHideUserInfo={setHideUserInfo} userData={userData} billingDetails={billingDetails} billingData={fetchBillingData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel}/>} ></Route>
                    <Route path="/categories" element={<Categories setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} />} ></Route>
                    <Route path="/media-gallery" element={<MediaGallery setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} billingDetails={billingDetails}/>} ></Route>
                    <Route path="/subscriber" element={<Subscriber setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} profileSlug={userData.profileSlug} billingDetails={billingDetails}/>} ></Route>
                    <Route path="/assistant-history" element={<AssistantHistory setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} />} ></Route>
                    <Route path="/support-history" element={<SupportHistory setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} />} ></Route>
                </Routes >
            </div >
        </>
    )
};

export default UsersDetail;