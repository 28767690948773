import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";

import SubscriptionList from "./SubscriptionList";
import {formattedDate} from "../../utils/functions";

const Subscription = ({setActivePage, userData}) => {
    setActivePage("Subscription");
    const { id } = useParams();

    const [planDetails, setPlanDetails] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const billingDetailsResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/getBillingDetails/${id}`,
            );

            setPlanDetails(billingDetailsResponse.data.result.subscriptions);
        } catch (error) {
            console.log(error, "error");
        }
    }  

    return (
        <div className="subscription-data">
            <div className="subscription-item">
                <div className="subscription-detail">
                    <h2 className="table-heading">Subscription History</h2>
                    <div className="subscription-content">
                        <div className="subscription-activity">
                            <div className="subscription-activity-title">
                                <h4>Plans Summary</h4>
                            </div>
                            <div className="subscription-activity-detail">
                                <h5>Current Plan</h5>
                                <p>
                                    {planDetails.planName}
                                    <br />
                                    {planDetails.price > 0 && (
                                        <span>
                                            ${planDetails.price} / {planDetails.interval} billed {planDetails.interval === 'month' ? 'monthly' : 'yearly'}
                                        </span>
                                    )}
                                </p>
                            </div>
                            {planDetails.nextPaymentAt && 
                                (
                                <div className="subscription-activity-detail">
                                    <h5>Next Billing Date</h5>
                                    <p>
                                        {formattedDate(planDetails.nextPaymentAt)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <SubscriptionList />
        </div>
    );
};

export default Subscription;
