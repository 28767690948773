import React, { useState } from 'react';
import axios from 'axios';

function ReverseTeamMember(props) {
    const { item, isOpen, onClose, selectedItems, setOpen, fetchData, originalData, refrence} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    const [userPassword, setUserPassword] = useState('');
    const [validatePassword, setValidatePassword] = useState(false);
    const [error, setError] = useState(false);
    const [errorMassage, setErrorMassage] = useState('');

    const reverseAction = async (itemIDs) => {
        try {
            setError(false);
            setValidatePassword(false);

            if (userPassword.length > 0) {
                setIsLoading(true);

                const updatePromises = itemIDs?.map(async (itemID) => {
                    const reverseAPI = `${process.env.REACT_APP_API_URL}/admin/reversehistory/${itemID}`;

                    await axios.post(
                        reverseAPI,
                        { 
                            member_id: JSON.parse(localStorage.getItem("userData"))?._id,
                            password: userPassword  
                        }
                    );
                });

                await Promise.all(updatePromises);
                await fetchData();
                document.body.classList.remove('hidden-message');
            } else {
                setValidatePassword(true);
            }
            setOpen(false);
            setIsLoading(false);
            setUserPassword("");
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
            } else {
                setError(true);
                setErrorMassage(error.response.data.error);
            }
        }
    };

    // Function to get display name from _id
    const getDisplayNameById = (id) => {
        const data = originalData.find(user => user._id === id);
        return data ? `${data.title}: ${data.subTitle}` : "";
    };

    return (
        <>
            {isOpen && (
                <>
                    <div className="popupModalOverlay"></div>
                    <div className='popupModal DeletePage' ref={refrence}>
                        <h3 className='popupModalTitle'>Reverse {item && item.length !== 0 ? 'Action' : 'Actions'}</h3>
                        {item && item.length !== 0 ? (
                            <p className='popupModalContent'>Are you sure you want to reverse <span>{item.title}: {item.subTitle}</span> action? This action can’t be undone.</p>
                        ) : (
                            <p className='popupModalContent'>Are you sure you want to reverse {selectedItems && selectedItems.length > 1 ? 'these actions' : <span>{getDisplayNameById(selectedItems[0])}</span>} ? This action can’t be undone.</p>
                        )}
                        <div className='popupFields'>
                            <label className='fieldsLabel'>Enter Password</label>
                            <input type="password" name='password' className='inputFields' placeholder='Type Password' autoComplete='off' onChange={(e) => setUserPassword(e.target.value)} readOnly={readOnly} onFocus={() => setReadOnly(false)} onBlur={() => setReadOnly(true)} />
                            {validatePassword && (
                                <p className="validateError">The field is not filled</p>
                            )}
                            {error && (
                                <p className="validateError">{errorMassage}</p>
                            )}
                        </div>
                        <div className="popupModalBtn">
                            <button className="cancel" type="button" onClick={onClose}>Cancel</button>
                            <button className="savePicture" type="button" onClick={() => reverseAction(item && item.length !== 0 ? [item._id] : selectedItems)}>
                                {isLoading ?
                                    <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                    </div>
                                    : "Reverse"}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default ReverseTeamMember;
