import CustomLinkIcon from "../../images/social/Custom Link.svg";
import AirbnbIcon from "../../images/social/Airbnb.svg";
import AmazonIcon from "../../images/social/Amazon.svg";
import AppleIcon from "../../images/social/Apple.svg";
import ApplePodcastIcon from "../../images/social/Apple Podcast.svg";
import BehanceIcon from "../../images/social/Behance.svg";
import DeviantArtIcon from "../../images/social/DeviantArt.svg";
import DiscordIcon from "../../images/social/Discord.svg";
import DribbbleIcon from "../../images/social/Dribbble.svg";
import DropboxIcon from "../../images/social/Dropbox.svg";
import EtsyIcon from "../../images/social/Etsy.svg";
import FacebookIcon from "../../images/social/Facebook.svg";
import FacebookMessengerIcon from "../../images/social/Facebook Messenger.svg";
import FigmaIcon from "../../images/social/Figma.svg";
import FlickrIcon from "../../images/social/Flickr.svg";
import GitHubIcon from "../../images/social/GitHub.svg";
import GitLabIcon from "../../images/social/GitLab.svg";
import GoogleIcon from "../../images/social/Google.svg";
import HouzzIcon from "../../images/social/Houzz.svg";
import InstagramIcon from "../../images/social/Instagram.svg";
import KickstarterIcon from "../../images/social/Kickstarter.svg";
import LinkedinIcon from "../../images/social/Linkedin.svg";
import MediumIcon from "../../images/social/Medium.svg";
import NotionIcon from "../../images/social/Notion.svg";
import PayPalIcon from "../../images/social/PayPal.svg";
import PinterestIcon from "../../images/social/Pinterest.svg";
import ProductHuntIcon from "../../images/social/Product Hunt.svg";
import QuoraIcon from "../../images/social/Quora.svg";
import RedditIcon from "../../images/social/Reddit.svg";
import SubstackIcon from "../../images/social/Substack.svg";
import SignalIcon from "../../images/social/Signal.svg";
import SkypeIcon from "../../images/social/Skype.svg";
import SlackIcon from "../../images/social/Slack.svg";
import SnapchatIcon from "../../images/social/Snapchat.svg";
import SoundCloudIcon from "../../images/social/Soundcloud.svg";
import SpotifyIcon from "../../images/social/Spotify.svg";
import StackOverflowIcon from "../../images/social/Stack Overflow.svg";
import TelegramIcon from "../../images/social/Telegram.svg";
import TikTokIcon from "../../images/social/TikTok.svg";
import TripAdvisorIcon from "../../images/social/Tripadvisor.svg";
import TumblrIcon from "../../images/social/Tumblr.svg";
import TwitchIcon from "../../images/social/Twitch.svg";
import TwitterIcon from "../../images/social/Twitter.svg";
import VimeoIcon from "../../images/social/Vimeo.svg";
import VKontakteIcon from "../../images/social/VKontakte.svg";
import WhatsAppIcon from "../../images/social/WhatsApp.svg";
import WikipediaIcon from "../../images/social/Wikipedia.svg";
import YahooIcon from "../../images/social/Yahoo.svg";
import YelpIcon from "../../images/social/Yelp.svg";
import YouTubeIcon from "../../images/social/YouTube.svg";
import ZoomIcon from "../../images/social/Zoom.svg";

const Socialicons = [
  {
    title: "Custom Link",
    icon: CustomLinkIcon,
  },
  {
    title: "Airbnb",
    icon: AirbnbIcon,
  },
  {
    title: "Amazon",
    icon: AmazonIcon,
  },
  {
    title: "Apple",
    icon: AppleIcon,
  },
  {
    title: "Apple Podcast",
    icon: ApplePodcastIcon,
  },
  {
    title: "Behance",
    icon: BehanceIcon,
  },
  {
    title: "DeviantArt",
    icon: DeviantArtIcon,
  },
  {
    title: "Discord",
    icon: DiscordIcon,
  },
  {
    title: "Dribbble",
    icon: DribbbleIcon,
  },
  {
    title: "Dropbox",
    icon: DropboxIcon,
  },
  {
    title: "Etsy",
    icon: EtsyIcon,
  },
  {
    title: "Facebook",
    icon: FacebookIcon,
  },
  {
    title: "Facebook Messenger",
    icon: FacebookMessengerIcon,
  },
  {
    title: "Figma",
    icon: FigmaIcon,
  },
  {
    title: "Flickr",
    icon: FlickrIcon,
  },
  {
    title: "GitHub",
    icon: GitHubIcon,
  },
  {
    title: "GitLab",
    icon: GitLabIcon,
  },
  {
    title: "Google",
    icon: GoogleIcon,
  },
  {
    title: "Houzz",
    icon: HouzzIcon,
  },
  {
    title: "Instagram",
    icon: InstagramIcon,
  },
  {
    title: "Kickstarter",
    icon: KickstarterIcon,
  },
  {
    title: "Linkedin",
    icon: LinkedinIcon,
  },
  {
    title: "Medium",
    icon: MediumIcon,
  },
  {
    title: "Notion",
    icon: NotionIcon,
  },
  {
    title: "PayPal",
    icon: PayPalIcon,
  },
  {
    title: "Pinterest",
    icon: PinterestIcon,
  },
  {
    title: "Product Hunt",
    icon: ProductHuntIcon,
  },
  {
    title: "Quora",
    icon: QuoraIcon,
  },
  {
    title: "Reddit",
    icon: RedditIcon,
  },
  {
    title: "Substack",
    icon: SubstackIcon,
  },
  {
    title: "Signal",
    icon: SignalIcon,
  },
  {
    title: "Skype",
    icon: SkypeIcon,
  },
  {
    title: "Slack",
    icon: SlackIcon,
  },
  {
    title: "Snapchat",
    icon: SnapchatIcon,
  },
  {
    title: "SoundCloud",
    icon: SoundCloudIcon,
  },
  {
    title: "Spotify",
    icon: SpotifyIcon,
  },
  {
    title: "Stack Overflow",
    icon: StackOverflowIcon,
  },
  {
    title: "Telegram",
    icon: TelegramIcon,
  },
  {
    title: "TikTok",
    icon: TikTokIcon,
  },
  {
    title: "TripAdvisor",
    icon: TripAdvisorIcon,
  },
  {
    title: "Tumblr",
    icon: TumblrIcon,
  },
  {
    title: "Twitch",
    icon: TwitchIcon,
  },
  {
    title: "Vimeo",
    icon: VimeoIcon,
  },
  {
    title: "VKontakte",
    icon: VKontakteIcon,
  },
  {
    title: "WhatsApp",
    icon: WhatsAppIcon,
  },
  {
    title: "Wikipedia",
    icon: WikipediaIcon,
  },
  {
    title: "X (Formerly Twitter)",
    icon: TwitterIcon,
  },
  {
    title: "Yahoo",
    icon: YahooIcon,
  },
  {
    title: "Yelp",
    icon: YelpIcon,
  },
  {
    title: "YouTube",
    icon: YouTubeIcon,
  },
  {
    title: "Zoom",
    icon: ZoomIcon,
  },
];

export default Socialicons;
