import React from 'react'
import '../Loader/Loader.css'

function Loader() {
    return (
        <div className="progress-wrap">
            <div className="progress">
                <div className="color"></div>
            </div>
        </div>
    )
}

export default Loader;