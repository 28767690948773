import React, { useState } from 'react'

function DeletePublicationModal(props) {
    const {item, isOpen, deleteMedia, onClose, selectedItems } = props;
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            {isOpen && (
                <>
                    <div className="popupModalOverlay"></div>
                    <div className='popupModal'>
                        <h3 className='popupModalTitle'>Delete Team {item && item.length != 0 ? 'Media' : 'Medias' }</h3>
                        {item && item.length != 0 ? (
                            <p className='popupModalContent'>Are you sure you want to delete <span>{item.fileName}</span>? This action can’t be undone.</p>
                        ) : (
                            <p className='popupModalContent'>Are you sure you want to delete these <span>Media</span>? This action can’t be undone.</p>
                        )}
                        <div className='popupFields'>
                            <label className='fieldsLabel'>Enter Password</label>
                            <input type="password" name='password' autoComplete='off' className='inputFields' placeholder='Type Password' autoComplete='off'/>
                        </div>
                        <div className="popupModalBtn">
                            <button className="cancel" type="button" onClick={onClose}>Cancel</button>
                            <button className="savePicture" type="button" onClick={() => deleteMedia( item && item.length != 0 ? item._id : selectedItems )}>
                                {isLoading ?
                                    <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                    </div>
                                : "Delete"}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default DeletePublicationModal;