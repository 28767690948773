import React, { useState, useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { PageTabs, PageNavigation } from './PageTabs';
import General from "./General";
import Password from "./Password";
import AssistantHistory from "./AssistantHistory";
import SupportHistory from "./SupportHistory";

import "../../components/css/Profile.css"
import "../../components/css/PageTabs.css"

import ProfileInfo from "./ProfileInfo";
import TwoFactor from "./TwoFactor";
import axios from "axios";

function Setting(props) {

    const userinfo = JSON.parse(localStorage.getItem("userData"));

    const [userData, setUserData] = useState(userinfo);
    const [activePage, setActivePage] = useState("General");
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateUser,setUpdateUser]=useState(false)

    const [ProfileTabs, setProfileTabs] = useState([
        { name: 'General', href: '/setting/general' },
        { name: 'Password', href: '/setting/password' },
        {name: '2FA', href: '/setting/twofactor'}
    ]);

    const assistantHistoryFor = [ 'admin', 'assistant' ];
    const supportHistoryFor = [ 'support' ];

    if (userinfo.role && assistantHistoryFor.includes(userinfo.role)) {
        // Check if "Assistant History" tab already exists
        const isAssistantHistoryTabExist = ProfileTabs.some(tab => tab.name === 'Assistant History');
        if (!isAssistantHistoryTabExist) {
            setProfileTabs(prevTabs => [
                ...prevTabs,
                { name: 'Assistant History', href: '/setting/assistant-history' },
            ]);
        }
    }

    if (userinfo.role && supportHistoryFor.includes(userinfo.role)) {
        // Check if "Support History" tab already exists
        const isSupportHistoryTabExist = ProfileTabs.some(tab => tab.name === 'Support History');
        if (!isSupportHistoryTabExist) {
            setProfileTabs(prevTabs => [
                ...prevTabs,
                { name: 'Support History', href: '/setting/support-history' }
            ]);
        }
    }

    const handleFormSubmit = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 4000);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getLoginTeamData/${userinfo?._id}/`);
                setUserData(response.data.result);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData(); // Call the async function immediately
    }, [userinfo?._id, isUpdateUser]);
    return (
        <div className="main-wrapper">
            <Header activePage="setting" userData={userData}/>
            <div className="main-content">
                <div className="content-wrapper">
                    <ProfileInfo userData={userData} />
                    <PageTabs tabs={ProfileTabs} activePage={activePage} setActivePage={setActivePage} />
                    <PageNavigation tabs={ProfileTabs} activePage={activePage} setActivePage={setActivePage} />

                    <div className="settingContent">
                        <Routes basename="/setting">
                            <Route path="/" element={ <General setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} userData={userData} setUserData={setUserData} setUpdateUser={setUpdateUser}/> } ></Route>
                            <Route path="/general" element={ <General setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} userData={userData} setUserData={setUserData} setUpdateUser={setUpdateUser}/> } ></Route>
                            <Route path="/password" element={ <Password setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} /> } ></Route>
                            <Route path='/twofactor' element={ <TwoFactor setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} />}></Route>
                            <Route path="/assistant-history" element={ <AssistantHistory setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} /> } ></Route>
                            <Route path="/support-history" element={ <SupportHistory setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} /> } ></Route>
                        </Routes>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    )
};

export default Setting;