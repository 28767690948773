import React, { useEffect, useState } from "react";
import arrowPagination from "../images/button-arrow.svg"

function Pagination({totalPage, currentPage, setCurrentPage}) {

    const [totalPages, setTotalPages] = useState([]);

    
    const returnPaginationRange = (totalPage, currentPage) => {
        const totalPageNoInArray = 20;
        
        if( totalPageNoInArray >= totalPage ) {
            let rangeOfPagination = numberArray(1, totalPage);
            setTotalPages(rangeOfPagination);
        } else {
            let leftSiblingsIndex  = Math.max(currentPage - 1, 1);
            let rightSiblingsIndex = Math.min(currentPage + 1, totalPage);

            let showleftDots  = leftSiblingsIndex > 6; 
            let showRightDots = rightSiblingsIndex < totalPage - 6;

            if( !showleftDots && showRightDots ) {
                let leftItemCount = 5 + 2 * 1;
                let leftRange     = numberArray(1, leftItemCount);
                setTotalPages([...leftRange, "...", totalPage - 4, totalPage - 3, totalPage - 2, totalPage - 1, totalPage]);
            } else if (showleftDots && !showRightDots) {
                let rightItemCount = 5 + 2 * 1;
                let rightRange    = numberArray(totalPage - rightItemCount, totalPage);
                setTotalPages([1, 2, 3, 4, 5, "...",...rightRange]);
            } else {
                let middelRange = numberArray(leftSiblingsIndex, rightSiblingsIndex);
                setTotalPages([1, 2, 3, 4, 5, "...",...middelRange, "...", totalPage - 4, totalPage - 3, totalPage - 2, totalPage - 1, totalPage]);
            }
        }
    }

    const numberArray = (startNumber, endNumber) => {
        const array = [];
        for (let i = startNumber; i <= endNumber; i++) {
            array.push(i);
        }

        return array;
    }

    useEffect(() => {        
        returnPaginationRange(totalPage, currentPage); // Call the async function immediately
    }, [currentPage,totalPage]);

    const handlePageClick = (page) => {
        if(page != '...') {
            setCurrentPage(page);
            scrollToTop(); // Scroll to the top when a page is clicked
        }
    }

    const handlePrevPageClick = () => {
        if(currentPage > 1 ) {
            setCurrentPage(currentPage - 1);
            scrollToTop();
        }
    }

    const handleNextPageClick = () => {
        if( currentPage < totalPage ) {
            setCurrentPage(currentPage + 1);
            scrollToTop();
        }
    }
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };

    return(        
        <div className="table-pagination">
            <div className="pagination-prev">
                <span className="pagination-item" onClick={() => handlePrevPageClick()}>
                    <img src={arrowPagination} alt="prev-icon" />
                    Previous
                </span>
            </div>
            <div className="pagination-number">
            
            {totalPages.map((page, index) => (
                <span key={index} className={`pagination-item ${page == currentPage && 'current' }`} onClick={() => handlePageClick(page)}>{page}</span>
            ))}
            </div>
            <div className="pagination-next">
                <span className="pagination-item" onClick={() => handleNextPageClick()}>
                    Next
                    <img src={arrowPagination} alt="next-icon" />
                </span>
            </div>
        </div>
    )
}

export default Pagination;