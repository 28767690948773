import React from 'react'
import UsersTable from "./UsersTable";

const UsersList = () => {
    return (
        <>
            <div className="dashboard-top-left">
                <h2 className="dashboard-top-text">Users</h2>
                <p className="dashboard-top-subtext">
                    Track, manage and forecast your customers and orders.
                </p>
            </div>
            <UsersTable />
        </>
    )
};

export default UsersList;
