import React, { useEffect, useState, useRef } from "react";
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'
import Loader from "../../../components/Loader/Loader";

import fileImageIcon from "../../../images/file-image-icon.svg";
import filePdfIcon from "../../../images/file-pdf-icon.svg";
import closeIcon from "../../../images/x-close.svg";

function ImagesPublication(props) {

    const { PubID, dbPageTitle, editorContent, validateTitle, validateEditorContent, setValidateTitle, setValidateEditorContent, setdbPageTitle, setEditorContent } = props;

    const pageTitleRef = useRef(null);
    const pageContentRef = useRef(null);
    const pulicationFileInputRef = useRef(null);

    const [isDragging, setIsDragging] = useState(false);

    const [validateImage, setValidateImage] = useState(false);
    const [validateFileImage, setValidateFileImage] = useState(false);
    const [isImageDragging, setIsImageDragging] = useState(false);

    const [selectedImageGallery, setSelectedImageGallery] = useState([]);
    const [imageGalleryloadingStates, setimageGalleryLoadingStates] = useState([]);
    const [loadingFiles, setloadingFiles] = useState(false);

    useEffect(() => {

        const handleResize = () => {
            if( pageTitleRef.current ) {
                pageTitleRef.current.style.height = '40px';
                pageTitleRef.current.style.height = pageTitleRef.current.scrollHeight + 2 + 'px';
            }
            
            if( pageContentRef.current ) {
                pageContentRef.current.style.height = '130px';

                var extraSpace = 2;

                if (pageContentRef.current.scrollHeight > 130) {
                    extraSpace = 20;
                }

                pageContentRef.current.style.height = pageContentRef.current.scrollHeight + extraSpace + 'px';
            }
        };

        handleResize();

        // Attach event listener for window resize
        window.addEventListener('resize', handleResize);


        // Cleanup: Remove event listener on component unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };

    }, [dbPageTitle, editorContent]);


    const handlePageTitleInput = (e) => {
        e.target.style.height = '40px';
        e.target.style.height = e.target.scrollHeight + 2 + 'px';

        setdbPageTitle(e.target.value);
        if (e.target.value.trim().length > 0) {
            setValidateTitle(false);
        }
    }

    const handlePageContentcInput = (e) => {
        e.target.style.height = '130px';

        var extraSpace = 2;

        if (e.target.scrollHeight > 130) {
            extraSpace = 20;
        }

        e.target.style.height = e.target.scrollHeight + extraSpace + 'px';

        setEditorContent(e.target.value);
        if (e.target.value.trim().length > 0) {
            setValidateEditorContent(false);
        }
    }


    const handleFileDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsImageDragging(true);
        }
    }

    const handleFileDragLeave = (e) => {
        e.preventDefault();
        setIsImageDragging(false);
    };

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        setSelectedImageGallery((imgObj) => arrayMoveImmutable(imgObj, oldIndex, newIndex));
    }

    const handlePublicationImageGalleryUpload = (e) => {
        let loadinfEffectTime = 0;
        const loadinfEffectAddTime = 4000;
        const files = e.target.files;
        const maxSizeInBytes = 128 * 1024 * 1024; // 128MB
        const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];

        if (files) {
            const selectedImagesArray = Array.from(files).filter(
                (file) =>
                    file.size <= maxSizeInBytes && allowedFileTypes.includes(file.type)
            );
            const initialLoadingStates = selectedImagesArray.map(() => 'loadingQueue');
            setimageGalleryLoadingStates((previousLoadingStatus) => [...initialLoadingStates, ...previousLoadingStatus ]);

            if (files.length != selectedImagesArray.length) {
                setValidateImage(true);
            } else {
                setValidateImage(false);
            }

            if (selectedImagesArray.length != 0) {
                setloadingFiles(true);
                selectedImagesArray.forEach((image, index) => {
                    const newIndex = imageGalleryloadingStates.length != 0 ? imageGalleryloadingStates.length + index : index;
                    setTimeout(() => {
                        setimageGalleryLoadingStates((prevLoadingStates) => {
                            const newLoadingStates = [...prevLoadingStates];
                            newLoadingStates[index] = true;
                            return newLoadingStates;
                        });
                    }, loadinfEffectTime);
                    loadinfEffectTime = loadinfEffectTime + loadinfEffectAddTime;

                    setTimeout(() => {
                        setimageGalleryLoadingStates((prevLoadingStates) => {
                            const newLoadingStates = [...prevLoadingStates];
                            newLoadingStates[index] = false;
                            return newLoadingStates;
                        });
                    }, loadinfEffectTime);
                });

                const totalTime = selectedImagesArray.length * loadinfEffectAddTime;
                setTimeout(() => {
                    setloadingFiles(false);
                }, totalTime);
                setSelectedImageGallery((selectedImageGallery) => [ ...selectedImagesArray, ...selectedImageGallery]);
            }
        } else {
            setloadingFiles(false);
            setValidateFileImage(true);
        }

    }

    const handleGalleryImageDrop = (e) => {
        e.preventDefault();
        let loadinfEffectTime = 0;
        const loadinfEffectAddTime = 4000;
        const files = e.dataTransfer.files;
        const maxSizeInBytes = 128 * 1024 * 1024; // 128MB
        const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];

        if (files) {
            const selectedImagesArray = Array.from(files).filter(
                (file) =>
                    file.size <= maxSizeInBytes && allowedFileTypes.includes(file.type)
            );
            const initialLoadingStates = selectedImagesArray.map(() => 'loadingQueue');
            setimageGalleryLoadingStates((previousLoadingStatus) => [...initialLoadingStates, ...previousLoadingStatus ]);

            if (files.length != selectedImagesArray.length) {
                setValidateImage(true);
            } else {
                setValidateImage(false);
            }

            if (selectedImagesArray.length != 0) {
                setloadingFiles(true);
                selectedImagesArray.forEach((image, index) => {
                    const newIndex = imageGalleryloadingStates.length != 0 ? imageGalleryloadingStates.length + index : index;
                    setTimeout(() => {
                        setimageGalleryLoadingStates((prevLoadingStates) => {
                            const newLoadingStates = [...prevLoadingStates];
                            newLoadingStates[index] = true;
                            return newLoadingStates;
                        });
                    }, loadinfEffectTime);
                    loadinfEffectTime = loadinfEffectTime + loadinfEffectAddTime;

                    setTimeout(() => {
                        setimageGalleryLoadingStates((prevLoadingStates) => {
                            const newLoadingStates = [...prevLoadingStates];
                            newLoadingStates[index] = false;
                            return newLoadingStates;
                        });
                    }, loadinfEffectTime);
                });

                const totalTime = selectedImagesArray.length * loadinfEffectAddTime;
                setTimeout(() => {
                    setloadingFiles(false);
                }, totalTime);
                setSelectedImageGallery((selectedImageGallery) => [ ...selectedImagesArray, ...selectedImageGallery]);
            }
        } else {
            setloadingFiles(false);
            setValidateFileImage(true);
        }
        setIsImageDragging(false);
    };

    const removeGalleryImage = (index) => {
        setSelectedImageGallery((selectedImageGallery) => {
            const newImages = [...selectedImageGallery];
            newImages.splice(index, 1);
            return newImages;
        });
    };

    const [currentItemIndex, setCurrentItemIndex] = useState(null);

    const handleUploadItemClick = (index) => {
      setCurrentItemIndex(index === currentItemIndex ? null : index);
    };


    return (
        <>
            <div className="newpageLeftRow textInfoBlock">
                <h2>
                    Upload Images
                </h2>
                <p>
                    Upload images for your visitors to view directly on your web profile. For those seeking to showcase a collection, we also support the creation of a gallery, where multiple images can be uploaded to form a cohesive visual narrative.
                </p>
                <p>
                    We want to ensure that your audience enjoys a seamless, in-browser viewing experience. This not only keeps them engaged but also guarantees that your images are displayed in the exact manner you envisaged. Whether it's a single snapshot or an array of photographs, your work will be presented in its best light.
                </p>
                <div className="newpageLeftRow newpageCard linkDataBox linkBox newpagefile">
                    <div className="linkData">
                        <p className="label" >Page title</p>
                        <textarea id="linkDataTitle" className="linkDataTitle" placeholder="Type title..." ref={pageTitleRef} onChange={(e) => handlePageTitleInput(e)} value={dbPageTitle}></textarea>
                        {validateTitle && (
                            <p className="validateError">The field is not filled</p>
                        )}
                        <p className="label">Description</p>
                        <textarea id="linkDataDiscripation" placeholder="Write a short description about the file..." className="linkDataDiscripation" ref={pageContentRef} onChange={(e) => handlePageContentcInput(e)} value={editorContent}></textarea>
                        {validateEditorContent && (
                            <p className="validateError">The field is not filled</p>
                        )}
                    </div>
                </div>
            </div>
            <div className={`newpageLeftRow textBlock ulpoadImageBlock ${loadingFiles ? 'uploadRunning' : ''}`}>
                <div className={isImageDragging ? `draging fileUploadBox` : `fileUploadBox`}
                    onDragOver={handleFileDragOver}
                    onDragLeave={handleFileDragLeave}
                    onDrop={handleGalleryImageDrop}
                >
                    <input
                        type="file"
                        ref={pulicationFileInputRef}
                        id="pulication-file"
                        multiple
                        onChange={handlePublicationImageGalleryUpload}
                        accept=".jpg, .png, .pdf"
                        hidden
                    />
                    <div
                        className="uploadFileBlock"
                        onClick={() =>
                            document.getElementById("pulication-file").click()
                        }
                    >
                        <div className="featureImgMiddleContent">
                            <img
                                className="fileUploadCover_Img"
                                src={fileImageIcon}
                                alt="FeaturedImage"
                            />
                            <p className="newPageImageUploadText">
                                <span>Open image gallery</span> or <span>upload</span>{" "}
                                <br />
                                jpg, png, pdf (max. 128MB)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {validateImage === true ? (
                <p className="validateError">Some selected files have invalid type or size. Please choose valid images (jpg, png) or PDF files within 128MB.</p>
            ) : (
                ""
            )}
            {validateFileImage === true ? (
                <p className="validateError">The field is not filled</p>
            ) : (
                ""
            )}
            <SortableList onSortEnd={onSortEnd} className={`uploadGrid ${loadingFiles ? 'uploadRunning' : ''}`} draggedItemClassName="dragged">
                {selectedImageGallery.map((image, index) => (
                    <SortableItem key={index}>
                    <div className={`uploadItem ${imageGalleryloadingStates[index] === 'loadingQueue' ? 'uploadingQueue' : ''} ${ index === currentItemIndex ? 'current' : '' }`} onClick={() => handleUploadItemClick(index)} >
                            <div className="uploadCard">
                                {imageGalleryloadingStates[index] && imageGalleryloadingStates[index] != 'loadingQueue' ? (
                                    <div className="uploadPicture">
                                        <Loader />
                                    </div>
                                ) : image.type.startsWith('image/') ? (
                                    <div className="uploadPicture">
                                        <div className="uploadThumbnail">
                                            <div className="uploadPictureCenter">
                                                <img
                                                    src={URL.createObjectURL(image)}
                                                    alt={`preview-${index}`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="uploadPDF">
                                        <div className="uploadThumbnail">
                                            <div className="uploadPictureCenter">
                                                <img src={filePdfIcon} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="uploadContent">
                                {imageGalleryloadingStates[index] === false ? (
                                    <>
                                        <img className="uploadClose" src={closeIcon} alt="icon-close" onClick={() => removeGalleryImage(index)} />
                                        <p className="uploadDetails">Details</p>
                                    </>
                                ) : null}
                                </div>
                            </div>
                        </div>
                    </SortableItem>
                ))}
            </SortableList>
        </>
    )
}

export default ImagesPublication;