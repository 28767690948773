
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../.././App.css';
import axios from 'axios';

function VerifyCode(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [codeInputs, setCodeInputs] = useState(['', '', '', '', '']);
    const [inputError, setInputError] = useState(false);
    const [invalidOtpError, setInvalidOtpError] = useState(false);
    const inputRefs = useRef([]);
    const handleInputChange = (index, value) => {
        if (value.length <= 1 && /^\d*$/.test(value)) {
            const newInputs = [...codeInputs];
            newInputs[index] = value;
            setCodeInputs(newInputs);

            if (value !== '') {
                // Move focus to the next input field
                if (index < codeInputs.length - 1) {
                    inputRefs.current[index + 1].focus();
                }
            } else {
                // Move focus to the previous input field
                if (index > 0) {
                    inputRefs.current[index - 1].focus();
                }
            }
        }
    };
    const handleOtpPaste = (e) => {
        const paste = e.clipboardData.getData('text');
        if (/^\d{5}$/.test(paste)) { // Assuming OTP length is 5
          const newInputs = paste.split('');
          setCodeInputs(newInputs);
          newInputs.forEach((value, index) => {
            inputRefs.current[index].value = value;
            if (index < inputRefs.current.length - 1) {
              inputRefs.current[index + 1].focus();
            }
          });
        }
        e.preventDefault();
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSend(e);
        }
    };

    const handleSend = async (e) => {
        e.preventDefault();        
        setIsLoading(true);
        try {            
            if (codeInputs) {
                const otpDigit = codeInputs.join('');
                const payload = {
                    email: props.email,
                    otp: Number(otpDigit)
                }
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/userOtpVerify/${JSON.parse(localStorage.getItem("userData"))?._id}`,
                    payload
                );
                const updateData = {
                    twoFactorAuth: {
                        status: props?.isActiveField === true ? "enable" : "disabled",
                        method: props?.email ? 'email' : 'phone',
                        data: props?.email ? props?.email : props?.phoneNumber
                    }
                }
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userData"))?._id}`,
                    updateData
                );

                setInvalidOtpError(false)
                // setCodeInputs(["", "", "", "", ""])
                if (codeInputs.some(input => input === '')) {
                    setInputError(true);
                    setInvalidOtpError(false)
                } else {
                    setInputError(false);
                    setTimeout(() => {
                        props.setStatus({
                            status: props?.isActiveField === true ? "enable" : "disabled",
                            method: props?.email ? 'email' : 'phone',
                            data: props?.email ? props?.email : props?.phoneNumber
                        })
                        props.setIsAddFactorOpen(false);
                    }, 2000);
                    await axios.get(
                        `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userData"))?._id}`
                    );
                }
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                setInvalidOtpError(true);
            } else {
                setInvalidOtpError(false);
            }
        }        
        setIsLoading(false);
        props.setOpenNotify(true)
    };
   
    return (
        <>
            <div className="popupContent">
                <p className="popupTitle">Set Two Factor Authentication</p>
                <p className="popupDesc">Enter the code to complete the two-factor authentication setup and secure your account.</p>
            </div>
            <div className='input-verify-code'>
                <p className='emailAddress-text'>Verification code</p>
                <div className='codeSection' onPaste={handleOtpPaste}>
                    {codeInputs.map((value, index) => (
                        <input
                            key={index}
                            type='number'
                            name='otp'
                            placeholder='0'
                            className='code-input'
                            value={value}
                            maxLength={1}
                            autoFocus={index === 0}
                            onChange={e => handleInputChange(index, e.target.value)}
                            ref={el => inputRefs.current[index] = el}
                            onKeyPress={(e) => handleKeyPress(e)}
                        />
                    ))}
                </div>
            </div>
            <div className='resend-text'>
                <p>Didn’t get a code? <Link onClick={() => {
                    axios.post(
                        `${process.env.REACT_APP_API_URL}/user/twoFactorEmailOTP/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                        { email: props.email }
                    );
                    setCodeInputs(["", "", "", "", ""])
                }}> Click to resend.</Link></p>
            </div>
            {inputError && <p className="validateError">Please fill all code fields</p>}
            {invalidOtpError && <p className="validateError">The code you have entered is incorrect</p>}
            <div className="popupButtons">
                <button className='settingsWhite billingSaveBtn' onClick={() => {props?.handleBack()}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                        <path d="M12.3332 5H1.6665M1.6665 5L5.6665 9M1.6665 5L5.6665 1" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>Back
                </button>
                <button className='settingsBlue billingSaveBtn verifyLoader' onClick={handleSend}>
                    {isLoading ?
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                        : "Set 2FA"}
                </button>
            </div>
        </>
    );
}

export default VerifyCode;
