import { useEffect, useState } from 'react';
import axios from 'axios';
import { Routes, Route } from 'react-router-dom';

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import UsersList from './UsersList';
import UsersDetail from './UsersDetail';

import "./UserModule.css";

const Users = () => {
    const userinfo = JSON.parse(localStorage.getItem("userData"));
    
      const [userData, setUserData] = useState(userinfo);
      useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getLoginTeamData/${userinfo?._id}/`);
                setUserData(response.data.result);
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchData(); // Call the async function immediately
    }, [userinfo?._id]);

    return (
        <div className="main-wrapper">
            <Header activePage="users" userData={userData}/>
            <div className="main-content">
                <div className="content-wrapper">
                    <Routes basename="/users">
                        <Route path='/' element={<UsersList />} />
                        <Route path="/:id/*" element={<UsersDetail />} ></Route>
                    </Routes>
                </div>
                <Footer />
            </div>
        </div>
    )
};

export default Users;
