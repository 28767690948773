import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";

function AddNewDropdown(props) {
    const { id } = useParams();
    return (
        <div className='dropdown addDropdown'>
            <ul>
                <li>
                    <Link to={`/users/${id}/pages/page/`} onClick={e => props.setClose(false)}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Page</p>
                    </Link>
                </li>
                <li>
                    <Link to={`/users/${id}/publications/publication/`} onClick={e => props.setClose(false)}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Publication</p>
                    </Link>
                </li>
                <li>
                    <Link to={`/users/${id}/newsletters/newsletter/`} onClick={e => props.setClose(false)}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Newsletter</p>
                    </Link>
                </li>

            </ul>
        </div>
    );
}

export default AddNewDropdown;
