
import { useEffect, useRef, useState } from "react";
import NoResultsMedia from "./NoResultsMedia";
import EditMediaPopup from "./EditMediaPopup";
import Loader from "../../components/Loader/Loader"

import filePdfIcon from "../../images/file-pdf-icon.svg"
import fileDocIcon from "../../images/file-doc-icon.svg"
import fileDocxIcon from "../../images/file-docx-icon.svg"
import filePptIcon from "../../images/file-ppt-icon.svg"
import filePptxIcon from "../../images/file-pptx-icon.svg"
import filePptmIcon from "../../images/file-pptm-icon.svg"
import fileXlsIcon from "../../images/file-xls-icon.svg"
import fileXlsxIcon from "../../images/file-xlsx-icon.svg"
import fileXlsmIcon from "../../images/file-xlsm-icon.svg"
import fileVideoIcon from "../../images/file-video-icon.svg"
import fileAudioIcon from "../../images/file-audio-icon.svg"
import axios from "axios";
import { useParams } from "react-router";
import { UpgradePopUp } from "../../components/modal/UpgradePopUp";


const GridLayout = ({mediaItem , setMediaItem, selectedItems, setSelectedItems, setUploadNewmedia, uploadNewmedia, isLoading,paymentMethods, setSaveNotify})=>{
    const [showPopup, setShowPopup] = useState(false);
    const [editedItemId, setEditedItemId] = useState(null);
    const [fileList, setFileList] = useState({ '^image/': 'Images' });
    const [isImageDragging, setIsImageDragging] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [errorMessages, setErrorMessages] = useState([])
    const [showUpgradeBtn, setShowUpgradeBtn] = useState(false);
    const {id} = useParams()
    const popupRef = useRef();
    const modelRef = useRef();
    const mediaIconSet = {
        'application/pdf': filePdfIcon,
        'application/doc': fileDocIcon,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': fileDocxIcon,
        'application/vnd.ms-powerpoint': filePptIcon,
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': filePptxIcon,
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': filePptmIcon,
        'application/vnd.ms-excel': fileXlsIcon,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': fileXlsxIcon,
        'application/vnd.ms-excel.sheet.macroenabled.12': fileXlsmIcon,
        'video/mp4': fileVideoIcon,
        'video/mpeg': fileVideoIcon,
        'video/ogg': fileVideoIcon,
        'video/webm': fileVideoIcon,
        'video/3gpp': fileVideoIcon,
        'video/3gpp2': fileVideoIcon,
        'video/x-msvideo': fileVideoIcon,
        'video/quicktime': fileVideoIcon,
        'audio/aac': fileAudioIcon,
        'audio/midi': fileAudioIcon,
        'audio/mpeg': fileAudioIcon,
        'audio/ogg': fileAudioIcon,
        'audio/wav': fileAudioIcon,
        'audio/webm': fileAudioIcon,
        'audio/3gpp': fileAudioIcon,
    }
    useEffect(() => {

        if( paymentMethods?.plan !== 'free_plan' ) {
            setFileList({
                '^image/' : 'Images', 
                '^video/': 'Videos', 
                'application/pdf': 'PDF', 
                'application/doc': 'DOC', 
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX', 
                'application/vnd.ms-powerpoint': 'PPT', 
                'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX', 
                'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM', 
                'application/vnd.ms-excel': 'XLS', 
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX', 
                'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
            })
        } else {
            setFileList({
                '^image/' : 'Images', 
            })
        }

        
    }, [paymentMethods]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowUpgradeBtn(false);
                setErrorMessages([])
            }
            if (modelRef.current && !modelRef.current.contains(event.target)) {
                setShowUpgradeBtn(false);
                setErrorMessages([])
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter((_id) => _id !== itemId);
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                return updatedSelectedItems;
            }
        });
    }

    const handleEditClick = (itemId) => {
        setShowPopup(true)
        setEditedItemId(itemId);
    };
  
    const maxSizeInBytes = 128 *  1024*  1024; // 128MB
    
    const allowFIleType = {
        '^image/': 'Images',
        '^video/': 'Videos',
        'application/pdf': 'PDF',
        'application/doc': 'DOC',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
        'application/vnd.ms-powerpoint': 'PPT',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
        'application/vnd.ms-excel': 'XLS',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
        'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
    }

    const handleGalleryImageDrop = async (e) => {
        e.preventDefault();
        let newErrorMessages = [];
        setErrorMessages([]);
        setShowUpgradeBtn(false);
        setIsImageDragging(false);
        const files = e.dataTransfer.files;

        let uploadedMediaId = 1;

        for (const file of files) {

            const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file.type));


            if (!isAllowedFileType) {
                // console.log(`File type ${file.type} is not allowed.`);
                const errorMessage = `File type ${file.type} is not allowed. Failed to upload file ${file.name}.`;
                newErrorMessages.push(errorMessage);

                if( Object.keys(allowFIleType).some((pattern) => new RegExp(pattern).test(file.type)) ) {
                    setShowUpgradeBtn(true);
                }
                continue; // Skip this file
            }

            if (file.size > maxSizeInBytes) {
                console.log(`File ${file.name} exceeds the maximum size of 128MB.`);
                continue; // Skip this file
            }

            setErrorMessages(newErrorMessages)

            const formData = new FormData();
            formData.append('files', file); // Append each file individually

            const uploadMedia = {
                _id: uploadedMediaId,
                name: file.name,
                description: file.name,
                alt: file.name,
                type: file.type,
                size: file.size,
                object_id: "",
                key: "",
                url: "",
                createdAt: "",
                isLoading: true
            }

            uploadedMediaId = uploadedMediaId + 1;

            setMediaItem((prevUserMedia) => [uploadMedia, ...prevUserMedia]);

            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                );
                // Update the uploadMedia object with the response data
                setMediaItem((prevUserMedia) =>
                    prevUserMedia.map((media) =>
                        media._id === uploadMedia._id ? { ...media, ...res.data.result[0], isLoading: false } : media
                    )
                );
            } catch (error) {
                console.log(error);
            }
        }

        setIsImageDragging(false);
        setErrorMessages(newErrorMessages);
    };

    const handleFileDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsImageDragging(true);
        }
    }

    const handleFileDragLeave = (e) => {
        e.preventDefault();
        setIsImageDragging(false);
    };
    
    return(
    
        <div className={isImageDragging ? 'grid-container media-grid-layout draging' : 'grid-container media-grid-layout '} onDragOver={handleFileDragOver}
        onDragLeave={handleFileDragLeave}
        onDrop={handleGalleryImageDrop}>
            {isLoading ? 
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
                :
                <>
                    {(mediaItem && mediaItem.length > 0)?  
                        <div className="gridIitem">
                            {mediaItem?.map((media) => (
                                <div className={`gridImg ${(media.type).includes('image') ? 'type-image' : 'type-doc'}`} key={media._id}>
                                    { media?.isLoading ? 
                                        <div className="uploadPicture">
                                            <Loader />
                                        </div>
                                    :
                                    <>
                                        <img src={mediaIconSet[media.type] ? mediaIconSet[media.type] : media.url} onClick={() => handleEditClick(media._id)} alt={media.name} className='item' />
                                        
                                        { (!(media.type).includes('image') ) && <div className='itemName'> {media.name} </div>}

                                        <input type="checkbox" checked={selectedItems.includes(media._id)} onChange={() => handleCheckboxChange(media._id)} />
                                    </>
                                    }
                                </div>
                            ))}
                            {showPopup &&   
                                <EditMediaPopup setShowPopup={setShowPopup} item={mediaItem.find(item => item._id === editedItemId)} userMedia={mediaItem} setUserMedia={setMediaItem} setSaveNotify={setSaveNotify}/>         
                            }
                        </div>
                        :
                        (<NoResultsMedia type="media-gallery" name="Media Library" onclickhandle={() => setUploadNewmedia(!uploadNewmedia)} />)
                    }  
                </>
            }
            {errorMessages && errorMessages.length > 0 && (
                <div className="errorMessages">
                    {errorMessages.map((errorMessage, index) => (
                        <>
                            <p key={index} className="error-message" >{errorMessage}</p>
                            {/* {showUpgradeBtn && <p className='upgrade-message' >Upgrade Now</p>}  */}
                        </>
                    ))}
                </div>
            )}
            {showUpgradeBtn && 
                <UpgradePopUp setShowUpgradeBtn={setShowUpgradeBtn} refrence={popupRef} setErrorMessages={setErrorMessages}/>
            }
        </div>
    )
}

export default GridLayout; 
