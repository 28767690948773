export const timezoneData = {
    "Africa": [
        "UTC+0 Abidjan"  ,
        "UTC+0 Accra",
        "UTC+3 Addis Ababa",
        "UTC+1 Algiers",
        "UTC+3 Asmara",
        "UTC+3 Asmera",
        "UTC+0 Bamako",
        "UTC+1 Bangui",
        "UTC+0 Banjul",
        "UTC+0 Bissau",
        "UTC+2 Blantyre",
        "UTC+1 Brazzaville",
        "UTC+2 Bujumbura",
        "UTC+2 Cairo",
        "UTC+1 Casablanca",
        "UTC+1 Ceuta",
        "UTC+0 Conakry",
        "UTC+0 Dakar",
        "UTC+3 Dar es_Salaam",
        "UTC+3 Djibouti",
        "UTC+1 Douala",
        "UTC+1 El Aaiun",
        "UTC+0 Freetown",
        "UTC+2 Gaborone",
        "UTC+2 Harare",
        "UTC+2 Johannesburg",
        "UTC+2 Juba",
        "UTC+3 Kampala",
        "UTC+2 Khartoum",
        "UTC+1 Kinshasa",
        "UTC+1 Lagos",
        "UTC+1 Libreville",
        "UTC+0 Lome",
        "UTC+1 Luanda",
        "UTC+2 Lubumbashi",
        "UTC+2 Lusaka",
        "UTC+1 Malabo",
        "UTC+2 Maputo",
        "UTC+2 Maseru",
        "UTC+2 Mbabane",
        "UTC+3 Mogadishu",
        "UTC+2 Kigali",
        "UTC+0 Monrovia",
        "UTC+3 Nairobi",
        "UTC+1 Ndjamena",
        "UTC+1 Niamey",
        "UTC+0 Nouakchott",
        "UTC+0 Ouagadougou",
        "UTC+1 Porto-Novo",
        "UTC+0 Sao Tome",
        "UTC+0 Timbuktu",
        "UTC+2 Tripoli",
        "UTC+1 Tunis",
        "UTC+2 Windhoek",


    ],
    "America": [
        "UTC-10 Adak",
        "UTC-9 Anchorage",
        "UTC-4 Anguilla",
        "UTC-4 Antigua",
        "UTC-3 Araguaina",
        "UTC-3 Argentina",
        "UTC-4 Aruba",
        "UTC-3 Asuncion",
        "UTC-5 Atikokan",
        "UTC-10 Atka",
        "UTC-3 Bahia",
        "UTC-6 Bahia Banderas",
        "UTC-4 Barbados",
        "UTC-3 Belem",
        "UTC-6 Belize",
        "UTC-4 Blanc-Sablon",
        "UTC-4 Boa Vista",
        "UTC-5 Bogota",
        "UTC-7 Boise",
        "UTC-3 Buenos Aires",
        "UTC-7 Cambridge Bay",
        "UTC-4 Campo Grande",
        "UTC-5 Cancun",
        "UTC-4 Caracas",
        "UTC-3 Catamarca",
        "UTC-3 Cayenne",
        "UTC-5 Cayman",
        "UTC-6 Chicago",
        "UTC-6 Chihuahua",
        "UTC-5 Coral Harbour",
        "UTC-3 Cordoba",
        "UTC-6 Costa Rica",
        "UTC-7 Creston",
        "UTC-4 Cuiaba",
        "UTC-4 Curacao",
        "UTC+0 Danmarkshavn",
        "UTC-7 Dawson",
        "UTC-7 Dawson Creek",
        "UTC-7 Denver ",
        "UTC-5 Detroit",
        "UTC-4 Dominica",
        "UTC-7 Edmonton",
        "UTC-5 Eirunepe",
        "UTC-6 El Salvador",
        "UTC-8 Ensenada",
        "UTC-7 Fort Nelson",
        "UTC-5 Fort Wayne",
        "UTC-3 Fortaleza",
        "UTC-4 Glace Bay",
        "UTC-3 Godthab",
        "UTC-4 Goose Bay",
        "UTC-5 Grand Turk",
        "UTC-4 Grenada",
        "UTC-4 Guadeloupe",
        "UTC-6 Guatemala",
        "UTC-5 Guayaquil",
        "UTC-4 Guyana",
        "UTC-4 Halifax",
        "UTC-5 Havana",
        "UTC-7 Hermosillo",
        "UTC-5 Indiana",
        "UTC-6 Indiana",
        "UTC-5 Indianapolis",
        "UTC-7 Inuvik",
        "UTC-5 Iqaluit",
        "UTC-5 Jamaica",
        "UTC-3 Jujuy",
        "UTC-9 Juneau",
        "UTC-5 America",
        "UTC-6 Knox IN",
        "UTC-4 Kralendijk",
        "UTC-4 La Paz",
        "UTC-5 Lima",
        "UTC-8 Los Angeles",
        "UTC-5 Louisville",
        "UTC-4 Lower Princes",
        "UTC-3 Maceio",
        "UTC-6 Managua",
        "UTC-4 Manaus",
        "UTC-4 Marigot",
        "UTC-4 Martinique",
        "UTC-6 Matamoros",
        "UTC-7 Mazatlan",
        "UTC-3 Mendoza",
        "UTC-6 Menominee",
        "UTC-6 Merida",
        "UTC-9 Metlakatla",
        "UTC-6 Mexico City",
        "UTC-3 Miquelon",
        "UTC-4 Moncton",
        "UTC-6 Monterrey",
        "UTC-3 Montevideo",
        "UTC-5 Montreal",
        "UTC-4 Montserrat",
        "UTC-5 Nassau",
        "UTC-5 New York",
        "UTC-5 Nipigon",
        "UTC-9 Nome",
        "UTC-2 Noronha",
        "UTC-6 America",
        "UTC-3 Nuuk",
        "UTC-6 Ojinagaa",
        "UTC-5 Panama",
        "UTC-5 Pangnirtung",
        "UTC-3 Paramaribo",
        "UTC-7 Phoenix",
        "UTC-5 Port-au-PrinceC",
        "UTC-4 Port of_Spain",
        "UTC-5 Porto Acre",
        "UTC-4 Porto Velho",
        "UTC-4 Puerto Rico",
        "UTC-3 Punta Arenas",
        "UTC-6 Rainy River",
        "UTC-6 Rankin Inlet",
        "UTC-3 Recife",
        "UTC-6 Regina",
        "UTC-6 Resolute",
        "UTC-5 Rio Branco",
        "UTC-3 Rosario",
        "UTC-8 Santa Isabel",
        "UTC-3 Santarem",
        "UTC-3 Santiago",
        "UTC-4 Santo Domingo",
        "UTC-3 Sao Paulo",
        "UTC-1 Scoresbysund",
        "UTC-7 Shiprock",
        "UTC-9 Sitka",
        "UTC-4 St Barthelemy",
        "UTC-3.5 St Johns",
        "UTC-4 St Kitts",
        "UTC-4 St Lucia",
        "UTC-4 St Thomas",
        "UTC-4 St Vincent",
        "UTC-6 Swift Current",
        "UTC-6 Tegucigalpa",
        "UTC-4 Thule",
        "UTC-5 Thunder Bay",
        "UTC-8 Tijuana",
        "UTC-5 Toronto",
        "UTC-4 Tortola",
        "UTC-8 Vancouver",
        "UTC-4 Virgin",
        "UTC-7 Whitehorse",
        "UTC-6 Winnipeg",
        "UTC-9 Yakutat",
        "UTC-7 Yellowknife",
    ],
    "Asia": [
        "UTC+3 Aden",
        "UTC+6 Almaty",
        "UTC+3 Amman",
        "UTC+12 Anadyr",
        "UTC+5 Aqtau",
        "UTC+5 Aqtobe",
        "UTC+5 Ashgabat",
        "UTC+5 Ashkhabad",
        "UTC+5 Atyrau",
        "UTC+3 Baghdad",
        "UTC+3 Bahrain",
        "UTC+4 Baku",
        "UTC+7 Bangkok",
        "UTC+7 Barnaul",
        "UTC+2 Beirut",
        "UTC+6 Bishkek",
        "UTC+8 Brunei",
        "UTC+5.5 Calcutta",
        "UTC+9 Chita",
        "UTC+8 Choibalsan",
        "UTC+8 Chongqing",
        "UTC+8 Chungking",
        "UTC+5.5 Colombo",
        "UTC+6 Dacca",
        "UTC+3 Damascus",
        "UTC+6 Dhaka",
        "UTC+9 Dili",
        "UTC+4 Dubai",
        "UTC+5 Dushanbe",
        "UTC+2 Famagusta",
        "UTC+2 Gaza",
        "UTC+8 Harbin",
        "UTC+2 Hebron",
        "UTC+7 Ho Chi_Minh",
        "UTC+8 Hong Kong",
        "UTC+7 Hovd",
        "UTC+8 Irkutsk",
        "UTC+3 Istanbul",
        "UTC+7 Jakarta",
        "UTC+9 Jayapura",
        "UTC+2 Jerusalem",
        "UTC+4.5 Kabul",
        "UTC+12 Kamchatka",
        "UTC+5 Karachi",
        "UTC+6 Kashgar",
        "UTC+5.75 Kathmandu",
        "UTC+5.75 Katmandu",
        "UTC+9 Khandyga",
        "UTC+5.5 Kolkata",
        "UTC+7 Krasnoyarsk",
        "UTC+8 Kuala Lumpur",
        "UTC+8 Kuching",
        "UTC+3 Kuwait",
        "UTC+8 Macao",
        "UTC+8 Macau",
        "UTC+11 Magadan",
        "UTC+8 Makassar",
        "UTC+8 Manila",
        "UTC+4 Muscat",
        "UTC+2 Nicosia",
        "UTC+7 Novokuznetsk",
        "UTC+7 Novosibirsk",
        "UTC+6 Omsk",
        "UTC+5 Oral",
        "UTC+7 Phnom Penh",
        "UTC+7 Pontianak",
        "UTC+9 Pyongyang",
        "UTC+3 Qatar",
        "UTC+6 Kostanay",
        "UTC+5 Qyzylorda",
        "UTC+6.5 Rangoon",
        "UTC+3 Riyadh",
        "UTC+7 Saigon",
        "UTC+11 Sakhalin",
        "UTC+5 Samarkand",
        "UTC+9 Seoul",
        "UTC+8 Shanghai",
        "UTC+8 Singapore",
        "UTC+11 Srednekolymsk",
        "UTC+8 Taipei",
        "UTC+5 Tashkent",
        "UTC+4 Tbilisi",
        "UTC+3.5 Tehran",
        "UTC+2 Tel Aviv",
        "UTC+6 Thimbu",
        "UTC+6 Thimphu",
        "UTC+9 Tokyo",
        "UTC+7 Tomsk",
        "UTC+8 Ujung Pandang",
        "UTC+8 Ulaanbaatar",
        "UTC+8 Ulan Bator",
        "UTC+6 Urumqi",
        "UTC+10 Ust-Nera",
        "UTC+7 Vientiane",
        "UTC+10 Vladivostok",
        "UTC+9 Yakutsk",
        "UTC+6.5 Yangon",
        "UTC+5 Yekaterinburg",
        "UTC+4 Yerevan",
    ],
    "Australia": [
        "UTC+11 ACT",
        "UTC+10.5 Adelaide",
        "UTC+10 Brisbane",
        "UTC+10.5 Broken Hill",
        "UTC+11 Canberra",
        "UTC+11 Currie",
        "UTC+9.5 Darwin",
        "UTC+8.75 Eucla",
        "UTC+11 Hobart",
        "UTC+11 LHI",
        "UTC+10 Lindeman",
        "UTC+11 Lord Howe",
        "UTC+11 Melbourne",
        "UTC+11 NSW",
        "UTC+9.5 North",
        "UTC+8 Perth",
        "UTC+10 Queensland",
        "UTC+10.5 South",
        "UTC+11 Sydney",
        "UTC+11 Tasmania",
        "UTC+11 Victoria",
        "UTC+8 West",
        "UTC+10.5 Yancowinna",
    ],
    "Europe": [
        "UTC+1 Amsterdam",
        "UTC+1 Andorra",
        "UTC+4 Astrakhan",
        "UTC+2 Athens",
        "UTC+0 Belfast",
        "UTC+1 Belgrade",
        "UTC+1 Berlin",
        "UTC+1 Bratislava",
        "UTC+1 Brussels",
        "UTC+2 Bucharest",
        "UTC+1 Budapest",
        "UTC+1 Busingen",
        "UTC+2 Chisinau",
        "UTC+1 Copenhagen",
        "UTC+0 Dublin",
        "UTC+1 Gibraltar",
        "UTC+0 Guernsey",
        "UTC+2 Helsinki",
        "UTC+0 Isle of_Man",
        "UTC+3 Istanbul",
        "UTC+0 Jersey",
        "UTC+2 Kaliningrad",
        "UTC+2 Kiev",
        "UTC+3 Kirov",
        "UTC+2 Europe",
        "UTC+0 Lisbon",
        "UTC+1 Ljubljana",
        "UTC+0 London",
        "UTC+1 Luxembourg",
        "UTC+1 Madrid",
        "UTC+1 Malta",
        "UTC+2 Mariehamn",
        "UTC+3 Minsk",
        "UTC+1 Monaco",
        "UTC+3 Moscow",
        "UTC+2 Nicosia",
        "UTC+1 Oslo",
        "UTC+1 Paris",
        "UTC+1 Podgorica",
        "UTC+1 Prague",
        "UTC+2 Riga",
        "UTC+1 Rome",
        "UTC+4 Samara",
        "UTC+1 San Marino",
        "UTC+1 Sarajevo",
        "UTC+4 Saratov",
        "UTC+3 Simferopol",
        "UTC+1 Skopje",
        "UTC+2 Sofia",
        "UTC+1 Stockholm",
        "UTC+2 Tallinn",
        "UTC+1 Tirane",
        "UTC+2 Tiraspol",
        "UTC+4 Ulyanovsk",
        "UTC+2 Uzhgorod",
        "UTC+1 Vaduz",
        "UTC+1 Vatican",
        "UTC+1 Vienna",
        "UTC+2 Vilnius",
        "UTC+3 Volgograd",
        "UTC+1 Warsaw",
        "UTC+1 Zagreb",
        "UTC+2 Zaporozhye",
        "UTC+1 Zurich",
    ],
    "Brazil": [
        "UTC-5 Acre",
        "UTC-2 DeNoronha",
        "UTC-3 East",
        "UTC-4 West"
    ],
    "Arctic": [
        "UTC+1 Longyearbyen"
    ],
    "Atlantic": [
        "UTC-1 Azores",
        "UTC-4 Bermuda",
        "UTC+0 Canary",
        "UTC-1 Cape Verde",
        "UTC+0 Faeroe",
        "UTC+0 Faroe",
        "UTC+1 Jan Mayen",
        "UTC+0 Madeira",
        "UTC+0 Reykjavik",
        "UTC-2 South Georgia",
        "UTC+0 St Helena",
        "UTC-3 Stanley",
    ],
    "Canada": [
        "UTC-4 Atlantic",
        "UTC-6 Central",
        "UTC-5 Eastern",
        "UTC-7 Mountain",
        "UTC-3.5 Newfoundland",
        "UTC-8 Pacific",
        "UTC-6 Saskatchewan",
        "UTC-7 Yukon",
    ],
    "Chile": [
        "UTC-3 Continental",
        "UTC-5 EasterIsland"
    ],
    "India": [
        "UTC+3 Antananarivo",
        "UTC+6 Chagos",
        "UTC+7 Christmas",
        "UTC+6.5 Cocos",
        "UTC+3 Comoro",
        "UTC+5 Kerguelen",
        "UTC+4 Mahe",
        "UTC+5 Maldives",
        "UTC+4 Mauritius",
        "UTC+3 Mayotte",
        "UTC+4 Lord Reunion",
    ],
    "US": [
        "UTC-6 Central",
        "UTC-5 East-Indiana",
        "UTC-5 Eastern",
        "UTC-5 Michigan",
        "UTC-7 Mountain",
        "UTC-8 Pacific",
    ],
    "Pacific": [
        "UTC+13 Apia",
        "UTC+13 Auckland",
        "UTC+11 Bougainville",
        "UTC+13.75 Chatham",
        "UTC+10 Chuuk",
        "UTC-5 Easter",
        "UTC+11 Efate",
        "UTC+13 Enderbury",
        "UTC+13 Fakaofo",
        "UTC+12 Fiji",
        "UTC+12 Funafuti",
        "UTC-6 Galapagos",
        "UTC-9 Gambier",
        "UTC+11 Guadalcanal",
        "UTC+10 Guam",
        "UTC-10 Honolulu",
        "UTC-10 Johnston",
        "UTC+13 Kanton",
        "UTC+14 Kiritimati",
        "UTC+11 Kosrae",
        "UTC+12 Kwajalein",
        "UTC+12 Majuro",
        "UTC-9.5 Marquesas",
        "UTC-11 Midway",
        "UTC+12 Nauru",
        "UTC-11 Niue",
        "UTC+12 Norfolk",
        "UTC+11 Noumea",
        "UTC-11 Pago Pago",
        "UTC+9 Palau",
        "UTC-8 Pitcairn",
        "UTC+11 Pohnpei",
        "UTC+11 Ponape",
        "UTC+10 Port Moresby",
        "UTC-10 Rarotonga",
        "UTC+10 Saipan",
        "UTC-11 Samoa",
        "UTC-10 Tahiti",
        "UTC+12 Mahe",
        "UTC+13 Tongatapu",
        "UTC+10 Truk",
        "UTC+12 Wake",
        "UTC+12 Wallis",
        "UTC+10 Yap",
    ],
};
