import React, { useState, useRef, useEffect } from "react";
import { Chart, registerables } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import dateFormatter from "../../utils/dateFormatter";


Chart.register(...registerables);

const DashboardGraph = ({ activeData, graphData, compareData, isLoading, selectedPeriod, setSelectedPeriod, setSelectedDate, showCompare, setTempPeriod, setPreviousDate }) => {

  const chartRef = useRef(null)
  let sum = 0;

  

    useEffect(() => {
        if (graphData) {
          const ctx = chartRef?.current?.getContext('2d');
    
          var gradient = ctx?.createLinearGradient(0, 0, 0, 300);
          gradient?.addColorStop(0, 'rgba(101,116,205, 0.2)');
          gradient?.addColorStop(1, 'rgba(101,116,205, 0)');
          let datasets ;

          const updatedGraphData = graphData.map(data => {
            // Iterate over each key in the data object
            Object.keys(data).forEach(key => {
              // If the value is negative, change it to 0
              if (data[key] < 0) {
                data[key] = 0;
              }
            });
            return data;
          });

          if(activeData === "activeUsers"){
            gradient?.addColorStop(0, 'rgba(0, 255, 0, 0.2)');
            gradient?.addColorStop(1, 'rgba(0, 255, 0, 0)');
            datasets = [{
                label: analyticsFilter["activeUsers"]?.['title'],
                data: updatedGraphData?.map(data => data["activeUsers"]),
                borderWidth: 3,
                pointHoverRadius: 4,
                fill: true,
                backgroundColor: gradient,
                borderColor: '#00ff00',
                pointBorderColor: "transparent",
                pointBackgroundColor: '#00ff00',
                pointHoverBackgroundColor: '#00ff00',
                yAxisID: 'y',
            }]
          }

          if(activeData === "newUsers"){
            datasets = [{
                label: analyticsFilter["newUsers"]?.['title'],
                data: updatedGraphData?.map(data => data["newUsers"]),
                borderWidth: 3,
                pointHoverRadius: 4,
                fill: true,
                backgroundColor: gradient,
                borderColor: '#5485ED',
                pointBorderColor: "transparent",
                pointBackgroundColor: '#5485ED',
                pointHoverBackgroundColor: '#5485ED',
                yAxisID: 'y',
            }]
          }

          if(activeData === "free"){
            datasets = [{
              label: analyticsFilter["free"]?.['title'],
              data: updatedGraphData?.map(data => data["freeUsers"]),
              borderWidth: 3,
              pointHoverRadius: 4,
              fill: true,
              backgroundColor: gradient,
              borderColor: '#808080',
              pointBorderColor: "transparent",
              pointBackgroundColor: 'rgb(128,128,128)',
              pointHoverBackgroundColor: 'rgb(128,128,128)',
              yAxisID: 'y',
            }]
            datasets.push({
              label: analyticsFilter["unlimitedUsers"]?.['title'],
              data: updatedGraphData?.map(data => data["unlimitedUsers"]),
              borderWidth: 3,
              pointHoverRadius: 4,
              fill: true,
              backgroundColor: gradient,
              borderColor: '#0000ff',
              pointBorderColor: "transparent",
              pointBackgroundColor: 'rgba(0, 0, 255,0.2)',
              pointHoverBackgroundColor: 'rgb(0, 0, 255)',
              yAxisID: 'yComparison',
            })
            datasets.push({
              label: analyticsFilter["personalAssistantUsers"]?.['title'],
              data: updatedGraphData?.map(data => data["personalAssistantUsers"]),
              borderWidth: 3,
              pointHoverRadius: 4,
              fill: true,
              backgroundColor: gradient,
              borderColor: '#0000ff',
              pointBorderColor: "transparent",
              pointBackgroundColor: 'rgba(0, 0, 255,0.2)',
              pointHoverBackgroundColor: '#0000ff',
              yAxisID: 'yComparison',
            })
          }
          if(activeData === "newPages"){
              datasets = [{
                label: analyticsFilter["newPages"]?.['title'],
                data: updatedGraphData?.map(data => data["newPages"]),
                borderWidth: 3,
                pointHoverRadius: 4,
                fill: true,
                backgroundColor: gradient,
                borderColor: '#87ceeb',
                pointBorderColor: "transparent",
                pointBackgroundColor: '#87ceeb',
                pointHoverBackgroundColor: '#87ceeb',
                yAxisID: 'y',
              }]
              datasets.push({
                label: analyticsFilter["newPublications"]?.['title'],
                data: updatedGraphData?.map(data => data["newPublications"]),
                borderWidth: 3,
                pointHoverRadius: 4,
                fill: true,
                backgroundColor: gradient,
                borderColor: '#a52a2a',
                pointBorderColor: "transparent",
                pointBackgroundColor: '#a52a2a',
                pointHoverBackgroundColor: '#a52a2a',
                yAxisID: 'yComparison',
              })
              datasets.push({
                label: analyticsFilter["newNewsletters"]?.['title'],
                data: updatedGraphData?.map(data => data["newNewsletters"]),
                borderWidth: 3,
                pointHoverRadius: 4,
                fill: true,
                backgroundColor: gradient,
                borderColor: '#B1B419',
                pointBorderColor: "transparent",
                pointBackgroundColor: '#B1B419',
                pointHoverBackgroundColor: '#B1B419',
                yAxisID: 'yComparison',
              })
          }
          if(activeData === "deletedUsers"){
            analyticsFilter["deletedUsers"]?.['title'] && gradient?.addColorStop(0, 'rgba(255, 0, 0, 0.2)');
            analyticsFilter["deletedUsers"]?.['title'] && gradient?.addColorStop(1, 'rgba(255, 0, 0, 0)');
              datasets = [{
                label: analyticsFilter["deletedUsers"]?.['title'],
                data: updatedGraphData?.map(data => data["deletedUsers"]),
                borderWidth: 3,
                pointHoverRadius: 4,
                fill: true,
                backgroundColor: gradient,
                borderColor: '#ff0000',
                pointBorderColor: "transparent",
                pointBackgroundColor: '#ff0000',
                pointHoverBackgroundColor: '#ff0000',
                yAxisID: 'y',
              }]
              datasets.push({
                label: analyticsFilter["deletedUsers"]?.['title'],
                data: updatedGraphData?.map(data => data["deactivatedUsers"]),
                borderWidth: 3,
                pointHoverRadius: 4,
                fill: true,
                backgroundColor: gradient,
                borderColor: '#ff0000',
                pointBorderColor: "transparent",
                pointBackgroundColor: '#ff0000',
                pointHoverBackgroundColor: '#ff0000',
                yAxisID: 'yComparison',
            })
          }
          
          if( showCompare ) {
            if(activeData === "activeUsers"){
              gradient?.addColorStop(0, 'rgba(0, 255, 0, 0.2)');
              gradient?.addColorStop(1, 'rgba(0, 255, 0, 0)');
              datasets.push({
                  label: analyticsFilter["activeUsers"]?.['title'],
                  data: compareData?.map(data => data["activeUsers"]),
                  borderWidth: 3,
                  pointHoverRadius: 4,
                  fill: true,
                  backgroundColor: gradient,
                  borderColor: 'rgba(0, 255, 0, 0.5)',
                  pointBorderColor: "transparent",
                  pointBackgroundColor: '#00ff00',
                  pointHoverBackgroundColor: '#00ff00',
                  yAxisID: 'yComparison',
              })
            }
            if(activeData === "newUsers"){
              datasets.push({
                  label: analyticsFilter["newUsers"]?.['title'],
                  data: compareData?.map(data => data["newUsers"]),
                  borderWidth: 3,
                  pointHoverRadius: 4,
                  fill: true,
                  backgroundColor: gradient,
                  borderColor: 'rgba(84, 133, 237, 0.5)',
                  pointBorderColor: "transparent",
                  pointBackgroundColor: '#5485ED',
                  pointHoverBackgroundColor: '#5485ED',
                  yAxisID: 'yComparison',
              })
            }
            if(activeData === "free"){
              datasets.push({
                label: analyticsFilter["free"]?.['title'],
                data: compareData?.map(data => data["freeUsers"]),
                borderWidth: 3,
                pointHoverRadius: 4,
                fill: true,
                backgroundColor: gradient,
                borderColor: 'rgba(128, 128, 128, 0.5)',
                pointBorderColor: "transparent",
                pointBackgroundColor: 'rgb(128,128,128)',
                pointHoverBackgroundColor: 'rgb(128,128,128)',
                yAxisID: 'yComparison',
              })
              datasets.push({
                  label: analyticsFilter["unlimitedUsers"]?.['title'],
                  data: compareData?.map(data => data["unlimitedUsers"]),
                  borderWidth: 3,
                  pointHoverRadius: 4,
                  fill: true,
                  backgroundColor: gradient,
                  borderColor: 'rgba(0, 0, 255, 0.5)',
                  pointBorderColor: "transparent",
                  pointBackgroundColor: 'rgba(0, 0, 255,0.2)',
                  pointHoverBackgroundColor: 'rgb(0, 0, 255)',
                  yAxisID: 'yComparison',
              })
              datasets.push({
                  label: analyticsFilter["personalAssistantUsers"]?.['title'],
                  data: compareData?.map(data => data["personalAssistantUsers"]),
                  borderWidth: 3,
                  pointHoverRadius: 4,
                  fill: true,
                  backgroundColor: gradient,
                  borderColor: 'rgba(0, 0, 255, 0.5)',
                  pointBorderColor: "transparent",
                  pointBackgroundColor: 'rgba(0, 0, 255,0.2)',
                  pointHoverBackgroundColor: '#0000ff',
                  yAxisID: 'yComparison',
              })
            }
            if(activeData === "newPages"){
              datasets.push({
                label: analyticsFilter["newPages"]?.['title'],
                data: compareData?.map(data => data["newPages"]),
                borderWidth: 3,
                pointHoverRadius: 4,
                fill: true,
                backgroundColor: gradient,
                borderColor: 'rgba(135, 206, 235, 0.5)',
                pointBorderColor: "transparent",
                pointBackgroundColor: '#87ceeb',
                pointHoverBackgroundColor: '#87ceeb',
                yAxisID: 'yComparison',
              })
              datasets.push({
                  label: analyticsFilter["newPublications"]?.['title'],
                  data: compareData?.map(data => data["newPublications"]),
                  borderWidth: 3,
                  pointHoverRadius: 4,
                  fill: true,
                  backgroundColor: gradient,
                  borderColor: 'rgba(165, 42, 42, 0.5)',
                  pointBorderColor: "transparent",
                  pointBackgroundColor: '#a52a2a',
                  pointHoverBackgroundColor: '#a52a2a',
                  yAxisID: 'yComparison',
              })
              datasets.push({
                  label: analyticsFilter["newNewsletters"]?.['title'],
                  data: compareData?.map(data => data["newNewsletters"]),
                  borderWidth: 3,
                  pointHoverRadius: 4,
                  fill: true,
                  backgroundColor: gradient,
                  borderColor: 'rgba(177, 180, 25, 0.5)',
                  pointBorderColor: "transparent",
                  pointBackgroundColor: '#B1B419',
                  pointHoverBackgroundColor: '#B1B419',
                  yAxisID: 'yComparison',
              })
            }
            if(activeData === "deletedUsers"){
              analyticsFilter["deletedUsers"]?.['title'] && gradient?.addColorStop(0, 'rgba(255, 0, 0, 0.2)');
              analyticsFilter["deletedUsers"]?.['title'] && gradient?.addColorStop(1, 'rgba(255, 0, 0, 0)');
              datasets.push({
                label: analyticsFilter["deletedUsers"]?.['title'],
                data: compareData?.map(data => data["deletedUsers"]),
                borderWidth: 3,
                pointHoverRadius: 4,
                fill: true,
                backgroundColor: gradient,
                borderColor: 'rgba(255, 0, 0, 0.5)',
                pointBorderColor: "transparent",
                pointBackgroundColor: '#ff0000',
                pointHoverBackgroundColor: '#ff0000',
                yAxisID: 'yComparison',
              })
              datasets.push({
                  label: analyticsFilter["deletedUsers"]?.['title'],
                  data: compareData?.map(data => data["deactivatedUsers"]),
                  borderWidth: 3,
                  pointHoverRadius: 4,
                  fill: true,
                  backgroundColor: gradient,
                  borderColor: 'rgba(255, 0, 0, 0.5)',
                  pointBorderColor: "transparent",
                  pointBackgroundColor: '#ff0000',
                  pointHoverBackgroundColor: '#ff0000',
                  yAxisID: 'yComparison',
              })
            }
          }
          // Dummy data
          const data = {
            labels: updatedGraphData.map(data => dateFormatter(data.date, selectedPeriod.value === 'day' ? 'hour' : 'date', '', false)),
            datasets: datasets
          };
          // Create the chart
          const myChart = new Chart(ctx, {
            type: 'line',
            data: data,
            options: {
              animation: false,
              plugins: {
                legend: { display: false },
                tooltip: {
                  enabled: false,
                  mode: 'index',
                  intersect: false,
                  position: 'average',
                  external: GraphTooltip()
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              elements: { line: { tension: 0 }, point: { radius: 0 } },
              onClick: handleClick,
              scale: {
                ticks: { precision: 0, maxTicksLimit: 8 }
              },
              scales: {
                y: {
                  min: 0,
                  suggestedMax: calculateMaximumY(datasets),
                  ticks: {
                    callback: function (value, index, values) {
                      return value;
                    }
                  },
                  border: {
                    display: false
                  },
                  grid: {
                    zeroLineColor: 'transparent',
                    drawBorder: false,
                  }
                },
                yComparison: {
                  min: 0,
                  display: false,
                  suggestedMax: calculateMaximumY(datasets),
                  grid: { display: false },
                },
                x: {
                  grid: { display: false },
                }
              },
              interaction: {
                mode: 'index',
                intersect: false,
              }
            },
          });
    
          window.addEventListener('mousemove', repositionTooltip);
    
          function handleClick(event) {
            if (selectedPeriod.value !== 'day') {
              const canvasPosition = getRelativePosition(event, myChart);
    
              const dataX = myChart.scales.x.getValueForPixel(canvasPosition.x);
              
              const data = updatedGraphData?.[dataX]
              const compareDate = compareData?.[dataX];
              const formatedDate = dateFormatter(data.date, 'date', '');
              setPreviousDate(compareDate?.date || false);
              setSelectedDate(data.date);
              setSelectedPeriod({ value: "day", label: formatedDate })
              setTempPeriod({ value: "day", label: formatedDate })
            }
          }
          return () => {
            // Cleanup chart on unmount
            myChart.destroy();
          };
    
        }
  
      }, [graphData, activeData]);

    const analyticsFilter = {
      activeUsers: {
        title: 'Active Users'
      },
      dailyActiveUsers: {
        title: 'Daily Active Users'
      },
      newUsers: {
        title: 'New Users'
      },
      deletedUsers: {
        title: 'Deleted Users'
      },
      deactivatedUsers: {
        title: 'Deactivated Users'
      },
      free: {
        title: 'Free'
      },
      unlimitedUsers:{
        title: 'Unlimited Plan'
      },
      personalAssistantUsers:{
        title: 'Personal Assistant'
      },
      newPages:{
        title: 'New Pages'
      },
      newPublications:{
        title: 'New Publications'
      },
      newNewsletters:{
        title: 'New Newsletters'
      },
    }

    const calculateMaximumY = function (dataset) {
        const yAxisValues = dataset?.flatMap((item) => item?.data)
          .map((item) => item || 0)
    
        if (yAxisValues) {
          return Math.max(...yAxisValues)
        } else {
          return 1
        }
      }
    
    const GraphTooltip = () => {
  
        return (context) => {
          const tooltipModel = context.tooltip;
          const offset = document.getElementById("main-graph-canvas").getBoundingClientRect();
          let tooltipEl = document.getElementById('chartjs-tooltip');
    
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.display = 'none';
            tooltipEl.style.opacity = 0;
            document.body.appendChild(tooltipEl);
          }
    
          if (tooltipEl && offset && window.innerWidth < 768) {
            tooltipEl.style.top = offset.y + offset.height + window.scrollY + 15 + 'px'
            tooltipEl.style.left = offset.x + 'px'
            tooltipEl.style.right = null
            tooltipEl.style.opacity = 1
          }
    
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.display = 'none';
            return;
          }
    
          const data = tooltipModel.dataPoints.find((dataPoint) => dataPoint.dataset.yAxisID == "y");
          const value = data?.raw || 0 ;
          const formattedValue = tooltipModel.dataPoints?.map((item) => {
            return item?.formattedValue
          });
          const updatedData = graphData[data?.dataIndex];
          const formatedDate = dateFormatter(updatedData?.date, 'date', '');
          const formatedTime = dateFormatter(updatedData?.date, 'hour', '');
          const date = `${formatedDate}${selectedPeriod.value === 'day' ? `, ${formatedTime}` : ''}`;
    
          const comparisonData = showCompare ? tooltipModel?.dataPoints?.find((dataPoint) => dataPoint?.dataset?.yAxisID == "yComparison") : {};
          const isCompareIndex = comparisonData && comparisonData.hasOwnProperty('dataIndex')
        
          const comparisonValue =  comparisonData?.raw || 0;
        
          
          const comparisonUpdatedData = showCompare && isCompareIndex ? compareData[comparisonData.dataIndex] : {};
          
          const comparisonFormatedDate = showCompare && isCompareIndex? dateFormatter(comparisonUpdatedData?.date, 'date', '') : '';
          const comparisonFormatedTime = showCompare && isCompareIndex ? dateFormatter(comparisonUpdatedData?.date, 'hour', '') : '';
          const comparisonDate = showCompare && isCompareIndex ? `${comparisonFormatedDate}${selectedPeriod.value === 'day' ? `, ${comparisonFormatedTime}` : ''}` : '';
          
          if (tooltipModel.body) {
            const comparisonTooltip = showCompare && isCompareIndex ? `<div class="tooltip-subtitle">
            <div class="${(activeData === "activeUsers") ? "user-mark" : "newUser-mark"} tooltip-mark"></div>${comparisonDate} <div class="tooltip-value">${(activeData === "activeUsers") ? (comparisonValue) : comparisonValue >0 ? comparisonValue : 0}</div></div>` : '';
    
            tooltipEl.innerHTML = `
              <div class="grapph-tooltip">
                ${(activeData === 'free') ? ` 
                    <div class="tooltip-title">${date}</div>
                    <div class= tooltip-data ><div class="tooltip-free tooltip-mark"></div> 
                                                ${data?.dataset?.label} <div class="tooltip-value">${formattedValue?.[0]}</div> </div>
                                                <div class= tooltip-data ><div class="tooltip-unlimited tooltip-mark"></div>
                                                ${`Unlimited Plan`}<div class="tooltip-value">${formattedValue?.[1]}</div></div>
                                                <div class= tooltip-data ><div class="tooltip-PA tooltip-mark"></div> 
                                                ${`Personal Assistant`} <div class="tooltip-value">${formattedValue?.[2]}</div> </div>
                                                </div>
                                            </div>
                ${showCompare ?  
                  `<div class="tooltip-title">${comparisonDate}</div>
                    <div class= tooltip-data ><div class="tooltip-mark compare-free"></div> 
                                                ${data?.dataset?.label} <div class="tooltip-value">${formattedValue?.[3]}</div></div>
                                                <div class= tooltip-data ><div class="tooltip-mark compare-unlimited"></div>
                                                ${`Unlimited Plan`} <div class="tooltip-value">${formattedValue?.[4]}</div> </div>
                                                <div class= tooltip-data ><div class="tooltip-mark compare-pa"></div> 
                                                ${`Personal Assistant`} <div class="tooltip-value">${formattedValue?.[5]}</div> </div>
                                                </div>
                                            </div>` :""
                }` 
                :
                    `${(activeData === 'newPages') ? `
                                            <div class="tooltip-title">${date}</div>
                                            <div class= tooltip-data ><div class="tooltip-pages tooltip-mark" ></div> 
                                                ${data?.dataset?.label}  <div class="tooltip-value">${formattedValue?.[0]}</div> </div>
                                                <div class= tooltip-data ><div class="tooltip-publication tooltip-mark"></div>
                                                ${`New Publications`} <div class="tooltip-value">${formattedValue?.[1]}</div> </div>
                                                <div class= tooltip-data ><div class="tooltip-newsletter tooltip-mark"></div> 
                                                ${`New Newsletters`}<div class="tooltip-value"> ${formattedValue?.[2]}</div> </div>
                                                </div>
                                            </div>
                              ${showCompare ? 
                                `<div class="tooltip-title">${comparisonDate}</div>
                                  <div class= tooltip-data ><div class="tooltip-mark compare-page" ></div> 
                                                              ${data?.dataset?.label} <div class="tooltip-value">${formattedValue?.[3]}</div> </div>
                                                              <div class= tooltip-data ><div class="tooltip-mark compare-publication"></div>
                                                              ${`Unlimited Plan`} <div class="tooltip-value">${formattedValue?.[4]}</div> </div>
                                                              <div class= tooltip-data ><div class="tooltip-mark compare-newsletter"></div> 
                                                              ${`Personal Assistant`}<div class="tooltip-value"> ${formattedValue?.[5]}</div> </div>
                                                              </div>
                                                          </div>` : ""
                                }` 
                    : 
                    `${(activeData === "deletedUsers")? `
                                            <div class="tooltip-title">${date}</div>
                                            <div class= tooltip-data ><div class="tooltip-delete tooltip-mark"></div> 
                                                ${data?.dataset?.label} <div class="tooltip-value">${formattedValue?.[0]}</div> </div>
                                                <div class= tooltip-data ><div class="tooltip-delete tooltip-mark"></div>
                                                ${`Deactivated Users`} <div class="tooltip-value">${formattedValue?.[1]}</div> </div>
                                                </div>
                                            </div>
                          ${showCompare ?  
                            `<div class="tooltip-title">${comparisonDate}</div>
                                            <div class= tooltip-data ><div class="tooltip-mark compare-delete" ></div> 
                                                ${data?.dataset?.label} <div class="tooltip-value">${formattedValue?.[2]}</div> </div>
                                                <div class= tooltip-data ><div class="tooltip-mark compare-delete"></div>
                                                ${`Deactivated Users`} <div class="tooltip-value">${formattedValue?.[3]}</div> </div>
                                                </div>
                              </div>` : ""
                          }` 
                    : `<div class="tooltip-title">${data?.dataset?.label}</div>
                                            <div class= tooltip-data ><div class= "${(activeData === "activeUsers")? 'activeUser' : (activeData === "dailyActiveUsers")? 'dailyActiveUsers' : 'newUser' } tooltip-mark "></div> 
                                                ${date} <div class="tooltip-value">${value} </div></div>
                        ${showCompare ? comparisonTooltip : ''}
                                            ` }` }`
                } 

              </div>
            `;
          }
          tooltipEl.style.display = null;
        };
    };


    const repositionTooltip = (e) => {
        const tooltipEl = document.getElementById('chartjs-tooltip');
        if (tooltipEl && window.innerWidth >= 768) {
          if (e.clientX > 0.66 * window.innerWidth) {
            tooltipEl.style.right = (window.innerWidth - e.clientX) + window.pageXOffset + 'px';
            tooltipEl.style.left = null;
          } else {
            tooltipEl.style.right = null;
            tooltipEl.style.left = e.clientX + window.pageXOffset + 'px';
          }
          tooltipEl.style.top = e.clientY + window.pageYOffset + 'px';
          tooltipEl.style.opacity = 1;
        }
      };


    return (
        <>
            <div className="dashboardGraph">                
                <div className="dashboardGraph analyticsCharts">
                {isLoading ?
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                    :
                    <div className="dashboardChart">
                        <div className="visitor-grreapoh">
                            <canvas id="main-graph-canvas" ref={chartRef} width="400" height="400"></canvas>
                        </div>
                    </div>
                }
                </div>
            </div>
        </>
    );
};

export default DashboardGraph;
