import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import {formattedDate} from '../../utils/functions';
import NoResultsInvoice from "./NoResultsInvoices";

import down from "../../images/down-arrow.svg";
import top from "../../images/arrow_top.svg";
import paid from "../../images/paid.svg";
import unpaid from "../../images/unpaid.svg";

const Subscription = (props) => {
    const { id } = useParams();
      
    const [invoices, setInvoices] = useState('');
    
    const [numberSort, setNumberSort] = useState(false);
    const [dateSort, setDateSort] = useState(false);
    const [statusSort, setStatusSort] = useState(false);
    const [AmountSort, setAmountSort] = useState(false);
    const [PlanSort, setPlanSort] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    function sortByNumber() {
        var result;

        if (numberSort === true) {
            result = [...invoices].sort((a, b) => (a.invoiceNumber > b.invoiceNumber ? 1 : -1));
        } else {
            result = [...invoices].sort((a, b) => (a.invoiceNumber > b.invoiceNumber ? -1 : 1));
        }
        setInvoices(result);
    }

    function sortByDate() {
        var result = [];

        if (dateSort === false) {
            result = [...invoices].sort(function (a, b) {
                return a.date.localeCompare(b.date);
            });
        } else {
            result = [...invoices].sort(function (a, b) {
                return b.date.localeCompare(a.date);
            });
        }
        setInvoices(result);
    }

    function sortByStatus(a, b) {
        if (statusSort === false) {
            setInvoices(
                invoices.sort((a, b) => (a.status > b.status ? 1 : -1))
            );
            setStatusSort(false);
        } else {
            setInvoices(
                invoices.sort((a, b) => (a.status > b.status ? -1 : 1))
            );
            setStatusSort(true);
        }
    }

    function sortByAmount() {
        var result;
        if (AmountSort === true) {
            result = [...invoices].sort(
                (a, b) =>
                Number(a.amount) - Number(b.amount)
            );
        } else {
            result = [...invoices].sort(
                (a, b) =>
                -1 * Number(a.amount) - Number(b.amount)
            );
        }
        setInvoices(result);
    }

    function sortByPlan() {

        if (PlanSort === false) {
            setInvoices(
                invoices.sort((a, b) => (a.planName > b.planName ? 1 : -1))
            );
            setPlanSort(false);
        } else {
            setInvoices(
                invoices.sort((a, b) => (a.planName > b.planName ? -1 : 1))
            );
            setPlanSort(true);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(false);
        try {
            setIsLoading(true)
            const invocesResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/getInvoices/${id}`,
            );
            
            setInvoices(invocesResponse.data.result);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "error");
        }
    }  

    return (
        <>
            <div className="subscription-item">
                <h2 className="table-heading">Invoices</h2>
                <div className="table-data-wrapper">
                    <div className="table-data">
                        <div className="table-head subscription-head">
                            <div>
                                <p className="table-title" onClick={(e) => { sortByNumber(); setNumberSort(!numberSort); }} > Number </p>
                                <img src={numberSort === true ? top : down} alt="Icon" onClick={(e) => { sortByNumber(); setNumberSort(!numberSort); }}/>
                            </div>
                            <div onClick={(e) => { sortByDate(); setDateSort(!dateSort); }} >
                                <p className="table-title">Date</p>
                                <img src={dateSort === true ? top : down} alt="Icon" />
                            </div>
                            <div onClick={(e) => { sortByStatus(); setStatusSort(!statusSort); }} >
                                <p className="table-title textmt">Status</p>
                                <img src={statusSort === true ? top : down} alt="Icon" />
                            </div>
                            <div onClick={(e) => { sortByAmount(); setAmountSort(!AmountSort); }}>
                                <p className="table-title">Amount</p>
                                <img src={AmountSort === true ? top : down} alt="Icon" />
                            </div>
                            <div onClick={(e) => { sortByPlan(); setPlanSort(!PlanSort); }}>
                                <p className="table-title">Plan</p>
                                <img src={PlanSort === true ? top : down} alt="Icon" />
                            </div>
                            <div>
                                <p className="table-title">Download</p>
                            </div>
                        </div>
                        {isLoading ?
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                            :
                            (invoices && invoices.length > 0) ?
                                invoices.map((item, index) => (
                                    <div className="table-item subscription-table-item">
                                        <div className="table-invoice"> 
                                            {item.invoiceNumber} 
                                        </div>
                                        <div className="table-date">
                                            {formattedDate(item.date, 'date', '', false, true)}
                                        </div>
                                        <div className="table-status">
                                            <img className='statusImage' src={item.status === "paid" ? paid :unpaid} alt="Icon" />
                                        </div>
                                        <div className="table-amount">
                                            ${item.amount} USD
                                        </div>
                                        <div className="table-plan">
                                            {item.planName}
                                        </div>
                                        <div className="table-download">
                                            <a className='download-link' href={item.downloadLink} download="download">Download</a>
                                        </div>
                                    </div>
                                )) : (
                                    <NoResultsInvoice />
                                )
                        }
                    </div>
                </div>
            </div>
        </>    
    );
};

export default Subscription;
