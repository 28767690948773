import '../../App.css';
import noResults from '../../images/noResults.svg';
import plus from '../../images/plus.svg';
import { Link } from 'react-router-dom';

function NoResults(props) {
    const buttonTo = props.type === 'categories' ? '#' : `/dashboard/${props?.type}`;
    const clickEventHandler = props?.onclickhandle || null;

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">No {props?.name} found</p>
                <p className='noResDesc'>Your search “Landing page design” did not match any projects. Please try again.</p>
                <button className='noResButton'>
                    <img src={plus} alt="Plus" />
                    <Link className='editTitle' to={buttonTo} onClick={() => { clickEventHandler && clickEventHandler() }}>
                        Add new {props?.name}
                    </Link>
                </button>
            </div>
        </div>
    );
}

export default NoResults;
