import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DatePicker from "../../components/DatePicker";
import DashboardReport from "./DashboardReport";
import DashboardGraph from "./DashboardGraph";
import DashboardNewUserTable from "./DashboardNewUserTable";

import {isUserAdmin} from "../../utils/functions";
import dateFormatter, { formatISO, getCompareDate, today, currentMonth, currentYear, getDateRangeOfMonth, getDateRangeOfYear, firstDateOfLastMonth, lastDateOfLastMonth, firstDateOfYear, lastDateOfYear, getNextDate, getPrevDate } from "../../utils/dateFormatter";

import down from '../../images/down.svg'
import "./TableData.css";


const Dashboard = () => {

  const [activeData, setActiveData] = useState('activeUsers');
  const [showPopup, setShowPopup] = useState(false);
  const [showPeriods, setShowPeriods] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showCompareDatePicker, setShowCompareDatePicker] = useState(false);
  const [customDates, setCustomDates] = useState({ startDate: '', endDate: '' });
  const [compareCustomDates, setCompareCustomDates] = useState({ startDate: '', endDate: '' });
  const [customDate, setCustomDate] = useState('');
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [selectedPeriodLbl, setSelectedPeriodLbl] = useState('Last 30 Days');
  const [selectedComapreLbl, setSelectedComapreLbl] = useState('Previous period');
  const [value, setValue] = useState({
    activeUser: 0,
    dailyActiveUsers: 0,
    newUser: 0,
    deletedUsers: 0,
    deactivatedUser: 0,
    freePlanUser: 0,
    unlimitedPlanUser: 0,
    personalAssistantUsers: 0,
    newPages: 0,
    newPublications: 0,
    newNewsletters: 0,
});

const [change, setChange] = useState({
    activeUserRate: 0,
    dailyActiveUsers: 0,
    newUserRate: 0,
    deletedUsersRate: 0,
    deactivatedUserRate: 0,
    freePlanUserRate: 0,
    unlimitedPlanUserRate: 0,
    personalAssistantUsersRate: 0,
    newPagesRate: 0,
    newPublicationsRate: 0,
    newNewslettersRate: 0,
});
const [compareCount, setCompareCount] = useState({
    activeUserCompare: 0,
    dailyActiveUsers: 0,
    newUserCompare: 0,
    deletedUsersCompare: 0,
    deactivatedUserCompare: 0,
    freePlanUserCompare: 0,
    unlimitedPlanUserCompare: 0,
    personalAssistantUsersCompare: 0,
    newPagesCompare: 0,
    newPublicationsCompare: 0,
    newNewslettersCompare: 0,
})
  const getCurrentDate = () => format(new Date(), "MMMM d, yyyy");
  // State for managing selected date range
  const [dateValue, setDateValue] = useState(
    getCurrentDate() + " - " + getCurrentDate()
  );

  // Function to handle date value change
  const handleDateChange = (data) => {
    setDateValue(data);
  };

  const [nextDateDisable, setNextDateDisable] = useState(true);
  const [prevDateDisable, setPrevDateDisable] = useState(false);

  var startDate = format(new Date(), "MMMM d, yyyy");
  var endDate = format(new Date(), "MMMM d, yyyy");

  const periods = [
    { value: "day", label: "Today" },
    { value: "7d", label: "Last 7 Days" },
    { value: "30d", label: "Last 30 Days" },
    { value: "month", label: "Month to Date" },
    { value: "last_month", label: "Last Month" },
    { value: "year", label: "Year to Date" },
    { value: "12mo", label: "Last 12 Months" },
    { value: "all", label: "All Time" },
    { value: "custom", label: "Custom Range" },
    { value: "compare", label: "Compare" }
  ];
  const comparePeriod = [
    { value: "disable", label: "Disable comparison" },
    { value: "previous_period", label: "Previous period" },
    { value: "year_over_year", label: "Year over year" },
    { value: "custom_period", label: "Custom period" }
  ];
  const ref = useRef();
  const [selectedPeriod, setSelectedPeriod] = useState(periods[2]); // Default to "Last 30 Days"
  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [tempPeriod, setTempPeriod] = useState(periods[2]);
  const [previousDate, setPreviousDate] = useState(getCompareDate(today, '30d', 'previous_period'));

  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filtersData, setFiltersData] = useState({ page: '', browser: '', browserVersion: '', os: '', osVersion: '', size: '', country: '', region: '', city: '' });
  const [apiFilter, setapiFilter] = useState('');

  const [showCompare, setShowCompare] = useState(false);
  const [showCompareDropdown, setShowCompareDropdown] = useState(false);
  const [selectedComaprePeriod, setSelectedComaprePeriod] = useState(comparePeriod[1]);
  const [selectedComapreValue, setSelectedComapreValue] = useState({ label: '', value: '' });
  const [datePickerValue, setDatePickerValue] = useState(startDate + " - " + endDate);
  
  const userinfo = JSON.parse(localStorage.getItem("userData"));
  const [isLoading, setIsLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [label, setLabel] = useState('');
  const [compareData, setCompareData] = useState([])

  const [userData, setUserData] = useState(userinfo);
  const datevalue = (data) => {
    setDatePickerValue(data)
  }

  const popupRef = useRef(null);
  const periodsRef = useRef(null);
  const filterRef = useRef(null);
  const customRef = useRef(null);

  // Close the popup when clicking outside of it
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }

      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilters(false);
      }

      if (periodsRef.current && !periodsRef.current.contains(event.target)) {
        setShowPeriods(false);
        setShowDatePicker(false);
      }
      if (customRef.current && !customRef.current.contains(event.target)) {
        setShowCompareDropdown(false);
        setShowCompareDatePicker(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);
  
  const fetchData = async (filterData = '', filtersData = {}) => {
    try {
      let setPeriod = selectedPeriod?.value || '30d';

      
      if (setPeriod === 'custom' && (customDates.startDate == '' || customDates.endDate == '')) {
        return false;
      }

      if (setPeriod === 'compare') {
        setPeriod = tempPeriod?.value || '30d';
      }

      let date = today;
      let compareDate = getCompareDate(today, setPeriod, selectedComaprePeriod.value);

      if (showCompare) {
        if ( selectedComaprePeriod.value === 'custom_period' && (compareCustomDates.startDate == '' || compareCustomDates.endDate == '')) {
          return false;
        }
        
        setPeriod = tempPeriod?.value || '30d';
      }

      const createdAtDate = formatISO(JSON.parse(localStorage.getItem("userData"))?.createdAt || today);
      compareDate = getCompareDate(today, setPeriod, selectedComaprePeriod.value, compareCustomDates);

      if (setPeriod === 'day') {
        setPeriod = 'day';
        date = selectedDate;
        compareDate = previousDate || getCompareDate(selectedDate, setPeriod, selectedComaprePeriod.value);
      } else if (setPeriod === 'month') {
        date = `${customDates.startDate},${customDates.endDate}`;
        compareDate = getCompareDate(customDates.startDate, setPeriod, selectedComaprePeriod.value);
        setPeriod = 'custom';
      } else if (setPeriod === 'last_month') {
        date = `${firstDateOfLastMonth},${lastDateOfLastMonth}`;
        compareDate = getCompareDate(firstDateOfLastMonth, setPeriod, selectedComaprePeriod.value);
        setPeriod = 'custom';
      } else if (setPeriod === 'year') {
        date = `${customDates.startDate},${customDates.endDate}`;
        compareDate = getCompareDate(customDates.startDate, setPeriod, selectedComaprePeriod.value);
        setPeriod = 'custom';
      } else if (setPeriod === 'all') {
        setPeriod = 'custom';
        date = `${createdAtDate},${today}`;
      } else if (setPeriod === 'custom') {
        setPeriod = 'custom';
        date = `${formatISO(customDates?.startDate)},${formatISO(customDates?.endDate)}`;
        compareDate = getCompareDate(`${formatISO(customDates?.startDate)},${formatISO(customDates?.endDate)}`, setPeriod, selectedComaprePeriod.value,compareCustomDates);
      }

      const period = setPeriod;

      setapiFilter(filterData);
      setFiltersData(filtersData);
      setIsLoading(true);
      
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getAnalytics?filters=${filterData}&period=${period}&date=${date}&compare=${showCompare}&compareDate=${compareDate}`,
      )

      const result = response?.data?.result;

      setAnalyticsData(result);
      setCompareData(result?.graphDataCompare);      
      setIsLoading(false);

      //setAllFiltered(response?.data?.result);
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
    }
  }
  useEffect(() => {
    const createdAtDate = formatISO(JSON.parse(localStorage.getItem("userData"))?.createdAt || today);
    const createdAtYear = formatISO(JSON.parse(localStorage.getItem("userData"))?.createdAt || today, 'year');

    const lastDateOfPeriod = new Date('2024-01-01'); 

    const lastDateFormatted = dateFormatter(lastDateOfPeriod, 'date', '', true);

    if (selectedComaprePeriod.value == 'custom_period' && compareCustomDates.startDate !== '' && compareCustomDates.endDate !== '') {
      setSelectedComapreLbl(`${dateFormatter(compareCustomDates.startDate, 'date', '', false)} - ${dateFormatter(compareCustomDates.endDate, 'date', '', false)}`)
    } else if( selectedComaprePeriod.value === 'disable'){
      setSelectedComapreLbl('Previous period')
    } else{
        setSelectedComapreLbl(selectedComaprePeriod.label)
    }
    if (selectedPeriod.value === 'day') {
      setCustomDate(selectedDate);
      setNextDateDisable(false);
      setPrevDateDisable(false);

      setSelectedPeriodLbl(dateFormatter(selectedDate, 'date', '', true));

      if (dateFormatter(selectedDate, 'date', '', true) === lastDateFormatted) {
        setPrevDateDisable(true);
      }

      if (today === selectedDate) {
        setNextDateDisable(true);
      }
    } else if (selectedPeriod.value === 'month') {

    } else if (selectedPeriod.value === 'year') {
      setNextDateDisable(false);
      setPrevDateDisable(false);

      if (selectedYear == currentYear) {
        setNextDateDisable(true);
      }
      if (selectedYear == createdAtYear) {
        setPrevDateDisable(true);
      }
      if (createdAtYear === currentYear) {
        setNextDateDisable(true);
        setPrevDateDisable(true);
      }
      setSelectedPeriodLbl(`Year of ${selectedYear}`);
    } else if (selectedPeriod.value === 'custom' && customDates.startDate !== '' && customDates.endDate !== '') {
      setSelectedPeriodLbl(`${dateFormatter(customDates.startDate, 'date', '', false)} - ${dateFormatter(customDates.endDate, 'date', '', false)}`)
    } else {
      if (selectedPeriod.value !== 'compare') {
        setSelectedPeriodLbl(selectedPeriod.label)
      }
    }
    if (showDatePicker === false && showCompareDatePicker === false) {
      fetchData(apiFilter, filtersData);
    }

  }, [selectedPeriod, selectedDate, customDates, showCompare, selectedComaprePeriod, compareCustomDates]);


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (datePickerOpen && ref.current && !ref.current.contains(e.target)) {
        setDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [datePickerOpen]);

  const handlePrevDate = () => {
    if (!prevDateDisable) {

      if (selectedPeriod.value === 'day') {
        const prevDate = getPrevDate(selectedDate);

        setSelectedDate(prevDate);
        setCustomDate(prevDate);

        if (showCompare) {
          const comPrevDate = getPrevDate(previousDate);
          setPreviousDate(comPrevDate);
        }
      }

      if (selectedPeriod.value === 'month') {
        const createdMonth = formatISO(JSON.parse(localStorage.getItem("userData"))?.createdAt || today, 'month');
        const prevMonth = getPrevDate(selectedPeriodLbl, 'month');
        const monthRange = getDateRangeOfMonth(prevMonth);
        const lastMonthOfPeriod = new Date('2024-01-01'); // January 2024
        const lastMonthFormatted = dateFormatter(lastMonthOfPeriod, 'month', '', true);

        setCustomDates(monthRange);
        setSelectedPeriodLbl(prevMonth);
        setNextDateDisable(false);

        if (prevMonth === lastMonthFormatted) {
          setPrevDateDisable(true);
        }
      }

      if (selectedPeriod.value === 'year') {
        const prevYear = getPrevDate(selectedPeriodLbl, 'year');
        const yearRange = getDateRangeOfYear(`Year of ${prevYear}`);

        setSelectedYear(prevYear);
        setCustomDates(yearRange);
      }
    }
  };

  const handleNextDate = () => {
    if (!nextDateDisable) {
      if (selectedPeriod.value == 'day') {
        const nextDate = getNextDate(selectedDate);
        setSelectedDate(nextDate);
        setCustomDate(nextDate);
        if (showCompare) {
          const comNextDate = getNextDate(previousDate);
          setPreviousDate(comNextDate);
        }
      }

      if (selectedPeriod.value === 'month') {
        const nextMonth = getNextDate(selectedPeriodLbl, 'month');
        const monthRange = getDateRangeOfMonth(nextMonth);

        setCustomDates(monthRange);
        setSelectedPeriodLbl(nextMonth);
        setPrevDateDisable(false);

        if (nextMonth === currentMonth) {
          setNextDateDisable(true);
        }
      }

      if (selectedPeriod.value === 'year') {
        const nextYear = getNextDate(selectedPeriodLbl, 'year');
        const yearRange = getDateRangeOfYear(`Year of ${nextYear}`);

        setSelectedYear(nextYear)
        setCustomDates(yearRange);
      }
    }
  }
  const handleChangeComaprePeriod = (period) => {
    if (period.value === 'disable') {
      setShowCompare(false);
      setSelectedComaprePeriod(comparePeriod[1]);
    } else {
      setSelectedComaprePeriod(period);
    }
    setSelectedComapreValue(period)
    if (period.value === 'custom_period') {
      setShowCompareDatePicker(true);
    }
    setShowCompareDropdown(false);
  }

  const handleChangePeriod = (period) => {
    setShowPeriods(false);
    setSelectedPeriod(period);
    setCustomDate('');

    if (period.value !== 'compare') {
      setTempPeriod(period)
    }

    setNextDateDisable(true);
    setPrevDateDisable(true);

    if (period.value === 'custom') {
      setShowDatePicker(true)
    } else if (period.value === 'compare') {
      setShowCompare(!showCompare);
    } else {
      setSelectedPeriod(period);
    }

    if (period.value === 'day') {
      setCustomDate(today)
      setSelectedDate(today);
      setNextDateDisable(true);
      setPrevDateDisable(false);
      setSelectedPeriodLbl(dateFormatter(today, 'date', '', true))
    }

    if (period.value === 'month') {
      const monthRange = getDateRangeOfMonth(currentMonth);

      setSelectedPeriodLbl(currentMonth);
      setCustomDates(monthRange);
      setNextDateDisable(true);
      setPrevDateDisable(false);
    }

    if (period.value === 'year') {
      const yearRange = getDateRangeOfYear(`Year of ${currentYear}`);

      setSelectedYear(currentYear);
      setCustomDates(yearRange);

      setNextDateDisable(true);
      setPrevDateDisable(false);
    }

    if (period.value === 'all') {
      setShowCompare(false);
    }

    if (showCompare) {
      const comPrevDate = getCompareDate(today, period.value, selectedComaprePeriod.value);
      setPreviousDate(comPrevDate)

    }
  }
  
  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getLoginTeamData/${userinfo?._id}/`);
            // console.log(response.data.result)
            setUserData(response.data.result);
        } catch (error) {
            console.log(error);
        }
    };

    fetchData(); // Call the async function immediately
}, [userinfo?._id]);
  return (
    <div className="main-wrapper">
      {/* Header component */}
      <Header activePage="dashboard" userData={userData}/>

      <div className="main-content">
        <div className="content-wrapper">
          <div className="dashboard-top-wrapper">
            <div className="dashboard-top-left">
              <h2 className="dashboard-top-text">Hello, {userData?.firstName}</h2>
              <p className="dashboard-top-subtext">
                Track, manage and forecast your customers and orders.
              </p>
            </div>
            <div className="datepickerButton analytics" ref={ref}>
              {/* Button to toggle date picker visibility */}
              <div className="analyticsSelect">
                        {/* Arrow-section Show condition */}
                        {selectedPeriod &&
                            (selectedPeriod.value === "day" || selectedPeriod.value === "month" || selectedPeriod.value === "year") && (
                                <div className="arrow-section">
                                    <button className={prevDateDisable && 'disable'} onClick={handlePrevDate}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="15 18 9 12 15 6"></polyline></svg>
                                    </button>
                                    <button className={nextDateDisable && 'disable'} onClick={handleNextDate}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                    </button>
                                </div>
                            )}

                        <div className="period-selections datepickerButton" ref={periodsRef}>
                            <div className="selected-period" onClick={() => setShowPeriods(!showPeriods)} >{selectedPeriodLbl} <img src={down} alt={selectedPeriod.label} /></div>
                            <div className="periods-options selections-dropdown">
                                {showPeriods && periods.map((period) => (
                                    <div
                                        key={period.value}
                                        className={((selectedPeriod.value !== 'day' && selectedPeriod.value !== 'month' && selectedPeriod.value !== 'year') && ((showCompare && tempPeriod.value === period.value) || (!showCompare && ((selectedPeriod.value !== 'compare' && selectedPeriod.value === period.value) || selectedPeriod.value === 'compare' && tempPeriod.value === period.value)))) && `selected`}
                                        onClick={() => { handleChangePeriod(period) }}
                                    >
                                        {period.value === 'compare' && showCompare ? 'Disable comparison' : period.label}
                                    </div>
                                ))}

                                {
                                    showDatePicker && <DatePicker datevalue={datevalue} setShowDatePicker={setShowDatePicker} setCustomDates={setCustomDates} />
                                }

                            </div>
                        </div>

                        {
                            showCompare &&
                            <>
                                <span> vs. </span>
                                <div className="period-selections compare-period datepickerButton" ref={customRef}>
                                    <div className="selected-period" onClick={() => setShowCompareDropdown(!showCompareDropdown)} >{selectedComapreLbl} <img src={down} alr={selectedComapreLbl} /></div>
                                    <div className="periods-options selections-dropdown">
                                        {showCompareDropdown && comparePeriod.map(period => (
                                            <div
                                                key={period.value}
                                                className={selectedComaprePeriod.value === period.value ? 'selected' : ''}
                                                onClick={() => handleChangeComaprePeriod(period)}
                                            >
                                                {period.label}
                                            </div>
                                        ))}
                                        {showCompareDatePicker &&
                                            <DatePicker datevalue={datevalue} setShowDatePicker={setShowCompareDatePicker} setCustomDates={setCompareCustomDates} />
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
            </div>
          </div>
          {/* Dashboard components */}
          { isUserAdmin() && <DashboardReport setActiveData={setActiveData} activeData={activeData} analyticsData={analyticsData} setLabel={setLabel} value={value} setValue={setValue} change={change} setChange={setChange} showCompare={showCompare} setCompareCount={setCompareCount} compareCount={compareCount} compareData={compareData} /> }
          { isUserAdmin() && <DashboardGraph activeData={activeData} graphData={analyticsData.graphData} compareData={analyticsData?.graphDataCompare} isLoading={isLoading} selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} setSelectedDate={setSelectedDate} showCompare={showCompare} setTempPeriod={setTempPeriod} setPreviousDate={setPreviousDate}  label={label} valuename={value} change={change}/> }
          <DashboardNewUserTable />
        </div>
        {/* Footer component */}
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
