
import '../../App.css';
import noResults from '../../images/noResults.svg'

function NoResultsAssistantHistory(props) {

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">No Support History found</p>
                <p className='noResDesc'>Your search “Landing page design” did not match any projects. Please try again.</p>
            </div>
        </div>
    );
}

export default NoResultsAssistantHistory;
