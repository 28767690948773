import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React from "react";

import Login from "./Pages/Login/";
import Dashboard from "./Pages/Dashboard/";
import Users from "./Pages/Users/";
import Team from "./Pages/Team/";
import Setting from "./Pages/Settting";

import "./App.css";

function App() {
    // Check if user is authenticated based on the presence of userData in localStorage
    const isAuthenticated = !!localStorage.getItem("userData");

    return (
        <BrowserRouter>
            <Routes>
                {isAuthenticated ? (
                    // Routes accessible only to authenticated users
                    <>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/users/*" element={<Users />} />
                        <Route path="/team/*" element={<Team />} />
                        <Route path="/setting/*" element={<Setting />} />
                    </>
                ) : (
                    // Routes accessible only to non-authenticated users
                    <>
                        <Route path="*" element={<Navigate to="/login" />} />
                        <Route path="/login" element={<Login />} />
                    </>
                )}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
