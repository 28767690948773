import React from "react";
import { countries } from "./countries";
const CountryFlag = React.lazy(() => import('./CountryFlag'));

const getCountryWithFlag = (countryCode) => {
    if( countryCode == '' ) {
        return '-';
    }
    return (
        <>
            <CountryFlag countryCode={countryCode} />
            {getCountryName(countryCode)}
        </>
    );
};

function getCountryName(countryCode) {
    if( countryCode == '' ) {
        return '-';
    }
    return countries[countryCode];
}

const formattedDate = (date) => {
    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    var newDate = new Date(date);
    var formatteddate = newDate.toLocaleDateString('en-US', options);
    return formatteddate;
}

export const dataURLtoFile = async (dataurl, filename) => {
    const response = await fetch(dataurl);
    const blob = await response.blob();
    const file = new File([blob], filename, { type: 'image/jpeg' });

    return file;
};

const isUserAdmin = () => {
    let isAdmin = false;

    const userinfo = JSON.parse(localStorage.getItem("userData"));

    if( userinfo.role === 'admin' ) {
        isAdmin = true
    }

    return isAdmin;
}

export const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const getImageDimensions = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const dimensions = `${img.width}x${img.height}`;
        resolve(dimensions);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
};

export const formatNumber = (num) => {
    const absNum = Math.abs(num);

    if (absNum >= 1e9) {
        let formatted = (num / 1e9).toFixed(2);
        if (formatted.endsWith('00')) {
            formatted = formatted.slice(0, -3);
        } else {
            formatted = formatted.replace(/\.?0+$/, '');
        }
        return formatted + 'B';
    }

    if (absNum >= 1e6) {
        let formatted = (num / 1e6).toFixed(2);
        if (formatted.endsWith('00')) {
            formatted = formatted.slice(0, -3);
        } else {
            formatted = formatted.replace(/\.?0+$/, '');
        }
        return formatted + 'M';
    }

    if (absNum >= 1e3) {
        let formatted = (num / 1e3).toFixed(2);
        if (formatted.endsWith('00')) {
            formatted = formatted.slice(0, -3);
        } else {
            formatted = formatted.replace(/\.?0+$/, '');
        }
        return formatted + 'k';
    }
    if (absNum <= 1e3) {
        let formatted = absNum.toFixed(2);
        if (formatted.endsWith('00')) {
            formatted = formatted.slice(0, -3);
        } else {
            formatted = absNum.toFixed(2);
        }

        return formatted;
    }

    return num?.toString();
};

export {formattedDate, getCountryWithFlag, getCountryName, isUserAdmin }