import { useState, useEffect } from 'react';

import drag from '../../images/drag.svg'
import down from '../../images/icondropdown.svg'

function NavItemEditable(props) {
  const [isOpen, setisOpen] = useState(false);
  const [title, setTitle] = useState(props.name);
  const [url, setUrl] = useState(props.data.url_address || '');


  useEffect(() => {
    setTitle(props.name);
    setUrl(props.data.url_address || '');
  }, [props.name, props.data.url_address]);


  return (

    <div className="navItem" draggable>
      <div className="navEditableVisible" onClick={(e) => setisOpen(!isOpen)} >
        <div>
          <img src={drag} alt="Drag" />
          <p>{title}</p>
        </div>
        <img
          className={isOpen === true ? "downRotated" : ""}
          src={down}
          alt="Down"
        />
      </div>

      {isOpen === true ? (
        <div className="customLinkRow">
          <label className='fieldsLabel'>Title</label>
          <div className="navLinkRow">
            <input
              type="text"
              placeholder={props.name}
              value={title}
              className='inputFields'
              autocomplete="off"
              onChange={(e) => {
                setTitle(e.target.value);
                props.updateTitle(props.data.id, e.target.value)
                props.data.name = e.target.value;
              }}
            />
          </div>
          {props.data.type === "customLink" &&
            <>
              <p>Url</p>
              <div className="navLinkRow">
                <input
                  type="text"
                  placeholder={props.data.url_address !== '' ? props.data.url_address : 'Enter URL'}
                  value={url}
                  className='inputFields'
                  autocomplete="off"
                  onChange={(e) => {
                    setUrl(e.target.value);
                    props.updateUrl(props.data.id, e.target.value);
                    props.data.url = e.target.value;
                  }}
                />
              </div>

            </>}

          <div className='remove'>
            <button className='remove-navitem' onClick={() => props.removeNavItem(props.data)}> Remove </button>
          </div>

        </div>
      ) : (
        ""
      )}
    </div>

  );
}

export default NavItemEditable;
