import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from 'react-router-dom';
import moment from "moment";
import axios from 'axios';
import {formattedDate} from "../../utils/functions";


import Pagination from "../../components/Pagination";
import NoResultNewsletter from "./NoResultNewsletter";
import DeleteNewsletterModal from "./DeleteNewsletterModal";
import CheckboxActionDropdown from "../../components/CheckboxActionDropdown";

import linkIMG from "../../images/link.svg";
import moreIcon from "../../images/more.svg";
import Delete from "../../images/delete.svg";
import Down from "../../images/down-arrow.svg";
import penIcon from "../../images/pen-img.svg";
import search from '../../images/search.svg'
import plus from '../../images/plus.svg'
import iconDropDown from "../../images/icondropdown.svg";
import Notification from "../../components/Notification";

const Newsletter = (props) => {
    const { id } = useParams();

    const ref = useRef();
    const statusRef = useRef();
    const popupRef = useRef();

    props.setActivePage("Newsletters");
    props.setHideUserInfo(false);

    const [selectAll, setSelectAll] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [userStatusSelect, setNewsletterStatusSelect] = useState(false);
    const [selectedNewsletterStatus, setSelectedNewsletterStatus] = useState([]);
    const [statusList, setNewsletterStatusList] = useState([]);
    const excludedItemIds = [];

    const [originalData, setOriginalData] = useState([]);

    
    const [titleSort, setTitleSort] = useState(false);
    const [statusSort, setStatusSort] = useState(false);
    const [dateSort, setDateSort] = useState(false);

    const [userFilterSelect, setFilterTypeSelect] = useState(false);
    const [selectedFilterType, setSelectedFilterType] = useState([])
    const [readFields, setReadFields] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [openNotify, setOpenNotify] = useState(false);
    const [multiDeteleNotify, setMultiDeteleNotify] = useState(false);
    const [selectedNewsLenght, setSelectedNewsLenght] = useState(0);

    const filterType = ['Send', 'Draft', 'Deleted'];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusRef.current && !statusRef.current.contains(event.target)) {
                setFilterTypeSelect(false);
            }

            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupitem([]);
                setOpen(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    
    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        const status = selectedFilterType.length > 0 ? selectedFilterType.join(',') : '';
        setIsLoading(false);
        try {
            setIsLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/newsLetter/list/${id}?page=${currentPage}&search=${search}&status=${status}`,
            )
            setOriginalData(response?.data?.result);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "error");
        }
    }
    useEffect(() => {
        fetchData();
    }, [id, currentPage, searchInput, selectedFilterType ]);
    
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedFilterType]);

    /*
    useEffect(() => {
        var result = [];
        if (originalData !== undefined) {
            originalData.forEach(element => {
                if (result.indexOf(element.status) === -1) {
                    result.push(element.status);
                }
            });
            setNewsletterStatusList(result)
        } else {
            setNewsletterStatusSelect(false);
            setNewsletterStatusList([])
            setOriginalData(originalData);
        }
    }, [originalData])
    */

    /*
    useEffect(() => {
        var result = [];
        var input = searchInput != '' ? searchInput.toLowerCase() : '';
        originalData.forEach((element) => {
            var title = element.title.toLowerCase();

            if (title.includes(input)) {
                result.push(element);
            }
        });

        if (result.length !== 0) {
            setNewsletterItem(result);
        } else if (input === "") {
            setNewsletterItem(originalData);
        } else {
            setNewsletterItem([]);
        }
    }, [searchInput]);
    */

    useEffect(() => {
        var result = [];
        originalData.forEach((element) => {
            var pagestatus = element.status;
            if (selectedNewsletterStatus.includes(pagestatus)) {
                result.push(element);
            }
        });

        if (result.length !== 0) {
            setOriginalData(result);
        } else if (selectedNewsletterStatus.length === 0) {
            setOriginalData(originalData);
        } else {
            setOriginalData([]);
        }
    }, [selectedNewsletterStatus]);

    // useEffect(() => {
    //     // Check if any selected page status
    //     if (selectedFilterType.length > 0) {
    //         // Filter data based on multiple selected values
    //         const filteredData = data.filter(item => {
    //             return (
    //                 (selectedFilterType.includes('Draft') && item.page_count > 0) ||
    //                 (selectedFilterType.includes('Send') && item.publication_count > 0) ||
    //                 (selectedFilterType.includes('Deleted') && item.newslatter_count > 0)
    //             );
    //         });
    //         setCategoriesData(filteredData);
    //     } else {
    //         // No selection, include all data
    //         setCategoriesData(data);
    //     }
    // }, [selectedFilterType]);

    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
        } else {
            setOpen(false);
            setOpenPopupitem(item);
        }
    };

    function sortByTitle() {
        var result;
        if (titleSort === true) {
            result = [...originalData].sort((a, b) =>
                a.subject.localeCompare(b.subject)
            );
        } else {
            result = [...originalData].sort(
                (a, b) => -1 * a.subject.localeCompare(b.subject)
            );
        }
        setOriginalData(result);
    }

    function sortByStatus() {
        var result;
        if (statusSort === true) {
            result = [...originalData].sort((a, b) =>
                a.status.localeCompare(b.status)
            );
        } else {
            result = [...originalData].sort(
                (a, b) => -1 * a.status.localeCompare(b.status)
            );
        }
        setOriginalData(result);
    }

    function sortByDate() {
        var result = [];

        if (dateSort === false) {
            result = [...originalData].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format("ll");
                var daysA = moment(dateA).diff(now, "Days");

                var dateB = moment(b.createdAt).format("ll");
                var daysB = moment(dateB).diff(now, "Days");

                return daysA - daysB;
            });
        } else {
            result = [...originalData].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format("ll");
                var daysA = moment(dateA).diff(now, "Days");

                var dateB = moment(b.createdAt).format("ll");
                var daysB = moment(dateB).diff(now, "Days");

                return daysB - daysA;
            });
        }
        setOriginalData(result);
    }

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = originalData.map((item) => item._id).filter((itemId) => !excludedItemIds.includes(itemId));
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter(
                    (_id) => _id !== itemId
                );
                setSelectAll(
                    updatedSelectedItems.length === originalData.length
                );
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(
                    updatedSelectedItems.length === originalData.length
                );
                return updatedSelectedItems;
            }
        });
    };

    const handleActionEvent = (e) => {
        togglePopup(null);
    };

    const handleDeleteActionEvent = (item) => {
        if (item.length != 0 || selectedItems.length != 0) {
            setOpen(true);
        }
    };

    const deleteNewsletter = (itemID) => {
        const idsToRemove = Array.isArray(itemID) ? itemID : [itemID];
        const updatedUsers = originalData.filter(
            (page) => !idsToRemove.includes(page._id)
        );
        setOriginalData(updatedUsers);
        togglePopup(null);
        setOpen(false);
        setSelectAll(false);
    };

    const onClose = (e) => {
        setOpen(false);
    };

    return (
        <>
            <section className="dashboard">
                <div className="pagesTableWrapper">
                    <div className="pagesSearch pageTeam">
                        <div className="pageSearchTop">
                            <div className="pageSearchInput">
                                <input type="text" id="pages_search" name="pages_search" className="inputFields" placeholder={'Search newsletters..'} value={searchInput} onChange={e => setSearchInput(e.target.value)} readOnly={readFields} onFocus={ () => setReadFields(false) } onBlur={ () => setReadFields(true) }  autocomplete="off" />
                                <img src={search} alt="Search" />
                            </div>
                            <div className="userrole-dropdown newsletter-dropdown" ref={statusRef}>
                                <button className="selectFields" onClick={(e) => setFilterTypeSelect(!userFilterSelect)}>
                                    All Newsletters{" "}
                                    {selectedFilterType.length > 0 ? `(${selectedFilterType.length})` : ""}
                                    <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userFilterSelect === true) ? <CheckboxActionDropdown options={filterType} selectedOptions={selectedFilterType} changeOption={setSelectedFilterType} /> : ''
                                }
                            </div>
                        </div>

                        <div className="actions-buttons">
                            <div className="deleteIcon">
                                <img onClick={() => handleDeleteActionEvent([])} className="deleteIconImage" src={Delete} alt="Clear Input" />
                            </div>
                            <div className="add-new-member">
                                <Link to={`/users/${id}/newsletters/newsletter/`}>
                                    <button className="form-btn">
                                        <img src={plus} alt="plus" />
                                        New Newslatter
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="table-data-wrapper table-pagination-wrapper">
                        <div className="table-data table-six-column">
                            <div className="table-head">
                                <div className="table-status">
                                    <input type={"checkbox"} checked={selectAll} onChange={handleSelectAll} />
                                    <div className={`title ${titleSort === false ? "down-arrow" : "up-arrow"}`} onClick={(e) => { setTitleSort(!titleSort); sortByTitle(); }} >
                                        <p className="table-title">Subject</p>
                                        <img src={Down} onClick={(e) => { setTitleSort(!titleSort); sortByTitle(); }} alt="Down" />
                                    </div>
                                </div>
                                <div className={`team-member-role ${statusSort === false ? "down-arrow" : "up-arrow"}`} >
                                    <p className="table-title" onClick={(e) => { setStatusSort(!statusSort); sortByStatus(); }} > Status </p>
                                    <img src={Down} onClick={(e) => { setStatusSort(!statusSort); sortByStatus(); }} alt="Down" />
                                </div>

                                <div className={`date-user ${dateSort === false ? "down-arrow" : "up-arrow"}`}>
                                    <p className="table-title" onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} >To</p>
                                    <img src={Down} onClick={(e) => { setStatusSort(!statusSort); sortByStatus(); }} alt="Down" />
                                </div>

                                <div className={`date-user ${dateSort === false ? "down-arrow" : "up-arrow"}`}>
                                    <p className="table-title" onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} >Date</p>
                                    <img src={Down} onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} alt="Down" />
                                </div>
                                <div className="link">Link</div>
                                <div className="actions">Actions</div>
                            </div>
                            {isLoading ? 
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div> 
                                :                           
                                (originalData.length > 0) ?
                                    originalData.map((item) => {
                                        return (
                                            <div className="table-item" key={item._id}>
                                                <div className="table-status">
                                                    <div className="user-status">
                                                        <input type="checkbox" checked={selectedItems.includes( item._id )} onChange={() => handleCheckboxChange(item._id) } />
                                                        <Link to={`/users/${id}/newsletters/newsletter/${item._id}`}>{item.subject}</Link>
                                                        {/* <div className="user-content">{item.title}</div> */}
                                                    </div>
                                                </div>
                                                <div className={`table-status ${item.status}`}>
                                                    <span className="status-btn">{item.status}</span>
                                                </div>
                                                <div className="table-to">
                                                    <span>{item.to}</span>
                                                    <div><span className='toSubject'>{item?.sendTo?.plan}</span></div>
                                                </div>
                                                <div className="table-date">
                                                <span>{formattedDate(item.createdAt)}</span>
                                                </div>
                                                <div className="table-link">
                                                    <Link to="#" className="link-btn">
                                                        <img className="socialIcon" src={linkIMG} alt="SocialIcon" />
                                                        Link
                                                    </Link>
                                                </div>
                                                <div className="table-action" onClick={() => togglePopup(item)} ref={ref} >
                                                    <img src={moreIcon} alt="More" />
                                                </div>
                                                {openPopupitem &&
                                                    openPopupitem._id == item._id && (
                                                        <div className="table-data-dropdown" ref={popupRef}>
                                                            <ul>
                                                                <li>
                                                                    <Link to={`/users/${id}/newsletters/newsletter/${item._id}`} onClick={ handleActionEvent } >
                                                                        <img src={penIcon} alt="pen-icon" />
                                                                        Edit
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span onClick={() => handleDeleteActionEvent( item ) } >
                                                                        <img src={Delete} alt="pen-icon" />
                                                                        Delete
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )}
                                            </div>
                                        );
                                    })
                                    :
                                    <NoResultNewsletter />
                            }
                        </div>
                        {
                            totalPage > 1 &&
                            <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                        }
                    </div>
                </div>
            </section>

            <DeleteNewsletterModal
                isOpen={isOpen}
                item={openPopupitem}
                selectedItems={selectedItems}                
                onClose={onClose}
                setOpen={setOpen}
                refrence={popupRef}
                fetchData={fetchData}
                originalData={originalData}
                setOpenNotify={setOpenNotify}
                setMultiDeteleNotify={setMultiDeteleNotify}
                setSelectedNewsLenght={selectedNewsLenght}
                setSelectedItems={setSelectedItems}
            />

            { openNotify && 
                <Notification title={`The newsletter was successfully deleted!`} setOpenNotify={setOpenNotify} type={"success"} openNotify={openNotify} />
            }
            { multiDeteleNotify && 
                <Notification title={`${selectedNewsLenght} newsletters were successfully deleted!`} setOpenNotify={setMultiDeteleNotify} type={"success"} openNotify={multiDeteleNotify} />
            }

        </>
    );
}
export default Newsletter;