import React, { useState } from "react";
import axios from 'axios';

const Password = (props) => {
    props.setActivePage("Password");
    const [currentPass, setCurrentPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirmNewPass, setConfirmNewPass] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const onPasswordChange = e => {
        setCurrentPass(e.target.value);
    };

    const onNewPasswordChange = e => {
        setNewPass(e.target.value);
        setBox(e.target.value.length > 0);
    };

    const onConfirmPasswordChange = e => {
        setConfirmNewPass(e.target.value);
    };

    const [errors, setErrors] = useState({
        curPass: "",
        newPass: "",
        confirmNewPass: "",
        curPassIncorrect:""
    });

    const validateInputs = () => {
        let valid = true;
        const newErrors = {};
        
        if (currentPass.length == 0) {
            newErrors.curPass = "Current Password is required";
            valid = false;
        }

        if (newPass.length == 0) {
            newErrors.newPass = "New Password is required";
            valid = false;
        }

        if (confirmNewPass.length == 0) {
            newErrors.confirmNewPass = "Confirm Password is required";
            valid = false;
        }

        if(newPass.length > 0 && confirmNewPass.length > 0 ) {
            if( newPass !== confirmNewPass ) {
                newErrors.confirmNewPass = "Password do not match";
                valid = false;
            } else {
                if( ! validatePassword(newPass) ) {
                    newErrors.confirmNewPass = "Password must contain at least 8 characters including uppercase and lowercase letters, numbers, and special characters.";
                    valid = false;
                } else {
                    newErrors.confirmNewPass = '';
                }
            }
        }
        setErrors(newErrors);
        return valid;
    };

    const validatePassword = (password) => {
        // Regular expression to enforce the password requirements
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const handleChangePassword = async () => {
        try {
            setIsLoading(true);
            
             // Validate inputs before making the API call
             const inputsValid = validateInputs();
             if (!inputsValid) {
                 setIsLoading(false);
                 return false;
             }
            const passwordData = {
                currentPassword: currentPass,
                newPassword: newPass,
                confirmPassword: confirmNewPass,
                changeFor: 'self'
            }
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/changepassword/${JSON.parse(localStorage.getItem("userData"))?._id}`,
                passwordData,
            )
            if(response?.data?.message === "Password successfully changed."){
                setBox(false)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if(error?.response?.data?.message === "Your current password is incorrect, the password was not changed."){
                const newErrors = {};
                newErrors.curPassIncorrect = 'Your current password is incorrect.'
                setErrors(newErrors)
            }
        }
    }

    const isUppercase = /[A-Z]/.test(newPass);
    const isLowercase = /[a-z]/.test(newPass);
    const hasNumber = /[0-9]/.test(newPass);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPass);
    const isMinLength = newPass.length >= 8;

    const [box, setBox] = useState(false);

    const fulfilledRequirementsCount = [
        isUppercase,
        isLowercase,
        hasSpecialChar,
        hasNumber,
    ].filter(Boolean).length;

    return (
        <div className="generalContent">
            <div className="generalRow">
                
                <div className="formControltems">
                    <div className="formControlInputs formControlFull">
                        <div className="formControlFields">
                            <label className="fieldsLabel">Current Password</label>
                            <input
                                value={currentPass}
                                onChange={onPasswordChange}
                                className="inputFields"
                                type="password" />
                                {errors.curPass && (
                                    <div className="validateError">
                                        {errors.curPass}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>

                <div className="formControltems">
                    <div className="formControlInputs formControlFull">
                        <div className="formControlFields">
                            <label className="fieldsLabel">New Password</label>
                            <input
                                type="password"
                                value={newPass}
                                className="inputFields"
                                onChange={onNewPasswordChange} />
                                {errors.newPass && (
                                    <div className="validateError">
                                        {errors.newPass}
                                    </div>
                                )}
                        </div>
                        {box && (
                            <div className="requirements-box">
                                <p>Your password must contain:</p>
                                <ul className="requirement-list">
                                    <li className={isMinLength ? "fulfilled" : ""}>
                                        <p>At least 8 characters</p>
                                    </li>
                                    <li
                                        className={
                                            fulfilledRequirementsCount >= 4 ? "fulfilled" : ""
                                        }
                                    >
                                        <p>And the Following:</p>
                                        <ul>
                                            <li className={isLowercase ? "fulfilled" : ""}>
                                                <p>Lower case letters (a - z)</p>
                                            </li>
                                            <li className={isUppercase ? "fulfilled" : ""}>
                                                <p>Upper case letters (A - Z)</p>
                                            </li>
                                            <li className={hasNumber ? "fulfilled" : ""}>
                                                <p>Numbers (0 - 9)</p>
                                            </li>
                                            <li className={hasSpecialChar ? "fulfilled" : ""}>
                                                <p>Special characters (e.g. !@#$%^&*)</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>

                
                <div className="formControlItems">
                    <div className="formControlInputs formControlFull">
                        <div className="formControlFields">
                            <label className="fieldsLabel">Confirm New Password</label>
                            <input
                                type="password"
                                value={confirmNewPass}
                                className="inputFields"
                                onChange={onConfirmPasswordChange} />
                                {errors.confirmNewPass && (
                                    <div className="validateError">
                                        {errors.confirmNewPass}
                                    </div>
                                )}
                                {errors.curPassIncorrect && (
                                    <div className="validateError">
                                        {errors.curPassIncorrect}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>

            </div>
            
            <button
                onClick={handleChangePassword}
                className="form-btn save-change" type="button">
                {isLoading ? (
                    <div className="settingPage-loader">
                        <div className="ring-loader"></div>
                    </div>
                ) : (
                    "Save Changes"
                )}
            </button>
        </div>
    );
};
export default Password;
