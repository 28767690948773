import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";

import {formattedDate, getCountryName, getCountryWithFlag} from "../../utils/functions";

import avatar from "../../images/profile-picture 1.png";
import Down from "../../images/down-arrow.svg";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";

const DashboardNewUserTable = () => {
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [userCountrySelect, setUserCountrySelect] = useState(false)
    const [countryList, setCountryList] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [userData, setUserData] = useState([]);
    const [userList, setUserList] = useState([]);
    const [originalData, setOriginalData] = useState(userList);
    const [userPlanSelect, setUserPlanSelect] = useState(false);
    const [userPlanList, setUserPlanList] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(false)
            try {
                const countries = selectedCountryCode.length > 0 ?  selectedCountryCode.join(',') : '';
                setIsLoading(true)

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getusers/?pageadmin=${currentPage}&countries=${countries}`);
                if( response.status == 200 ) {
                    const usersArray = response.data.users.map(item => ({
                        ...item,
                        countryName: getCountryName(item?.country || ''),
                    }));

                    setTotalPage(response.data.totalPages || 1);
                    setOriginalData(usersArray);
                    setUserList(usersArray);
                }
                setIsLoading(false)
            } catch (error) {
                console.log(error);
                setIsLoading(false)
            }
        };
    
        fetchData(); // Call the async function immediately
    }, [currentPage, selectedCountryCode]);

    useEffect(() => {
        if (originalData !== undefined) {
            const uniqueCountries = [];
            const uniquePlans = [];
            originalData.forEach((element) => {
                if (!uniqueCountries.includes(element.country)) {
                    uniqueCountries.push(element.country);
                }
                if (!uniquePlans.includes(element.plan)) {
                    uniquePlans.push(element.plan);
                }
            });
            setUserPlanList(uniquePlans);
            setUserList(originalData);
        } else {
            setUserPlanSelect(false);
            setUserPlanList([]);
            setUserList([]);
        }
    }, [originalData]);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = userList.map((item) => item._id);
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter(
                    (_id) => _id !== itemId
                );
                setSelectAll(
                    updatedSelectedItems.length === userList.length
                );
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(
                    updatedSelectedItems.length === userList.length
                );
                return updatedSelectedItems;
            }
        });
    };

    const [dateSort, setDateSort] = useState(false);
    function sortByDate() {
        var result = [];

        if (dateSort === false) {
            result = [...userList].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a?.createdAt).format("ll");
                var daysA = moment(dateA).diff(now, "Days");

                var dateB = moment(b?.createdAt).format("ll");
                var daysB = moment(dateB).diff(now, "Days");

                return daysA - daysB;
            });
        } else {
            result = [...userList].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a?.createdAt).format("ll");
                var daysA = moment(dateA).diff(now, "Days");

                var dateB = moment(b?.createdAt).format("ll");
                var daysB = moment(dateB).diff(now, "Days");

                return daysB - daysA;
            });
        }
        setUserList(result);
    }

    const [planSort, setPlanSort] = useState(false);
    function sortByPlan() {
        var result;
        if (planSort === true) {
            result = [...userList].sort((a, b) =>
                a?.planDetail?.plan.localeCompare(b?.planDetail?.plan)
            );
        } else {
            result = [...userList].sort(
                (a, b) => -1 * a?.planDetail?.plan?.localeCompare(b?.planDetail?.plan)
            );
        }
        setUserList(result);
    }

    const [countrySort, setCountrySort] = useState(false);
    function sortByCountry() {
        var result;
        if (countrySort === true) {
            result = [...userList].sort((a, b) =>
                a.country.localeCompare(b.country)
            );
        } else {
            result = [...userList].sort(
                (a, b) => -1 * a.country.localeCompare(b.country)
            );
        }
        setUserList(result);
    }

    const [nameSort, setNameSort] = useState(false);
    function sortByName() {
        var result;
        if (nameSort === true) {
            result = [...userList].sort((a, b) =>
                a?.displayName?.localeCompare(b?.displayName)
            );
        } else {
            result = [...userList].sort(
                (a, b) => -1 * a?.displayName.localeCompare(b?.displayName)
            );
        }
        setUserList(result);
    }

    return (
        <>
            <div className="table-data-wrapper table-pagination-wrapper">
                <h3 className="heading">New Users</h3>
                <div className="table-data">
                    <div className="table-head table-four-column">
                        <div className="table-status">
                            <input type={"checkbox"} checked={selectAll} onChange={handleSelectAll} />
                            <div className={`full-name ${nameSort === false ? 'down-arrow' : 'up-arrow'}`} onClick={(e) => { setNameSort(!nameSort); sortByName(); }}  >
                                <p className="table-full-name">Full Name</p>
                                <img src={Down} alt="Down" />
                            </div>
                        </div>
                        <div className={`country ${countrySort === false ? 'down-arrow' : 'up-arrow'}`} onClick={(e) => { setCountrySort(!countrySort); sortByCountry(); }} >
                            <p className="table-country">Country</p>
                            <img src={Down} alt="Down" />
                        </div>
                        <div className={`subscription-date ${dateSort === false ? 'down-arrow' : 'up-arrow'}`} onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} >
                            <p className="table-title">Subscription Date</p>
                            <img src={Down} alt="Down" />
                        </div>
                        <div className={`plan ${planSort === false ? 'down-arrow' : 'up-arrow'}`} onClick={(e) => { setPlanSort(!planSort); sortByPlan(); }} >
                            <p className="table-title">Plan</p>
                            <img src={Down} alt="Down" />
                        </div>
                    </div>
                    {isLoading ? 
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                        :
                    userList.map((item) => {
                        return(
                            <div className="table-item user-item" key={item._id}>
                                <div className="table-status">
                                    <div className="user-status" >
                                        <input
                                            type="checkbox"
                                            checked={selectedItems.includes(item._id)}
                                            onChange={() => handleCheckboxChange(item._id)}
                                        />
                                        <div className="user-content">
                                            <img src={`${item?.profileMedia == "" ? avatar : item?.profileMedia }`} />
                                            <div className="user-brief">
                                                <span className="user-name">
                                                    <Link to={`/users/${item._id}`}>{item?.displayName || 'NO NAME'}</Link>
                                                </span>
                                                <p className="user-mail">{item.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-country">
                                    <span className="country-flag">{getCountryWithFlag(item.country)}</span>
                                </div>
                                <div className="subscription-date">
                                    <span>{formattedDate(item.createdAt)}</span>
                                </div>
                                <div className="subscription-plan">
                                    <span className={`plan-btn ${item.planDetail !== 'FREE' && 'pro-btn'}`}>{`${(item?.planDetail?.plan === "free_plan" || item?.planDetail?.plan === "basic_plan" || item?.planDetail === "FREE") ? "Free" : (item?.planDetail?.plan === "unlimited_plan") ? "Unlimited" : "Personal Assistant"}`}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {/* {
                totalPage > 1 && 
                    <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                }   */}
            </div>
        </>
    );
};
export default DashboardNewUserTable;
