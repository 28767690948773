import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import moment from "moment";

import DeleteTeamMember from "./DeleteTeamMember";
import UserRoleActionDropdown from "../../components/userRoleActionDropdown";
import NoResultsTeam from "./NoResultsTeam";
import AddNewTeamMember from "./AddNewTeamMember";
import Pagination from "../../components/Pagination";

import { formattedDate, getCountryName, getCountryWithFlag, isUserAdmin } from "../../utils/functions";

import avatar from "../../images/profile-user.svg";
import Down from "../../images/down-arrow.svg";
import moreIcon from "../../images/more.svg";
import penIcon from "../../images/pen-img.svg";
import Delete from "../../images/delete.svg";
import search from "../../images/search.svg";
import iconDropDown from "../../images/icondropdown.svg";
import plus from '../../images/plus.svg'

const TeamMemberTable = () => {
    const ref = useRef();
    const userroleRef = useRef();
    const usercountryRef = useRef();
    const tableActionRef = useRef();

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [isAddNewPopupOpen, setAddNewPopupOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [countrySort, setCountrySort] = useState(false);
    const [userMemberList, setUserMemberList] = useState([]);
    const [originalData, setOriginalData] = useState(userMemberList);
    const [userRoleSelect, setUserRoleSelect] = useState(false)
    const [selectedUserRole, setSelectedUserRole] = useState([])

    const userRoleList = ['admin', 'assistant', 'support'];

    const [userCountrySelect, setUserCountrySelect] = useState(false)
    const [countryList, setCountryList] = useState([])
    const [selectedCountryCode, setSelectedCountryCode] = useState('');

    const [dateSort, setDateSort] = useState(false);
    const [nameSort, setNameSort] = useState(false);
    const [userRoleSort, setUserRoleSort] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [readFields, setReadFields] = useState(true);
    const [isLoading, setIsLoading] = useState(false); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getcountries?type=team`);

                if (response.status == 200) {
                    setCountryList(response.data.result || [])
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData(); // Call the async function immediately
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const search = searchInput.toLowerCase();
            const countries = selectedCountryCode.length > 0 ? selectedCountryCode.join(',') : '';
            const roles = selectedUserRole.length > 0 ? selectedUserRole.join(',') : '';

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getteam/?page=${currentPage}&search=${search}&countries=${countries}&roles=${roles}`);

            if (response.status == 200) {
                const teamMemberArray = response.data.team.map(item => ({
                    ...item,
                    countryName: getCountryName(item.country),
                }));

                setTotalPage(response.data.totalPages || 1);

                setUserMemberList(teamMemberArray);
                setOriginalData(teamMemberArray)
                setIsLoading(false)
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(); // Call the async function immediately
    }, [currentPage, searchInput, selectedCountryCode, selectedUserRole]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (userroleRef.current && !userroleRef.current.contains(event.target)) {
                setUserRoleSelect(false);
            }

            if (usercountryRef.current && !usercountryRef.current.contains(event.target)) {
                setUserCountrySelect(false)
            }

            if (tableActionRef.current && !tableActionRef.current.contains(event.target)) {
                setOpenPopupitem([]);
                setOpen(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (originalData !== undefined) {
            // Initialize result arrays
            let uniqueRoles = [];
            let uniqueCountries = [];

            // Iterate over originalData to extract unique roles and countries
            originalData.forEach(element => {
                if (!uniqueRoles.includes(element.role)) {
                    uniqueRoles.push(element.role);
                }
                if (!uniqueCountries.includes(element.country)) {
                    uniqueCountries.push(element.country);
                }
            });

            // Set state values
            setUserMemberList(originalData);
        } else {
            // Handle case when originalData is undefined
            setUserRoleSelect(false);
            setUserMemberList([]);
        }
    }, [originalData]);


    const handleActionEvent = (e) => {
        togglePopup(null);
    };

    const onClose = (e) => {
        setOpen(false);
        setAddNewPopupOpen(false);
    };

    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
        } else {
            setOpen(false);
            setOpenPopupitem(item);
        }
    };

    const handleDeleteActionEvent = (item) => {
        if (item.length != 0 || selectedItems.length != 0) {
            setOpen(true);
        }
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = userMemberList.map((item) => item._id);
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter(
                    (_id) => _id !== itemId
                );
                setSelectAll(
                    updatedSelectedItems.length === userMemberList.length
                );
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(
                    updatedSelectedItems.length === userMemberList.length
                );
                return updatedSelectedItems;
            }
        });
    };

    function sortByDate() {
        var result = [];

        if (dateSort === false) {
            result = [...userMemberList].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format("ll");
                var daysA = moment(dateA).diff(now, "Days");

                var dateB = moment(b.createdAt).format("ll");
                var daysB = moment(dateB).diff(now, "Days");

                return daysA - daysB;
            });
        } else {
            result = [...userMemberList].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format("ll");
                var daysA = moment(dateA).diff(now, "Days");

                var dateB = moment(b.createdAt).format("ll");
                var daysB = moment(dateB).diff(now, "Days");

                return daysB - daysA;
            });
        }
        setUserMemberList(result);
    }

    function sortByCountry() {
        var result;
        if (countrySort === true) {
            result = [...userMemberList].sort((a, b) =>
                a.countryName.localeCompare(b.countryName)
            );
        } else {
            result = [...userMemberList].sort(
                (a, b) => -1 * a.countryName.localeCompare(b.countryName)
            );
        }
        setUserMemberList(result);
    }

    function sortByName() {
        var result;
        if (nameSort === true) {
            result = [...userMemberList].sort((a, b) =>
                a.displayName.localeCompare(b.displayName)
            );
        } else {
            result = [...userMemberList].sort(
                (a, b) => -1 * a.displayName.localeCompare(b.displayName)
            );
        }
        setUserMemberList(result);
    }

    function sortByUserRole() {
        var result;
        if (userRoleSort === true) {
            result = [...userMemberList].sort((a, b) =>
                a.role.localeCompare(b.role)
            );
        } else {
            result = [...userMemberList].sort(
                (a, b) => -1 * a.role.localeCompare(b.role)
            );
        }
        setUserMemberList(result);
    }

    return (
        <>
            <div className="pagesSearch pageTeam">
                <div className="pageSearchTop">
                    <div className="pageSearchInput">
                        <input
                            type="text"
                            name="pages_search"
                            id="pages_search"
                            className="inputFields"
                            placeholder={"Search team member..."}
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            autocomplete="off"
                            readOnly={readFields}
                            onFocus={() => setReadFields(false)}
                            onBlur={() => setReadFields(true)}
                        />
                        <img src={search} alt="Search" />
                    </div>

                    <div ref={usercountryRef} className="select-country dropdown-wrapp">
                        <button className="selectFields" onClick={(e) => setUserCountrySelect(!userCountrySelect)}>
                            All Countries{" "}
                            {selectedCountryCode.length > 0 ? `(${selectedCountryCode.length})` : ""}
                        </button>
                        {
                            userCountrySelect === true &&
                            <div className="dropdown addDropdown countryDropdown">
                                <ul>
                                    {countryList && countryList.length > 0 &&
                                        countryList.map((country) => (
                                            <li key={country} onClick={() => {
                                                const result = selectedCountryCode.includes(country)
                                                    ? selectedCountryCode.filter(
                                                        (selectcountry) =>
                                                            selectcountry !== country
                                                    )
                                                    : [...selectedCountryCode, country];
                                                setSelectedCountryCode(result);
                                                setCurrentPage(1);
                                            }}>
                                                <input className="countrySelect" type="checkbox" checked={selectedCountryCode.includes(country)} />
                                                <span className="country-flag">{getCountryWithFlag(country)}</span>
                                            </li>

                                        ))
                                    }
                                </ul>
                            </div>

                        }
                    </div>

                    <div ref={userroleRef} className="userrole-dropdown dropdown-wrapp">
                        <button className="selectFields" onClick={(e) => setUserRoleSelect(!userRoleSelect)}>
                            All Roles{" "}
                            {selectedUserRole.length > 0 ? `(${selectedUserRole.length})` : ""}
                            <img src={iconDropDown} alt="User Roles" />
                        </button>
                        {
                            (userRoleSelect === true) ? <UserRoleActionDropdown userRoles={userRoleList} selected={selectedUserRole} changeRole={setSelectedUserRole} setCurrentPage={setCurrentPage} /> : ''
                        }
                    </div>
                </div>
                {isUserAdmin() && (
                    <div className="actions-buttons">
                        <div className="deleteIcon">
                            <img
                                onClick={() => handleDeleteActionEvent([])}
                                className="deleteIconImage"
                                src={Delete}
                                alt="Clear Input"
                            />
                        </div>
                        <div className="add-new-member">
                            <button className="form-btn"
                                onClick={() => setAddNewPopupOpen(!isAddNewPopupOpen)}>
                                <img src={plus} alt="plus" />
                                New Team Member
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div className="table-data-wrapper table-pagination-wrapper">
                <div className="table-data table-five-column">
                    <div className="table-head">
                        <div className="table-status">
                            <input type={"checkbox"} checked={selectAll} onChange={handleSelectAll} />
                            <div className={`full-name ${nameSort === false ? "down-arrow" : "up-arrow"}`} onClick={(e) => { setNameSort(!nameSort); sortByName(); }} >
                                <p className="table-full-name">Full Name</p>
                                <img src={Down} alt="Down" />
                            </div>
                        </div>
                        <div className={`team-member-role ${userRoleSort === false ? "down-arrow" : "up-arrow"}`} >
                            <p className="table-title" onClick={(e) => { setUserRoleSort(!userRoleSort); sortByUserRole(); }} > Role </p>
                            <img src={Down} onClick={(e) => { setUserRoleSort(!userRoleSort); sortByUserRole(); }} alt="Down" />
                        </div>
                        <div className={`country ${countrySort === false ? "down-arrow" : "up-arrow"}`} onClick={(e) => { setCountrySort(!countrySort); sortByCountry(); }} >
                            <p className="table-country" onClick={(e) => { setCountrySort(!countrySort); sortByCountry(); }} > Country </p>
                            <img src={Down} onClick={(e) => { setCountrySort(!countrySort); sortByCountry(); }} alt="Down" />
                        </div>
                        <div className={`subscription-date ${dateSort === false ? "down-arrow" : "up-arrow"}`} onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} >
                            <p className="table-title" onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} > Date </p>
                            <img src={Down} onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} alt="Down" />
                        </div>
                        <div className="actions">Actions</div>
                    </div>
                    {isLoading ? 
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                        :
                        (userMemberList.length > 0) ?
                            userMemberList.map((item) => {
                                return (
                                    <div className="table-item" key={item._id}>
                                        <div className="table-status">
                                            <div className="user-status">
                                                <input type="checkbox" checked={selectedItems.includes(item._id)} onChange={() => handleCheckboxChange(item._id)} />
                                                <div className="user-content">
                                                    <img src={(item?.profileMedia !== "" && item?.profileMedia !== undefined) ? item?.profileMedia : avatar} />
                                                    <div className="user-brief">
                                                        <span className="user-name">
                                                            <Link to={`/team/${item._id}`}>{item.displayName}</Link>
                                                        </span>
                                                        <p className="user-mail">
                                                            {item.email}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`table-role ${item.role}`}>
                                            <span className="plan-btn">{item.role}</span>
                                        </div>
                                        <div className="table-country">
                                            <span className="country-flag">{getCountryWithFlag(item.country)}</span>
                                        </div>
                                        <div className="subscription-date">
                                            <span>{formattedDate(item.createdAt)}</span>
                                        </div>
                                        <div className="table-action" onClick={() => togglePopup(item)} ref={ref} >
                                            <img src={moreIcon} alt="More" />
                                        </div>
                                        {openPopupitem && openPopupitem._id == item._id && (
                                            <div className="table-data-dropdown" ref={tableActionRef}>
                                                <ul>
                                                    <li>
                                                        <Link to={`/team/${item._id}`} onClick={handleActionEvent} >
                                                            <img src={penIcon} alt="pen-icon" />
                                                            {isUserAdmin() ? 'Edit' : 'View'}

                                                        </Link>
                                                    </li>
                                                    <li>
                                                        {isUserAdmin() && (
                                                            <span onClick={() => handleDeleteActionEvent(item)} >
                                                                <img src={Delete} alt="pen-icon" />
                                                                Delete
                                                            </span>
                                                        )}

                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                            :
                            <NoResultsTeam />
                    }
                </div>
                {
                    totalPage > 1 &&
                    <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                }
            </div>
            <DeleteTeamMember
                refrence={tableActionRef}
                isOpen={isOpen}
                item={openPopupitem}
                selectedItems={selectedItems}
                setOpen={setOpen}
                setUserMemberList={setUserMemberList}
                setSelectAll={setSelectAll}
                setSelectedItems={setSelectedItems}
                userMemberList={userMemberList}
                togglePopup={togglePopup}
                onClose={onClose}
            />

            <AddNewTeamMember
                isOpen={isAddNewPopupOpen}
                onClose={onClose}
                setUserMemberList={setUserMemberList}
                setOriginalData={setOriginalData}
                fetchData={fetchData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
};
export default TeamMemberTable;
